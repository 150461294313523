import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
// Sembly UI
import { GraphError } from '@sembly-ui';
// App Shared
import generateAccessTokenMutation from '@shared/queries/GenerateIntegrationFabricUserToken.graphql';
import { GenerateIntegrationFabricUserToken } from '@gql-types';

/**
 * Generates an access token for the Integration Fabric and stores it in a cookie.
 */
export const useFabricUserLevelAuth = () => {
  const [authToken, setAuthToken] = useState('');
  const [error, setError] = useState<GraphError | null>(null);
  const [loading, setLoading] = useState(false);

  const [generateAccessToken] = useMutation<GenerateIntegrationFabricUserToken>(
    generateAccessTokenMutation,
  );

  const removeAccessToken = () => {
    setAuthToken('');
    setError(null);
  };

  useEffect(() => {
    const removeAccessToken = () => {
      setAuthToken('');
      setError(null);
    };

    const requestAndSetAuthToken = async () => {
      setLoading(true);
      const result = await generateAccessToken();

      const accessToken = result.data?.generateIntegrationFabricUserToken?.accessToken;

      if (!accessToken) {
        setError(result.data?.generateIntegrationFabricUserToken?.errors);
        return '';
      }

      setAuthToken(accessToken || '');
      setLoading(false);
    };

    requestAndSetAuthToken();

    return () => {
      removeAccessToken();
    };
  }, [generateAccessToken]);

  return { authToken, loading, error, removeAccessToken };
};

export default useFabricUserLevelAuth;
