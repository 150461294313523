import { UserGuideTypes } from '@gql-types/globalTypes';
// Shared Assets
import checkCommitmentsIcon from '@shared/assets/achievements/check-commitments.svg';
import checkCommitmentsImage from '@shared/assets/achievements/check-commitments.png';
import chromeExtensionIcon from '@shared/assets/achievements/chrome-extension.svg';
import chromeExtensionImage from '@shared/assets/achievements/chrome-extension.png';
import composeMinutesIcon from '@shared/assets/achievements/compose-minutes.svg';
import composeMinutesImage from '@shared/assets/achievements/compose-minutes.png';
import connectCalendarIcon from '@shared/assets/achievements/connect-calendar.svg';
import connectCalendarImage from '@shared/assets/achievements/connect-calendar.png';
import instantlyInviteIcon from '@shared/assets/achievements/instantly-invite.svg';
import instantlyInviteImage from '@shared/assets/achievements/instantly-invite.png';
import recordMeetingIcon from '@shared/assets/achievements/record-meeting.svg';
import recordMeetingImage from '@shared/assets/achievements/record-meeting.png';
import shareNotesIcon from '@shared/assets/achievements/share-notes.svg';
import shareNotesImage from '@shared/assets/achievements/share-notes.png';
import uploadRecordingIcon from '@shared/assets/achievements/upload-recording.svg';
import uploadRecordingImage from '@shared/assets/achievements/upload-recording.png';
import workspaceInviteIcon from '@shared/assets/achievements/workspace-invite.svg';
import workspaceInviteImage from '@shared/assets/achievements/workspace-invite.png';
import automationsIcon from '@shared/assets/achievements/automations.svg';
import automationsImage from '@shared/assets/achievements/automations.png';

interface State {
  description: string;
  icon: string;
  image: string;
  link: string;
  title: string;
  video: string;
}

export const ACHIEVEMENT_TYPES: Record<UserGuideTypes, State> = {
  CHROME_EXTENSION: {
    title: 'Add Sembly Chrome extension ',
    description: `Invite Sembly agent to the meeting and control meeting recording directly from your browser!`,
    link: 'https://sembly-ai.zendesk.com/hc/en-us/articles/8299967037457-Chrome-Extension',
    icon: chromeExtensionIcon,
    image: chromeExtensionImage,
    video: 'https://www.youtube.com/embed/U9KQ00JghDU?autoplay=1',
  },
  COMMITMENTS: {
    title: 'Never miss a Task with Sembly',
    description: `Use Sembly Tasks to not lose track of important commitments and follow-ups.`,
    link: 'https://sembly-ai.zendesk.com/hc/en-us/articles/12896509885329-Commitments',
    icon: checkCommitmentsIcon,
    image: checkCommitmentsImage,
    video: 'https://www.youtube.com/embed/p19J2Qtzjnc?autoplay=1',
  },
  AUTOMATIONS: {
    title: 'Set up your first automation',
    description: `Start sharing meeting insights directly in other apps integrated into your workflows`,
    link: `https://helpdesk.sembly.ai/hc/en-us/articles/22954612331409-Native-Personal-and-Workspace-Automations`,
    icon: automationsIcon,
    image: automationsImage,
    video: 'https://www.youtube.com/embed/PB7c_dMcM9c?autoplay=1',
  },
  CONNECT_CALENDAR: {
    title: 'Connect your calendar to Sembly',
    description: `Make Sembly your trusted teammate by asking it to attend your scheduled meetings!`,
    link: 'https://sembly-ai.zendesk.com/hc/en-us/articles/4407875058833-Invite-Sembly-with-Connected-Calendar',
    icon: connectCalendarIcon,
    image: connectCalendarImage,
    video: 'https://www.youtube.com/embed/JPa3vjvgbgc?autoplay=1',
  },
  GUEST_VIEW: {
    title: 'Share Sembly Meeting Notes using Guest View',
    description: `Let others access the meeting notes even if they are not Sembly users with Guest View.`,
    link: 'https://sembly-ai.zendesk.com/hc/en-us/articles/4407884099729-Share-a-Meeting-by-Link-',
    icon: shareNotesIcon,
    image: shareNotesImage,
    video: 'https://www.youtube.com/embed/vGhbBnEBrbk?autoplay=1',
  },
  INSTA_INVITE: {
    title: 'Instantly invite Sembly to a meeting',
    description: `Invite Sembly to an ongoing call or schedule Sembly attendance for the future!`,
    link: 'https://sembly-ai.zendesk.com/hc/en-us/articles/4407874486033-Immediate-Sembly-Invite',
    icon: instantlyInviteIcon,
    image: instantlyInviteImage,
    video: 'https://www.youtube.com/embed/iTQVs9CEie0?autoplay=1',
  },
  INVITE_TEAMMEMBER: {
    title: 'Invite others to work with you in Sembly',
    description: `Create a team in Sembly to easily collaborate with your colleagues!`,
    link: 'https://sembly-ai.zendesk.com/hc/en-us/articles/4407363252753-How-to-invite-new-users-to-Sembly-',
    icon: workspaceInviteIcon,
    image: workspaceInviteImage,
    video: 'https://www.youtube.com/embed/Fyj0nKLLrCA?autoplay=1',
  },
  MANUAL_RECORDING: {
    title: 'Record a meeting from your microphone',
    description: `Use your browser or mobile phone to record an offline conversation or take a self-note!`,
    link: 'https://sembly-ai.zendesk.com/hc/en-us/articles/4420271153297-Sembly-Voice-Recorder',
    icon: recordMeetingIcon,
    image: recordMeetingImage,
    video: 'https://www.youtube.com/embed/TRUYmTYjiTE?autoplay=1',
  },
  MEETING_MINUTES: {
    title: 'Prepare and export Meeting Notes',
    description: `Fine-tune your automatically generated meeting notes and share them seamlessly with your team using Sembly!`,
    link: 'https://sembly-ai.zendesk.com/hc/en-us/articles/4421010439185-Sembly-Minutes',
    icon: composeMinutesIcon,
    image: composeMinutesImage,
    video: 'https://www.youtube.com/embed/VXc4IdWLTXU?autoplay=1',
  },
  UPLOAD_RECORDING: {
    title: 'Upload your recording to Sembly',
    description: `Upload audio files to generate a transcript, meeting notes, and insights for pre-recorded meetings!`,
    link: `https://sembly-ai.zendesk.com/hc/en-us/articles/8901816805649-How-to-upload-an-audio-file`,
    icon: uploadRecordingIcon,
    image: uploadRecordingImage,
    video: 'https://www.youtube.com/embed/EoIrwWLSHww?autoplay=1',
  }
} as const;

export default ACHIEVEMENT_TYPES;
