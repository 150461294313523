import { MEETING_PLATFORM_URL_REGEXPS as RegExps } from '@shared/constants';

type MeetingPlatform = 'webex' | 'zoom' | 'microsoft teams' | 'google meet' | 'other';

export const getAgentCallProcessedLink = (
  platform: MeetingPlatform,
  url: string,
): string | null => {
  let regExp: string | null = null;

  const teamsRegExps = [
    RegExps.outlookSafeLinkWithTeemsRegExp,
    RegExps.teamsLiveUrlRegExp,
    RegExps.teamsMicrosoftUrlRegExp,
    RegExps.teamsUrlWithContextRegExp,
    RegExps.teamsUrlRegExp,
  ];

  const zoomRegExps = [
    RegExps.outlookSafeLinkWithZoomRegExp,
    RegExps.zoomWithDomainUrlRegExp,
    RegExps.zoomWithDomainAndPwdUrlRegExp,
    RegExps.zoomUrlRegExp,
  ];

  const meetRegExps = [RegExps.outlookSafeLinkWithMeetRegExp, RegExps.meetUrlRegExp];

  function clearLink(regExps: Array<string>, type?: string) {
    for (let elem of regExps) {
      if (new RegExp(elem).test(url)) {
        regExp = `https://${url.match(elem)?.[0].split('&')[0]}`;
        if (type === 'zoom') regExp = regExp.split(',')[0].split('__')[0].split(']')[0];
        if (regExp.includes('\\n')) regExp = regExp.split('\\n')[0];
        break;
      }
    }
  }

  switch (platform) {
    case 'google meet':
      clearLink(meetRegExps);
      break;

    case 'microsoft teams':
      clearLink(teamsRegExps);
      break;

    case 'zoom':
      clearLink(zoomRegExps, 'zoom');
      break;
  }
  return regExp;
};

export default getAgentCallProcessedLink;
