import { PlanIDEnum } from '../../types';

export const userPlan = (
  selector: 'equalsOrHigher' | 'equalsOrLower' | 'equals' | 'notEquals',
  targetPlan: PlanIDEnum,
  userPlan: PlanIDEnum = PlanIDEnum.PERSONAL,
): boolean => {
  const plansOrder = [
    PlanIDEnum.PERSONAL,
    PlanIDEnum.PROFESSIONAL,
    PlanIDEnum.TEAM,
    PlanIDEnum.TEAM_PLUS,
    PlanIDEnum.ENTERPRISE,
  ];

  const userPlanIndex = plansOrder.indexOf(userPlan);
  const planIndex = plansOrder.indexOf(targetPlan);

  switch (selector) {
    case 'equalsOrHigher':
      return userPlanIndex >= planIndex;
    case 'equalsOrLower':
      return userPlanIndex <= planIndex;
    case 'equals':
      return userPlanIndex === planIndex;
    case 'notEquals':
      return userPlanIndex !== planIndex;
    default:
      return false;
  }
};
