import { useState, useEffect, useCallback } from 'react';
import { useIntegrationApp } from '@integration-app/react';
import { nanoid } from 'nanoid';
import {
  CreateFlowInstanceRequest,
  DataSchema,
  DataSourceInstance,
  DataSourceInstanceAccessor,
  Flow,
  FlowInstance,
  FlowInstanceAccessor,
  Integration,
  UpdateFlowInstanceRequest,
} from '@integration-app/sdk';
// Lib Shared
import { IRREGULAR_INTEGRATION_KEYS } from '../constants';

/**
 * Hook that provides all the logic needed to create an automation.
 */
export const useAutomation = (
  app: Integration,
  flowInstanceKey?: string,
  flowInstanceId?: string,
) => {
  /* #region  Hooks */
  const integrationApp = useIntegrationApp();

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [flowItems, setFlowItems] = useState<Flow[]>([]);
  const [dataSourceInstanceAccessor, setDataSourceInstanceAccessor] = useState<DataSourceInstanceAccessor | null>(null); // prettier-ignore
  const [dataSourceInstance, setDataSourceInstance] = useState<DataSourceInstance | null>(null);
  const [flowInstanceAccessor, setFlowInstanceAccessor] = useState<FlowInstanceAccessor | null>(null); // prettier-ignore
  const [flowInstance, setFlowInstance] = useState<FlowInstance | null>(null);
  const [destinationDataSchema, setDestinationDataSchema] = useState<DataSchema | null>(null);
  const [hasActiveConnection, setHasActiveConnection] = useState(true);
  /* #endregion */

  /* #region  Helpers */
  const updateDataSchema = useCallback(
    (dataSchema: DataSchema | null, integrationKey: string | null) => {
      const isIrrgularIntegration =
        !!integrationKey && IRREGULAR_INTEGRATION_KEYS.includes(integrationKey);

      const updatedDataSchema: DataSchema | null =
        isIrrgularIntegration && !!dataSchema?.properties
          ? {
              ...dataSchema,
              required: Object.keys(dataSchema.properties).map((key) => key),
            }
          : dataSchema;

      setDestinationDataSchema(updatedDataSchema);
    },
    [],
  );

  /**
   * Extracts the data source key from the flow key.
   */
  function getDataSourceKey(flowKey: string) {
    let result: string | null = null;

    switch (flowKey) {
      case 'push-assignments':
        result = 'assignments';
        break;
      case 'push-meeting-notes':
        result = 'meeting-notes';
        break;
      case 'push-transcriptions':
        result = 'transcriptions';
        break;
      default:
        result = null;
    }

    if (!result) throw new Error('No data source key found');
    return result;
  }

  /**
   * Extracts the collection parameters from the path.
   */
  function extractCollectionParametersFromPath(path: string) {
    const arr = (path.split('?')[1] || '').split('&');
    return arr.reduce((acc, pair) => {
      const [key, value] = pair.split('=');
      if (!key) return acc;
      acc[key] = decodeURIComponent(value).replaceAll('+', ' ');
      return acc;
    }, {} as Record<string, string>);
  }

  const getDataTypeOptions = () => {
    return flowItems.map((item) => ({
      label: item.name,
      value: item.key,
    }));
  };

  const getDestinationValue = () => {
    return extractCollectionParametersFromPath(dataSourceInstance?.path || '');
  };
  /* #endregion */

  /* #region  Handlers */
  const updateFlowInstance = async (
    values: CreateFlowInstanceRequest | UpdateFlowInstanceRequest,
  ) => {
    setUpdating(true);
    if (typeof values === 'object' && 'flowKey' in values && !!values.flowKey) {
      // Remove current flow instance accessor if it exists
      if (flowInstanceAccessor) await flowInstanceAccessor.delete();
      // Create a new flow instance accessor
      const instanceKeyValue: string = nanoid();
      const newFlowInstanceAccessor = integrationApp.flowInstance({
        flowKey: values.flowKey,
        integrationKey: app.key,
        instanceKey: instanceKeyValue,
      });

      const newFlowInstance = await newFlowInstanceAccessor.create({
        flowKey: values.flowKey,
        enabled: values.enabled ?? false,
        name: values.name,
      });

      const newDataSourceInstanceAccessor = integrationApp.dataSourceInstance({
        autoCreate: true,
        integrationKey: app.key,
        instanceKey: instanceKeyValue,
        dataSourceKey: getDataSourceKey(values.flowKey),
      });

      const newDataSourceInstance = await newDataSourceInstanceAccessor.get();
      const newDataSourceCollection = await newDataSourceInstanceAccessor.getCollection();
      const parametersSchema = newDataSourceCollection?.parametersSchema || null;

      setDataSourceInstance(newDataSourceInstance);
      setDataSourceInstanceAccessor(newDataSourceInstanceAccessor);
      setFlowInstance(newFlowInstance);
      setFlowInstanceAccessor(newFlowInstanceAccessor);

      updateDataSchema(parametersSchema, newFlowInstance?.integration?.key || null);
    } else {
      if (!flowInstanceAccessor) throw new Error('Use must create a flow instance first');
      const destinations = flowInstance?.parameters?.destinations;
      const data = !destinations
        ? values
        : { ...values, parameters: { ...values.parameters, destinations } };
      const updFlowInstance = await flowInstanceAccessor.patch(data);
      setFlowInstance(updFlowInstance);
    }

    setUpdating(false);
    return Promise.resolve();
  };

  const updateDestinationValue = async (record: Record<string, string>) => {
    if (!dataSourceInstanceAccessor || !dataSourceInstance) {
      throw new Error('Use must create a flow instance first');
    }

    setUpdating(true);

    const currentPath = dataSourceInstance.path?.split('?')[0];
    const valueString = Object.entries(record)
      .map(([k, v]) => `${k}=${v}`)
      .join('&');

    const updDataSourceInstance = await dataSourceInstanceAccessor.patch({
      path: `${currentPath}?${valueString}`,
    });

    setDataSourceInstance(updDataSourceInstance);

    if (!!flowInstanceAccessor) {
      const parameterValues = flowInstance?.parameters || {};

      const parameters = {
        ...parameterValues,
        destinations: { ...parameterValues?.destinations, ...record },
      };

      const updFlowInstance = await flowInstanceAccessor.patch({ parameters });
      setFlowInstance(updFlowInstance);
    }

    setUpdating(false);
  };
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    const getFlows = async () => {
      const flows = await integrationApp.flows.find({
        integrationKey: app.key,
        includeArchived: false,
      });
      // filter out duplicates
      const knownFlows = ['push-assignments', 'push-meeting-notes', 'push-transcriptions'];
      const filteredFlows = flows.items.filter((item) => knownFlows.includes(item.key));
      setFlowItems(filteredFlows);
    };
    getFlows();
  }, [integrationApp, app.key, app.id]);

  // Fetch flow instance if flowId is provided
  useEffect(() => {
    if (!flowInstanceId) return;

    const getFlowInstance = async () => {
      setLoading(true);

      const flowInstanceAccessor = integrationApp.flowInstance({ id: flowInstanceId });
      const flowInstance = await flowInstanceAccessor.get();

      // exit if flow instance is not found
      if (!flowInstance?.flow?.key) {
        setLoading(false);
        return;
      }

      const dataSourceInstanceAccessor = integrationApp.dataSourceInstance({
        autoCreate: false,
        dataSourceKey: getDataSourceKey(flowInstance.flow.key),
        connectionId: flowInstance.connectionId,
        instanceKey: flowInstanceKey,
      });

      const dataSourceInstance = await dataSourceInstanceAccessor.get();
      const dataSourceCollection = await dataSourceInstanceAccessor.getCollection();
      const parametersSchema = dataSourceCollection?.parametersSchema || null;

      setLoading(false);
      setDataSourceInstance(dataSourceInstance);
      setDataSourceInstanceAccessor(dataSourceInstanceAccessor);
      setFlowInstance(flowInstance);
      setFlowInstanceAccessor(flowInstanceAccessor);
      updateDataSchema(parametersSchema, flowInstance?.integration?.key || null);
    };

    getFlowInstance();
  }, [integrationApp, flowInstanceId, flowInstanceKey, updateDataSchema]);

  // check if the integration has an active connection
  useEffect(() => {
    const checkConnection = async () => {
      const integrationAccessor = await integrationApp.integration(app.id)?.get();
      setHasActiveConnection(!!integrationAccessor.connection);
    };

    checkConnection();
  }, [integrationApp, app.id]);
  /* #endregion */

  const hasDestinationParametersSchema =
    !!dataSourceInstance?.collectionSpec && !!dataSourceInstance.collectionSpec.parametersSchema;

  return {
    dataTypeOptions: getDataTypeOptions(),
    dataTypeValue: flowInstance?.flow?.key || '',
    destinationDataSchema,
    destinationValue: getDestinationValue(),
    flowInstance,
    hasActiveConnection,
    hasDestinationParametersSchema,
    integrationKey: flowInstance?.integration?.key || null,
    loading,
    name: flowInstance?.name || '',
    parameters: flowInstance?.parameters as Record<string, any> | null,
    parametersSchema: flowInstance?.parametersSchema || null,
    updateDestinationValue,
    updateFlowInstance,
    updating,
  };
};

export default useAutomation;
