export enum Routes {
  // OAuth2
  OAuth2 = '/oauth2',
  OAuth2Login = '/oauth2/login',
  OAuth2Apple = '/oauth2/apple',
  // Integrations
  Integrations = '/integrations',
  IntegrationsConnectCalendar = '/integrations/connect-calendar',
  IntegrationsConnectIntegrations = '/integrations/connect-integration',
  // Login
  Login = '/login',
  LoginWebView = '/login/webview',
  LoginWithCustomSSO = '/login/custom-sso',
  // Sign Up
  SignUp = '/signup',
  SignUpByInvintation = '/signup/:email/:workspace/:token',
  SignUpByInvintationModalWebView = '/signup/:email/:workspace/:token/webview',
  SignUpModalWebView = '/signup/:signupType/webview',
  SignUpWithCalendar = '/signup/with-calendar',
  SignUpWithEmail = '/signup/with-email/:email',
  SignUpWithoutCalendar = '/signup/without-calendar',
  SignUpSmartMikeUser = '/signup/smartmike-user',
  SignUpSmartMeetingUser = '/signup/smartmeeting-user',
  // Credits
  Credits = '/credits',
  // Analytics
  Analytics = '/analytics',
  // My Chats
  MyChats = '/my-chats',
  MyChatsItem = '/my-chats/chat/:chatId',
  // Actions
  ChangePassword = '/change-password/:token',
  ChangePasswordComplete = '/change-password/complete',
  ChangeEmail = '/change-email/:token',
  ChangeEmailComplete = '/change-email/complete',
  // Password
  ResetPassword = '/reset-password',
  ResetPasswordRecovery = '/reset-password/:userId/:confirmToken',
  ResetPasswordRecoverySuccess = '/reset-password/recovery-success',
  ResetPasswordRequest = '/reset-password/request',
  ResetPasswordRequestSuccess = '/reset-password/request-success',
  // Bookmarks
  Bookmarks = '/bookmarks',
  // Shared With Me
  SharedWithMe = '/shared-with-me',
  SharedWithMeVerifyAgent = '/shared-with-me/verify-agent/:meetingId',
  // My Tasks (Ex Commitments)
  /** @deprecated since SB22 */
  MyCommitments = '/commitments',
  MyAssignments = '/my-assignments',
  // Settings
  Settings = '/settings',
  SettingsCalendars = '/settings/calendars',
  /** @deprecated since SB28 */
  SettingsIntegrations = '/settings/integrations',
  SettingsProfile = '/settings/profile',
  SettingsVoiceID = '/settings/voice-id',
  SettingsWorkspaceAgent = '/settings/workspace-agent',
  SettingsWorkspaceDetails = '/settings/workspace-details',
  SettingsWorkspaceMembers = '/settings/workspace-members',
  SettingsWorkspacePlans = '/settings/workspace-plans',
  SettingsWorkspaceTeams = '/settings/workspace-teams',
  /** @deprecated since SB28 */
  SettingsWorkspaceIntegrations = '/settings/workspace-integrations',
  SettingsWorkspaceBilling = '/settings/workspace-billing',
  SettingsWorkspaceBillingUpdatePaymetMethod = '/settings/workspace-billing/update-payment-method',
  SettingsWorkspaceBillingUpdatePaymetMethodResult = '/settings/workspace-billing/update-payment-method/result',
  // Workspace Transfer
  WorkspaceTransfer = '/workspace-transfer/:workspaceIdEncoded/:token',
  // Checkout
  Checkout = '/checkout',
  CheckoutPlans = '/checkout/plans',
  CheckoutResult = '/checkout/result/:priceId/:planId/:currencyCode',
  CheckoutStart = '/checkout/start/:planId',
  // Home
  Home = '/home',
  HomeVerifyAgent = '/home/verify-agent/:meetingId',
  HomeCreateEnrollmentSample = '/home/create-enrollment-sample',
  HomeCreateEnrollmentComplete = '/home/create-enrollment-complete',
  // Onboardings
  Onboarding = '/onboarding',
  OnboardingPhilipsDevice = '/onboarding/philips-device',
  OnboardingNewAccount = '/onboarding/new-account',
  OnboardingWorkspace = '/onboarding/workspace',
  OnboardingEmailConfirmation = '/onboarding/email-confirmation',
  OnboardingSemblianWorkspace = '/onboarding/semblian-workspace',
  OnboardingSemblianMember = '/onboarding/semblian-member',
  // Meeting
  Meeting = '/meeting/:meetingId',
  MeetingAssignments = '/meeting/:meetingId/assignments',
  MeetingKeyItems = '/meeting/:meetingId/key-items',
  MeetingInsights = '/meeting/:meetingId/insights',
  /** @deprecated since SB22 */
  MeetingMinutes = '/meeting/:meetingId/minutes',
  MeetingModalDetails = '/meeting/:meetingId/:resourceId/details',
  MeetingModalExport = '/meeting/:meetingId/:resourceId/export',
  MeetingModalShare = '/meeting/:meetingId/:resourceId/share',
  MeetingNotes = '/meeting/:meetingId/meeting-notes',
  MeetingTranscript = '/meeting/:meetingId/transcript',
  /** @deprecated since SB22 */
  MeetingGlance = '/meeting/:meetingId/glance',
  // My Automations
  MyAutomations = '/my-automations',
  MyAutomationsNative = '/my-automations/native',
  MyAutomationsCustom = '/my-automations/custom',
  MyAutomationsZapier = '/my-automations/zapier',
  // My Workstreams
  MyWorkstreams = '/my-workstreams',
  // Guest
  GuestNotes = '/minutes-public/:token',
  GuestMeeting = '/guest-access/meeting/:meetingToken',
  GuestMeetingAssignments = '/guest-access/meeting/:meetingToken/assignments',
  GuestMeetingNotes = '/guest-access/meeting/:meetingToken/meeting-notes',
  GuestMeetingKeyItems = '/guest-access/meeting/:meetingToken/key-items',
  GuestMeetingTranscript = '/guest-access/meeting/:meetingToken/transcript',
  /** @deprecated since SB22 */
  GuestMeetingGlance = '/guest-access/meeting/:meetingToken/glance',
  /** @deprecated since SB22 */
  GuestMeetingMinutes = '/guest-access/meeting/:meetingToken/minutes',
  // Confirmations
  Confirm = '/confirm',
  ConfirmEmail = '/confirm/email/:token',
  ConfirmEmailAlias = '/confirm/email-alias/:emailAliasId/:token',
  /** @deprecated since SB26 */
  ConfirmEmailAliasLegacy = '/confirm-email-alias/:emailAliasId/:token',
  // Misc
  AppRoot = '/',
  PageNotFound = '/error/not-found',
  DvtConnect = '/dvt-connect',
  // Errors
  Error = '/error',
  ErrorExecution = '/error/execution',
  ErrorGeneric = '/error/generic',
  ErrorInternalServer = '/error/internal',
  ErrorPermissionDenied = '/error/permission-denied',
  // Workspace Automations
  WorkspaceAutomations = '/workspace-automations',
  WorkspaceAutomationsNative = '/workspace-automations/native',
  WorkspaceAutomationsCustom = '/workspace-automations/custom',
  WorkspaceAutomationsSettings = '/workspace-automations/settings',
}

export default Routes;
