import { DateTime } from 'luxon';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/CloseSharp';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// Sembly UI
import { GenericDialog } from '@sembly-ui';
// App Shared
import { ACHIEVEMENT_TYPES } from '@shared/constants';
import { CHROME_EXTENSION_URI } from '@shared/configuration';
import { Routes } from '@shared/enums';
import { ga } from '@shared/utils';
import { useUserInterface, useUserContext } from '@shared/hooks';
// GraphQL Queries and Types
import confirmMutation from '@shared/queries/MarkUserGuideAsDone.graphql';
import { MarkUserGuideAsDone, MarkUserGuideAsDoneVariables } from '@gql-types/MarkUserGuideAsDone';
import { UserGuideTypes, UserRole } from '@gql-types';

export interface AchievementDescriptionProps {
  type: UserGuideTypes;
  onClose: () => void;
}

const STORAGE_KEY = 'lastAchievementPromotion' as const;

export const Achievement: React.VFC<AchievementDescriptionProps> = ({ type, onClose }) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();
  const user = useUserContext();

  const { update } = useUserInterface();

  const [confirm] = useMutation<MarkUserGuideAsDone, MarkUserGuideAsDoneVariables>(confirmMutation);
  /* #endregion */

  /* #region  Handlers */
  const handleClose = () => {
    onClose();
  };

  const handleShowAllTips = () => {
    update({ isOpenAchievements: true });
    onClose();
  };

  const handleConfirmAchievementPromotion = () => {
    const feature = ACHIEVEMENT_TYPES[type];
    const isAdmin = user.data?.me?.role === UserRole.CUSTOMER_ADMIN;

    localStorage.setItem(STORAGE_KEY, DateTime.local().toISODate());
    ga.event({ category: 'Support', action: 'Level Up Item Completed', label: type });

    confirm({ variables: { type: type } });
    update({
      isOpenResourceCenter: false,
      isOpenAchievements: false,
      currentAchievementType: null,
    });

    switch (type) {
      // Add Sembly Chrome extension
      case UserGuideTypes.CHROME_EXTENSION:
        window.open(CHROME_EXTENSION_URI, '_blank', 'noopener noreferrer');
        break;
      // Pin that important todo in My Commitments
      case UserGuideTypes.COMMITMENTS:
        if (isSmallScreen) {
          window.open(feature.link, '_blank', 'noopener noreferrer');
        } else {
          history.push(Routes.MyAssignments);
        }
        break;
      // Set up your first automation
      case UserGuideTypes.AUTOMATIONS:
        history.push(Routes.MyAutomations);
        break;
      // Connect your calendar to Sembly
      case UserGuideTypes.CONNECT_CALENDAR:
        window.open('https://www.youtube.com/watch?v=JPa3vjvgbgc', '_blank', 'noopener noreferrer');
        break;
      // Share Sembly notes with other participants using Guest View
      case UserGuideTypes.GUEST_VIEW:
        window.open(feature.link, '_blank', 'noopener noreferrer');
        break;
      // Instantly invite Sembly to a meeting
      case UserGuideTypes.INSTA_INVITE:
        update({ isOpenAgentCallPlanner: true });
        break;
      // Invite your team members to work with you in Sembly
      case UserGuideTypes.INVITE_TEAMMEMBER:
        if (isAdmin) {
          history.push(Routes.SettingsWorkspaceMembers);
        } else {
          window.open(feature.link, '_blank', 'noopener noreferrer');
        }
        break;
      // Record a meeting directly from your microphone
      case UserGuideTypes.MANUAL_RECORDING:
        update({ isOpenRecorder: true });
        break;
      // Compose Meeting Minutes
      case UserGuideTypes.MEETING_MINUTES:
        window.open(feature.link, '_blank', 'noopener noreferrer');
        break;
      // Upload your recording to Sembly
      case UserGuideTypes.UPLOAD_RECORDING:
        update({ isOpenUpload: true });
        break;
    }
  };
  /* #endregion */

  const achievement = ACHIEVEMENT_TYPES[type];

  return (
    <GenericDialog
      hideTitle
      dialogProps={{ fullWidth: true, maxWidth: 'sm', fullScreen: isSmallScreen }}
      onClose={handleClose}
    >
      <Box component={DialogContent} mb={4}>
        <div className={styles.head}>
          <Box flex={1}>
            <Typography variant="h6">Level up</Typography>
          </Box>
          <Button
            variant="outlined"
            size="small"
            aria-label="See all tips"
            onClick={handleShowAllTips}
          >
            See all tips
          </Button>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Box mt={2} textAlign="center">
          <iframe
            allowFullScreen
            className={styles.video}
            src={achievement.video}
            title={achievement.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            srcDoc={`
              <style>
                * {padding:0;margin:0;overflow:hidden}
                html,body {height:100%}
                img,span {position:absolute;width:100%;top:0;bottom:0;margin:auto}
                span {height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow: 0 0 0.5em rgb(0 0 0 / 25%);padding: 1rem 0;}
              </style>
              <a href=${achievement.video}>
                <img src=${achievement.image} alt=${achievement.title}>
                <span>▶</span>
              </a>
            `}
          />
          <Box mt={3} mb={2}>
            <Typography gutterBottom variant="h5">
              {achievement.title}
            </Typography>
            <Typography variant="body1">{achievement.description}</Typography>
          </Box>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            aria-label="Try it now"
            onClick={handleConfirmAchievementPromotion}
          >
            <Typography noWrap variant="body1">
              Try it now
            </Typography>
          </Button>
        </Box>
      </Box>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  head: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  video: {
    width: '100%',
    border: 'none',
    aspectRatio: '16 / 9',
  },
}));

export default Achievement;
