import { useState } from 'react';
import { useIntegrations, useIntegrationApp, useFlowInstances } from '@integration-app/react';
import { Integration, FlowInstance } from '@integration-app/sdk';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material UI Icons
import AddIcon from '@material-ui/icons/AddSharp';
// Lib Shared
import IntegrationAppManager from '../dialogs/IntegrationAppManager';
import IntegrationAppsMarketplace from '../dialogs/IntegrationAppsMarketplace';
import ManageThirdPartyAutomationDialog from '../dialogs/ManageThirdPartyAutomationDialog';
import { useConfirmationDialog } from '../hooks';
import {
  AutomationsPlaceholder,
  BrowseAppsButton,
  ConnectedAutomationCard,
  GenericConfirmation,
  IntegrationAppCard,
  NewAutomationMenu,
} from '../components';

export interface WorkspaceNativeAutomationsContainerProps {
  onConnectIntegration: (integrationName: string) => void;
  onDisconnectIntegration: (integrationName: string) => void;
  onIntegrationAppError: (err: Error | unknown) => void;
}

export const WorkspaceNativeAutomationsContainer: React.VFC<
  WorkspaceNativeAutomationsContainerProps
> = ({ onConnectIntegration, onDisconnectIntegration, onIntegrationAppError }) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isConnectingIntegration, setIsConnectingIntegration] = useState(false);
  const [showAppsDialog, setShowAppsDialog] = useState(false);
  const [showAppsMenu, setShowAppsMenu] = useState<HTMLElement | null>(null);
  const [selectedApp, setSelectedApp] = useState<Integration | null>(null);
  const [creatingAutomation, setCreatingAutomation] = useState<Integration | null>(null);
  const [editingAutomation, setEditingAutomation] = useState<FlowInstance | null>(null);

  const integrationApp = useIntegrationApp();

  const {
    items: integrationItems,
    refresh: refreshIntegrations,
    loading: loadingIntegrations,
    error: errorIntegrations,
  } = useIntegrations({ limit: 1000 });

  const {
    items: flowInstanceItems,
    loading: loadingFlowInstances,
    refresh: refreshFlowInstances,
    error: errorFlowInstances,
  } = useFlowInstances({ limit: 1000 });

  const [confirmDelete, DeleteConfirmationDialog] = useConfirmationDialog((resolve) => (
    <GenericConfirmation
      open
      titleProps={{ color: 'inherit' }}
      title="Delete"
      text="Are you sure you want to delete this automation?"
      confirmButtonProps={{ color: 'secondary' }}
      confirmButtonLabel="Delete"
      onCancel={() => resolve(false)}
      onConfirm={() => resolve(true)}
    />
  ));
  /* #endregion */

  /* #region  Handlers */
  const handleOpenAppsDialog = () => {
    setSelectedApp(null);
    setShowAppsDialog(true);
  };

  const handleOpenAppManager = (appId: string) => {
    const app = integrationItems.find((item) => item.id === appId);
    if (!app) throw new Error('There is no app with the provided id');
    setSelectedApp(app);
  };

  const handleCreateAutomation = (app: Integration) => () => {
    setCreatingAutomation(app);
  };

  const handleCloseAutomationManager = async (isDiscarded: boolean, automation?: FlowInstance) => {
    setCreatingAutomation(null);
    setEditingAutomation(null);

    if (!!automation && isDiscarded) {
      try {
        await integrationApp.flowInstance({ id: automation.id })?.delete();
      } catch (error) {
        console.warn(error);
      }
    }

    refreshFlowInstances();
  };

  const handleToggleAutomation =
    (automation: FlowInstance) => async (e: unknown, enabled: boolean) => {
      if (!automation.integration) throw new Error('No integration provided for flow instance');
      try {
        await integrationApp.flowInstance({ id: automation.id }).patch({ enabled });
        refreshFlowInstances();
      } catch (error) {
        onIntegrationAppError(error);
      }
    };

  const handleEditAutomation = (automation: FlowInstance) => async () => {
    setEditingAutomation(automation);
  };

  const handleDeleteAutomation = (automation: FlowInstance) => async () => {
    if (!automation.integration) throw new Error('No integration provided for flow instance');

    const confirmed = await confirmDelete();
    if (!confirmed) return;

    try {
      await integrationApp.flowInstance({ id: automation.id }).delete();
      refreshFlowInstances();
    } catch (error) {
      onIntegrationAppError(error);
    }
  };

  const handleClickOnApp = (app: Integration) => () => {
    setSelectedApp(app);
  };

  const handleConnectApp = (app: Integration) => async () => {
    try {
      setIsConnectingIntegration(true);
      const spec = await integrationApp.integration(app.id).getConnectorSpec();
      if (!!spec.ui) {
        setSelectedApp(null);
        await integrationApp.integration(app.id).open({ showPoweredBy: false });
        setSelectedApp(app);
      } else {
        const result = await integrationApp.integration(app.id).connect({
          allowMultipleConnections: false,
        });
        if (!!result?.id) onConnectIntegration(app.name);
      }
      setIsConnectingIntegration(false);
    } catch (error) {
      setIsConnectingIntegration(false);
      console.warn(error);
    }

    refreshIntegrations();
  };

  const handleDisconnectApp = (app: Integration) => async () => {
    try {
      await integrationApp.integration(app.id).disconnect();
      onDisconnectIntegration(app.name);
    } catch (error) {
      onIntegrationAppError(error);
    }

    refreshIntegrations();
  };
  /* #endregion */

  // Sort integration items based on connection status and name
  const sortedAppItems = integrationItems.sort((a, b) => {
    if (a.connection && !b.connection) return -1;
    if (!a.connection && b.connection) return 1;
    return a.name.localeCompare(b.name);
  });

  const connectedAutomations = flowInstanceItems.filter((item) => !!item.integration);
  const currentSelectedApp: Integration | null =
    integrationItems.find((item) => item.id === selectedApp?.id) || null;

  if (errorIntegrations || errorFlowInstances) {
    onIntegrationAppError(errorIntegrations || errorFlowInstances);
  }

  return (
    <>
      <Box pt={2}>
        <div className={styles.header}>
          <Typography variant="h6">Automations</Typography>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            aria-label="Create a new automation"
            disabled={loadingFlowInstances}
            onClick={(e) => setShowAppsMenu(e.currentTarget)}
            startIcon={<AddIcon />}
          >
            <Typography component="span" variant="body1">
              {isSmallScreen ? 'New' : 'New Automation'}
            </Typography>
          </Button>
          {!!showAppsMenu && (
            <NewAutomationMenu
              anchorEl={showAppsMenu}
              items={integrationItems.filter((item) => !!item.connection)}
              onClose={() => setShowAppsMenu(null)}
              onClick={(app) => setCreatingAutomation(app as Integration)}
              onBrowseApps={handleOpenAppsDialog}
            />
          )}
        </div>

        <div className={styles.connectedIntegrations}>
          {loadingFlowInstances ? (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  variant="rect"
                  key={index}
                  className={styles.connectedIntegrationSkeleton}
                />
              ))}
            </>
          ) : (
            <>
              {!connectedAutomations.length ? (
                <AutomationsPlaceholder />
              ) : (
                <>
                  {connectedAutomations.map((item) => {
                    if (!item.integration) return null;
                    return (
                      <ConnectedAutomationCard
                        enabled={item.enabled}
                        description={''}
                        key={item.id}
                        logoUri={item.integration.logoUri}
                        name={item.name || item.integration.name}
                        onDelete={handleDeleteAutomation(item)}
                        onEdit={handleEditAutomation(item)}
                        onToggleEnabled={handleToggleAutomation(item)}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>

        <div className={styles.header}>
          <Typography variant="h6">Apps</Typography>
        </div>

        <Box mt={3}>
          <BrowseAppsButton onClick={handleOpenAppsDialog} />
        </Box>

        <Box my={3}>
          <Grid container spacing={2}>
            {loadingIntegrations ? (
              <>
                {Array.from({ length: 8 }).map((_, index) => (
                  <Grid item key={index} xs={6} sm={6} md={4} lg={3}>
                    <Skeleton
                      variant="rect"
                      key={index}
                      className={styles.integrationAppSkeleton}
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {sortedAppItems.slice(0, 8).map((item) => (
                  <Grid item key={item.id} xs={6} sm={6} md={4} lg={3}>
                    <IntegrationAppCard
                      name={item.name}
                      logoUri={item.logoUri}
                      connected={!!item.connection}
                      onClick={handleClickOnApp(item)}
                    />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Box>
      </Box>
      {showAppsDialog && (
        <IntegrationAppsMarketplace
          open
          apps={integrationItems}
          onClickOnApp={handleOpenAppManager}
          onClose={() => setShowAppsDialog(false)}
        />
      )}
      {currentSelectedApp && (
        <IntegrationAppManager
          open
          connected={!!currentSelectedApp.connection}
          connecting={isConnectingIntegration}
          identifier={currentSelectedApp.key}
          logoUri={currentSelectedApp.logoUri}
          name={currentSelectedApp.name}
          onBrowseApps={handleOpenAppsDialog}
          onClose={() => setSelectedApp(null)}
          onConnect={handleConnectApp(currentSelectedApp)}
          onDisconnect={handleDisconnectApp(currentSelectedApp)}
          onNewAutomation={handleCreateAutomation(currentSelectedApp)}
        />
      )}
      {creatingAutomation && (
        <ManageThirdPartyAutomationDialog
          open
          automation={creatingAutomation}
          containerType="workspace"
          flowInstanceId={creatingAutomation.id}
          onClose={handleCloseAutomationManager}
          onComplete={refreshFlowInstances}
          onIntegrationAppError={onIntegrationAppError}
        />
      )}
      {editingAutomation && (
        <ManageThirdPartyAutomationDialog
          open
          automation={editingAutomation.integration!}
          containerType="workspace"
          flowInstanceId={editingAutomation.id}
          flowInstanceKey={editingAutomation.instanceKey}
          onClose={handleCloseAutomationManager}
          onComplete={refreshFlowInstances}
          onIntegrationAppError={onIntegrationAppError}
        />
      )}
      {DeleteConfirmationDialog}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  connectedIntegrations: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  connectedIntegrationSkeleton: {
    height: 64,
    borderRadius: theme.shape.borderRadius * 2,
  },
  integrationAppSkeleton: {
    width: '100%',
    height: 114,
    borderRadius: theme.shape.borderRadius * 2,
  },
}));
