import CheckIcon from '@material-ui/icons/CheckSharp';
import Typography from '@material-ui/core/Typography';
import { Banner } from '@shared/components';

export interface CalendarSyncedBannerProps {
  onClose: () => void;
  onReload: () => void;
}

export const CalendarSyncedBanner: React.VFC<CalendarSyncedBannerProps> = ({
  onClose,
  onReload,
}) => (
  <Banner
    icon={<CheckIcon />}
    content={
      <div>
        Calendar successfully synced. Please reload the page to see updates.{' '}
        <Typography
          component="span"
          variant="subtitle2"
          color="inherit"
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={onReload}
        >
          Reload Page
        </Typography>
      </div>
    }
    bgcolor={'status.success.backgroudColor.main'}
    color={'status.success.color'}
    onClose={onClose}
  />
);

export default CalendarSyncedBanner;
