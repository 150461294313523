import { useHistory } from 'react-router-dom';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// App Shared
import { APP_DRAWER_WIDTH } from '@shared/configuration';
import { Routes as R } from '@shared/enums';
import { SettingsModuleContainer } from '@modules/Settings';
import { logout as performLogout } from '@shared/clients/authClient';
import { useUserContext, useUserInterface } from '@shared/hooks';

export interface AppDrawerPopupMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AppDrawerPopupMenu: React.VFC<AppDrawerPopupMenuProps> = ({ isOpen, onClose }) => {
  /* #region  Hooks */
  const styles = useStyles();
  const history = useHistory();

  const { data: user, loading } = useUserContext();
  const { update } = useUserInterface();
  /* #endregion */

  /* #region  Handlers */
  const handleRedirectToSettings = () => {
    history.push(R.Settings);
    update({ isAppDrawerOpen: false });
    onClose();
  };

  const handleSignOut = () => {
    performLogout();
  };

  const handleMenuOpened = () => {
    SettingsModuleContainer.preload();
  };
  /* #endregion */

  return (
    <Grow mountOnEnter unmountOnExit in={isOpen} onEntered={handleMenuOpened}>
      <div>
        <ClickAwayListener onClickAway={onClose}>
          <Paper className={styles.root}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Avatar alt={user?.me?.fullName} src={user?.me?.avatar || undefined} />
                <Box my={1} textAlign="center">
                  <Typography gutterBottom variant="subtitle2">
                    {user?.me?.fullName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {user?.me?.email}
                  </Typography>
                </Box>

                <Button
                  fullWidth
                  variant="outlined"
                  className={styles.button}
                  aria-label="Settings"
                  onClick={handleRedirectToSettings}
                >
                  Settings
                </Button>

                <Button
                  fullWidth
                  variant="outlined"
                  className={styles.button}
                  aria-label="Sign Out"
                  onClick={handleSignOut}
                >
                  Sign Out
                </Button>
              </>
            )}
          </Paper>
        </ClickAwayListener>
      </div>
    </Grow>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    left: '0.5rem',
    bottom: theme.spacing(6),
    width: `calc(${APP_DRAWER_WIDTH}px - 1rem)`,
    borderRadius: '1.5rem',
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[10],
    zIndex: 1,
  },
  button: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize,
  },
}));

export default AppDrawerPopupMenu;
