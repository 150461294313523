import { useMutation, useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useIntegrationApp } from '@integration-app/react';
// Material UI
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import KeyItemsIntegrations from '../components/integrations/KeyItemsIntegrations';
import TodoIntegrationsDialog from '../dialogs/integrations/TodoIntegrationsDialog';
import NotionDestinationPicker from '../dialogs/integrations/NotionDestinationPicker';
// GraphQL Queries & Types
import actionMutation from '../graphql/mutations/SendActionToCustomOutboundIntegration.graphql';
import createMSTodoTaskFromKeyItemMutation from '../graphql/mutations/CreateTodoTaskFromKeyItem.graphql';
import userOutboundIntegrationsQuery from '../graphql/queries/UserOutboundIntegrations.graphql';
import sendMeetingKeyItemToNotionMutation from '../graphql/mutations/SendMeetingKeyItemToNotion.graphql';
import sendToIntegrationFabricMutation from '../graphql/mutations/SendAssignmentToIntegrationFabric.graphql';
import {
  ActionsCustomIntegrationSettingsContentTypes,
  CreateTodoTaskFromKeyItem,
  CreateTodoTaskFromKeyItemVariables,
  ExtendedFlowInstance,
  GenericKeyItemsDestinationList,
  GenericNotionDatabase,
  GraphError,
  IntegrationTypes,
  NotionIntegrationsTypes,
  SendActionToCustomOutboundIntegration,
  SendActionToCustomOutboundIntegrationVariables,
  SendAssignmentToIntegrationFabric,
  SendAssignmentToIntegrationFabricVariables,
  SendMeetingKeyItemToNotion,
  SendMeetingKeyItemToNotionVariables,
  UserOutboundIntegrations,
} from '../types';

export interface KeyItemIntegrationsMenuContainerProps {
  anchorEl: HTMLElement;
  keyItemId: string;
  onClickOnDiscoverIntegrations: () => void;
  onClose: () => void;
  onError: (error: GraphError) => void;
  onSuccess: (integrationType: string) => void;
}

export const KeyItemIntegrationsMenuContainer: React.VFC<KeyItemIntegrationsMenuContainerProps> = ({
  anchorEl,
  keyItemId,
  onClickOnDiscoverIntegrations,
  onClose,
  onError,
  onSuccess,
}) => {
  /* #region Hooks */
  const styles = useStyles();

  const integrationApp = useIntegrationApp();

  const [flowInstances, setFlowInstances] = useState<ExtendedFlowInstance[]>([]);
  const [isLoadingFlowInstances, setIsLoadingFlowInstances] = useState(false);
  const [openListDialogs, setOpenListDialogs] = useState<IntegrationTypes | null>(null);
  /* #endregion */

  /* #region Apollo Hooks */
  const { data: userOutboundIntegrationsData, loading: isLoading } =
    useQuery<UserOutboundIntegrations>(userOutboundIntegrationsQuery);

  const [createTodoTaskFromKeyItem, { loading: isTodoSending }] = useMutation<
    CreateTodoTaskFromKeyItem,
    CreateTodoTaskFromKeyItemVariables
  >(createMSTodoTaskFromKeyItemMutation);

  const [sendToCustomActionIntegration, { loading: isCustomSending }] = useMutation<
    SendActionToCustomOutboundIntegration,
    SendActionToCustomOutboundIntegrationVariables
  >(actionMutation);

  const [sendToCustomNotionIntegration, { loading: isSendingToNotion }] = useMutation<
    SendMeetingKeyItemToNotion,
    SendMeetingKeyItemToNotionVariables
  >(sendMeetingKeyItemToNotionMutation, {
    refetchQueries: [userOutboundIntegrationsQuery],
  });

  const [sendToIntegrationFabric, { loading: isSendingToIntegrationFabric }] = useMutation<
    SendAssignmentToIntegrationFabric,
    SendAssignmentToIntegrationFabricVariables
  >(sendToIntegrationFabricMutation);
  /* #endregion */

  /* #region Handlers */
  const handleOpenListDialog = (dialogType: IntegrationTypes) => {
    setOpenListDialogs(dialogType);
  };

  const handleCloseListDialogs = () => {
    setOpenListDialogs(null);
  };

  const handleChangeTodoItem = (
    todoListItem: GenericKeyItemsDestinationList | null,
    integrationType: IntegrationTypes,
  ) => {
    handleSendKeyItemsToTasksIntegration(todoListItem, integrationType);
    handleCloseListDialogs();
  };

  const handleSendKeyItemToNotionIntegration = async (
    destinationDatabase: GenericNotionDatabase | null,
  ) => {
    if (!destinationDatabase) {
      setOpenListDialogs(IntegrationTypes.NOTION_ASSIGNMENTS);
      return;
    }

    const result = await sendToCustomNotionIntegration({
      variables: {
        contentType: ActionsCustomIntegrationSettingsContentTypes.ACTIONS,
        destinationDatabaseId: destinationDatabase.id,
        itemId: keyItemId,
      },
    });

    if (result.data?.sendMeetingKeyItemToNotion?.success) {
      onSuccess(IntegrationTypes.NOTION_ASSIGNMENTS);
      onClose();
    } else {
      onError(result.data?.sendMeetingKeyItemToNotion?.errors);
    }
  };

  const handleSendKeyItemsToTasksIntegration = async (
    tasksItemDestination: GenericKeyItemsDestinationList | null,
    integrationType: IntegrationTypes,
  ) => {
    if (!tasksItemDestination) {
      setOpenListDialogs(integrationType);
      return;
    }

    const result = await createTodoTaskFromKeyItem({
      variables: {
        settings: {
          integrationType: integrationType,
          id: keyItemId,
          contentType: ActionsCustomIntegrationSettingsContentTypes.ACTIONS,
          destinationListId: tasksItemDestination.id,
        },
      },
      refetchQueries: [userOutboundIntegrationsQuery],
    });

    if (result.data?.createTodoTaskFromKeyItem?.success) {
      onSuccess(integrationType);
    } else {
      onError(result.data?.createTodoTaskFromKeyItem?.errors);
    }
    onClose();
  };

  const handleSendKeyItemToCustomIntegration = async (
    integrationId: string,
    contentType: ActionsCustomIntegrationSettingsContentTypes,
  ) => {
    const result = await sendToCustomActionIntegration({
      variables: { keyItemId, integrationId, contentType },
    });
    if (result.data?.sendActionToCustomOutboundIntegration?.success) {
      onSuccess('Custom');
    } else {
      onError(result.data?.sendActionToCustomOutboundIntegration?.errors);
    }
    onClose();
  };

  const handleSendFabricIntegration = async (automation: ExtendedFlowInstance) => {
    if (automation.flow?.key !== 'push-assignments') {
      throw new Error('Unsupported automation flow');
    }

    const result = await sendToIntegrationFabric({
      variables: {
        assignmentId: keyItemId,
        flowInstanceId: automation.id,
      },
    });

    if (result.data?.sendAssignmentToIntegrationFabric?.success) {
      onSuccess('Fabric');
    } else {
      onError(result.data?.sendAssignmentToIntegrationFabric?.errors);
    }
    onClose();
  };
  /* #endregion */

  useEffect(() => {
    if (!integrationApp) return;

    const fetchData = async () => {
      setIsLoadingFlowInstances(true);
      const flows = await integrationApp.flows.findAll({ includeArchived: false });
      const flowInstances = await integrationApp.flowInstances.find({ enabled: true, limit: 1000 });

      let updatedFlowInstances: ExtendedFlowInstance[] = [];

      flowInstances?.items?.forEach((flowInstance) => {
        const flow = flows?.find((f) => f.id === flowInstance.universalFlowId);
        if (!!flow && flow.key === 'push-assignments') {
          updatedFlowInstances.push({ ...flowInstance, flow });
        }
      });

      // Update the state with the fetched data
      setFlowInstances(updatedFlowInstances);
      setIsLoadingFlowInstances(false);
    };

    fetchData();
  }, [integrationApp]);

  /* #region Render Helpers */
  const isSending =
    isTodoSending || isCustomSending || isSendingToNotion || isSendingToIntegrationFabric;
  /* #endregion */

  if (isLoading || isLoadingFlowInstances) {
    return (
      <Menu
        variant="menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        classes={{ paper: styles.paper, list: styles.menu }}
        onClose={onClose}
      >
        <Box py={8} textAlign="center">
          <CircularProgress size={24} />
          <Box mt={1} />
          <Typography variant="body2">Requesting integrations...</Typography>
        </Box>
      </Menu>
    );
  }

  return (
    <Menu
      variant="menu"
      open={!!anchorEl}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}
      classes={{ paper: styles.paper, list: styles.menu }}
      onClose={onClose}
    >
      <div>
        {!openListDialogs && (
          <KeyItemsIntegrations
            isSending={isSending}
            fabricIntegrations={flowInstances}
            userOutboundIntegrations={userOutboundIntegrationsData}
            onClickOnDiscoverIntegrations={onClickOnDiscoverIntegrations}
            onOpenListDialog={handleOpenListDialog}
            onSendCustom={handleSendKeyItemToCustomIntegration}
            onSendTodo={handleSendKeyItemsToTasksIntegration}
            onSendToNotion={handleSendKeyItemToNotionIntegration}
            onSendFabricItem={handleSendFabricIntegration}
          />
        )}
        {openListDialogs === IntegrationTypes.MS_TODO && (
          <TodoIntegrationsDialog
            integrationType={IntegrationTypes.MS_TODO}
            onClose={handleCloseListDialogs}
            onSend={handleChangeTodoItem}
          />
        )}
        {openListDialogs === IntegrationTypes.GOOGLE_TASKS && (
          <TodoIntegrationsDialog
            integrationType={IntegrationTypes.GOOGLE_TASKS}
            onClose={handleCloseListDialogs}
            onSend={handleChangeTodoItem}
          />
        )}
        {openListDialogs === IntegrationTypes.NOTION_ASSIGNMENTS && (
          <NotionDestinationPicker
            integrationType={NotionIntegrationsTypes.NOTION_ASSIGNMENTS_INTEGRATION}
            onClose={handleCloseListDialogs}
            onSend={handleSendKeyItemToNotionIntegration}
            onError={onError}
          />
        )}
      </div>
    </Menu>
  );
};

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: 0,
  },
  paper: {
    minWidth: '40ch',
    maxWidth: '60ch',
    maxHeight: '60vh',
    width: `calc(100vw - ${theme.spacing(4)}px)`,
    borderRadius: theme.shape.borderRadius,
  },
}));

export default KeyItemIntegrationsMenuContainer;
