import './wdyr';

import ReactDOM from 'react-dom';
import { Suspense } from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client/react';
import { Integrations } from '@sentry/tracing';
import { init, ErrorBoundary } from '@sentry/react';
import { ToastContainer } from 'react-toastify';
import { CssBaseline, ThemeProvider, createTheme } from '@material-ui/core';
// Sembly
import { THEME_OPTIONS } from '@sembly-ui/theme';
// App Shared Components
import AlertContainer from '@shared/components/AlertContainer';
import ErrorBoundaryDialog from '@shared/components/ErrorBoundaryDialog';
import ModuleRouter from '@shared/components/ModuleRouter';
import SuspenseFallback from '@shared/components/SuspenseFallback';
import AppOverlay from '@shared/containers/AppOverlay';
import { STORAGE_MEETING_LIST_SCROLL_TARGET_KEY } from '@shared/configuration';
// App Modules
import modules from '@modules';
// App Shared
import * as ga from '@shared/utils/analytics';
import { breadcrumbUIClick, breadcrumbUIInput } from '@shared/utils/sentry';
import Routes from '@shared/enums/Routes';
import apolloClient from '@shared/clients/ApolloClient';
import history from '@shared/clients/History';
import isLocalhost from '@shared/utils/location/IsLocalhost';
import { GlobalNotifications } from '@shared/containers';
import { UserProvider, UserInterfaceProvider } from '@shared/hooks';
// Analytics Utils
import reportWebVitals from './reportWebVitals';
// CSS
import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const App = () => (
  <ApolloProvider client={apolloClient}>
    <ThemeProvider theme={createTheme(THEME_OPTIONS)}>
      <Router history={history}>
        <ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorBoundaryDialog error={error} onClose={resetError} />
          )}
        >
          <CssBaseline />
          <ToastContainer enableMultiContainer position="bottom-left" limit={3} />
          <AlertContainer limit={1} />
          <Suspense fallback={<SuspenseFallback />}>
            <UserProvider>
              <UserInterfaceProvider>
                <GlobalNotifications />
                <ModuleRouter
                  modules={modules}
                  homeRoute={Routes.Home}
                  loginRoute={Routes.Login}
                  rootRoute={Routes.AppRoot}
                >
                  <AppOverlay />
                </ModuleRouter>
              </UserInterfaceProvider>
            </UserProvider>
          </Suspense>
        </ErrorBoundary>
      </Router>
    </ThemeProvider>
  </ApolloProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));

// Clear temporary data from the previous session
sessionStorage.removeItem(STORAGE_MEETING_LIST_SCROLL_TARGET_KEY);

// Init Sentry
init({
  enabled: !isLocalhost(),
  release: 'SB34.2',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 1.0,
  ignoreErrors: [
    "SyntaxError: Unexpected token '<'",
    'Failed to load Stripe.js',
    'Non-Error exception captured',
    'Non-Error promise rejection',
    'The play() request was interrupted by a call to pause()',
    'chrome-extension://',
  ],
  beforeBreadcrumb(breadcrumb, hint) {
    switch (breadcrumb.category) {
      case 'ui.click':
        return breadcrumbUIClick(breadcrumb, hint);
      case 'ui.input':
        return breadcrumbUIInput(breadcrumb, hint);
    }

    return breadcrumb;
  },
});

// Init Web Vitals
reportWebVitals(({ id, name, value }) =>
  ga.event({
    category: 'Web Vitals',
    action: name,
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  }),
);
