import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { format } from 'date-fns';
import { useState, useLayoutEffect } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import Link from '@material-ui/core/Link';
import QuestionMarkIcon from '@material-ui/icons/HelpOutlineSharp';
import Typography from '@material-ui/core/Typography';
// Lib Queries
import assignmentsQuery from '../graphql/queries/MeetingAssignmentsContainerQuery.graphql';
import editAssignmentMutation from '../graphql/mutations/EditAssignment.graphql';
import genericAssignmentFragment from '../graphql/fragments/GenericAssignment.graphql';
import meetingQuery from '../graphql/queries/Meeting.graphql';
// Lib Components
import ActionItemMenusContainer from './ActionItemMenusContainer';
import MeetingAssignmentsGroup, {
  AssignmentsGroupToggleMenuHandler,
} from '../components/MeetingAssignmentsGroup';
import MeetingPlaceholderAssignments from '../components/MeetingPlaceholderAssignments';
import MeetingPlaceholderProcessing from '../components/MeetingPlaceholderProcessing';
import VoiceIdPromotionBanner from '../components/VoiceIdPromotionBanner';
import { isAsianLang } from '../utils';
// Lib Generated Types
import {
  ActionItemMenuType,
  ActionItemType,
  AgentCallPlatform,
  EditAssignment,
  EditAssignmentVariables,
  GenericAssignment,
  GenericDefaultUser,
  GraphError,
  Meeting,
  MeetingAssignmentsContainerQuery,
  MeetingAssignmentsContainerQueryVariables,
  MeetingStatuses,
  MeetingVariables,
  RedirectTarget,
} from '../types';

/* #region  Types */
interface ContextMenu {
  anchorEl: HTMLElement;
  item: GenericAssignment;
  type: ActionItemMenuType;
}

interface CopyContentProps {
  meetingId: string;
  keyItemId: string;
  item: GenericAssignment;
}

interface CopyLinkProps {
  meetingId: string;
  keyItemId: string;
}

interface Participant {
  id: string;
  user: GenericDefaultUser | string | null;
  userType: 'assignee' | 'performer';
}

export type MeetingAssignmentsContainerActionEventType =
  | 'Make Inactive'
  | 'Make Active'
  | 'Make Completed'
  | 'Make Incompleted'
  | null;

export interface MeetingAssignmentsContainerProps {
  enableBetaFeatures?: boolean;
  isAuthorizedToExport?: boolean;
  isForceHiddenIntegrations?: boolean;
  isAuthorizedToView: boolean;
  isPromoteUpgrade?: boolean;
  isPromoteVoiceId?: boolean;
  isRestrictedKeyItemsPromotion: boolean;
  meetingId: string;
  scrollTarget?: string | null;
  onClickOnCopyContent: (props: CopyContentProps) => void;
  onClickOnCopyLink: (props: CopyLinkProps) => void;
  onClickOnDiscoverIntegrations?: () => void;
  onClickOnDismissVoiceIdPromotion?: () => void;
  onClickOnManageVoiceId?: () => void;
  onClickOnUpgradePlan?: () => void;
  onPostingSuccess?: (integrationType: string) => void;
  onRedirect: (target: RedirectTarget) => void;
  onResponseError?: (error: GraphError) => void;
  onUpdateError?: (error: GraphError) => void;
  onUpdateSuccess?: (actionEventType: MeetingAssignmentsContainerActionEventType) => void;
  onExportContent?: (tasks: GenericAssignment[]) => void;
}
/* #endregion */

/**
 * The container component that renders the assignments of a meeting
 */
export const MeetingAssignmentsContainer: React.VFC<MeetingAssignmentsContainerProps> = ({
  enableBetaFeatures = false,
  isAuthorizedToExport = false,
  isAuthorizedToView,
  isForceHiddenIntegrations = false,
  isPromoteUpgrade = false,
  isPromoteVoiceId = false,
  isRestrictedKeyItemsPromotion,
  meetingId,
  scrollTarget = null,
  onClickOnCopyContent,
  onClickOnCopyLink,
  onClickOnDiscoverIntegrations = () => null,
  onClickOnDismissVoiceIdPromotion,
  onClickOnManageVoiceId,
  onClickOnUpgradePlan,
  onPostingSuccess = () => null,
  onRedirect,
  onExportContent = () => null,
  onResponseError = () => null,
  onUpdateError = () => null,
  onUpdateSuccess = () => null,
}) => {
  /* #region  Hooks */
  const client = useApolloClient();

  const [isPromotingVoiceId, setIsPromotingVoiceId] = useState(true);
  const [isRefetchingKeyItems, setIsRefetchingKeyItems] = useState(false);
  const [currentContextMenu, setCurrentContextMenu] = useState<ContextMenu | null>(null);

  const {
    data: assignmentsData,
    loading: isAssignmentsLoading,
    refetch: refetchKeyItems,
  } = useQuery<MeetingAssignmentsContainerQuery, MeetingAssignmentsContainerQueryVariables>(
    assignmentsQuery,
    {
      variables: { meetingId },
      skip: !isAuthorizedToView,
    },
  );

  const { data: meetingData, loading: isMeetingLoading } = useQuery<Meeting, MeetingVariables>(
    meetingQuery,
    {
      variables: { meetingId },
      onCompleted: async (data) => {
        const hasAssignments = (assignmentsData?.assignments?.length || 0) > 0;
        if (
          data?.meeting?.processingResults?.processedAssignments &&
          !hasAssignments &&
          !isAssignmentsLoading
        ) {
          // Refetching the key items when the meeting is completly processed
          setIsRefetchingKeyItems(true);
          await refetchKeyItems();
          setIsRefetchingKeyItems(false);
        }
      },
    },
  );

  const [editAssignment] = useMutation<EditAssignment, EditAssignmentVariables>(
    editAssignmentMutation,
  );
  /* #endregion */

  /* #region  Helpers */
  const getCachedAssignment = (id: string): GenericAssignment | null => {
    return client.readFragment({
      id: client.cache.identify({ id, __typename: 'AssignmentType' }),
      fragment: genericAssignmentFragment,
      fragmentName: 'GenericAssignment',
    });
  };

  const updateAssignment = async (
    itemId: string,
    variables: Omit<EditAssignmentVariables, 'id'>,
  ) => {
    const cachedData = getCachedAssignment(itemId);
    if (!cachedData) return;

    const acitionVariables: EditAssignmentVariables = {
      assigneeId: cachedData.assignedBy?.id,
      assigneeRawName: cachedData.rawAssignedBy,
      customHeaderText: cachedData.customHeaderText,
      customText: cachedData.customText,
      dueDate: cachedData.dueDate,
      id: +itemId,
      isCompleted: cachedData.isCompleted,
      inDiscussionWith: cachedData.inDiscussionWith,
      isActive: cachedData.isActive,
      isPinned: cachedData.isPinned,
      performerId: cachedData.performer?.id,
      performerRawName: cachedData.rawPerformerName,
      rawTiming: cachedData.rawTiming,
      ...variables,
    };

    const optimisticAssignment: GenericAssignment = {
      ...cachedData,
      ...variables,
      isActive: variables.isActive ?? cachedData.isActive,
      isCompleted: variables.isCompleted ?? cachedData.isCompleted,
      isPinned: variables.isPinned ?? cachedData.isPinned,
    };

    const result = (
      await editAssignment({
        variables: acitionVariables,
        optimisticResponse: {
          editAssignment: {
            __typename: 'EditAssignmentMutationPayload',
            assignment: optimisticAssignment,
            errors: null,
            success: true,
          },
        },
      })
    ).data?.editAssignment;

    if (result?.success) {
      let actionType: MeetingAssignmentsContainerActionEventType = null;
      if (variables.isActive === true) actionType = 'Make Active';
      if (variables.isActive === false) actionType = 'Make Inactive';
      if (variables.isCompleted === true) actionType = 'Make Completed';
      if (variables.isCompleted === false) actionType = 'Make Incompleted';
      onUpdateSuccess(actionType);
    } else {
      onUpdateError(result?.errors);
    }
  };
  /* #endregion */

  /* #region  Handlers */
  const handleToggleContextMenu: AssignmentsGroupToggleMenuHandler = (data) => {
    setCurrentContextMenu(data);
  };

  const handleClickOnCopyContent = ({ meetingId, keyItemId, item }: CopyContentProps) => {
    onClickOnCopyContent({ meetingId, keyItemId, item });
  };

  const handleClickOnCopyLink = ({ meetingId, keyItemId }: CopyLinkProps) => {
    onClickOnCopyLink({ meetingId, keyItemId });
  };

  const handleChangeActivity = async (actionItem: GenericAssignment) => {
    const keyItemId = actionItem.id;
    const isActive = !actionItem.isActive;
    updateAssignment(keyItemId, { isActive });
  };

  const handleChangeActionParticipant = async ({ id, user, userType }: Participant) => {
    const isAssigneeEvent = userType === 'assignee';
    const isPerformerEvent = userType === 'performer';
    const isUnknownUser = !!user && typeof user === 'string';
    const isKnownUser = !!user && typeof user !== 'string';
    const isRemoveUser = user === null;

    const cachedData = getCachedAssignment(id);
    if (!cachedData) return;

    let optimisticData: GenericAssignment = Object.assign({}, cachedData);
    let variables: EditAssignmentVariables = {
      assigneeId: cachedData.assignedBy?.id,
      assigneeRawName: cachedData.rawAssignedBy,
      customHeaderText: cachedData.customHeaderText,
      customText: cachedData.customText,
      dueDate: cachedData.dueDate,
      id: +id,
      isCompleted: cachedData.isCompleted,
      inDiscussionWith: cachedData.inDiscussionWith,
      isActive: cachedData.isActive,
      isPinned: cachedData.isPinned,
      performerId: cachedData.performer?.id,
      performerRawName: cachedData.rawPerformerName,
      rawTiming: cachedData.rawTiming,
    };

    if (isPerformerEvent) {
      if (isUnknownUser) {
        variables.performerId = null;
        variables.performerRawName = user;
        optimisticData.performer = null;
        optimisticData.rawPerformerName = user;
      }
      if (isKnownUser) {
        variables.performerId = user.id;
        variables.performerRawName = null;
        optimisticData.performer = { ...user, __typename: 'DefaultUserType' };
        optimisticData.rawPerformerName = null;
      }
      if (isRemoveUser) {
        variables.performerId = null;
        variables.performerRawName = null;
        optimisticData.performer = null;
        optimisticData.rawPerformerName = null;
      }
    }

    if (isAssigneeEvent) {
      if (isUnknownUser) {
        variables.assigneeId = null;
        variables.assigneeRawName = user;
        optimisticData.assignedBy = null;
        optimisticData.rawAssignedBy = user;
      }
      if (isKnownUser) {
        variables.assigneeId = user.id;
        variables.assigneeRawName = null;
        optimisticData.assignedBy = { ...user, __typename: 'DefaultUserType' };
        optimisticData.rawAssignedBy = null;
      }
      if (isRemoveUser) {
        variables.assigneeId = null;
        variables.assigneeRawName = null;
        optimisticData.assignedBy = null;
        optimisticData.rawAssignedBy = null;
      }
    }

    const { data } = await editAssignment({
      variables,
      optimisticResponse: {
        editAssignment: {
          __typename: 'EditAssignmentMutationPayload',
          success: true,
          errors: [],
          assignment: optimisticData,
        },
      },
    });

    if (!data?.editAssignment?.success) {
      onUpdateError(data?.editAssignment?.errors);
    } else {
      onUpdateSuccess(null);
    }
  };

  const handleChangeInDiscussionWith = (keyItem: GenericAssignment, inDiscussionWith: string) => {
    updateAssignment(keyItem.id, { inDiscussionWith });
  };

  const handleChangeText = (keyItem: GenericAssignment, customText: string) => {
    updateAssignment(keyItem.id, { customText });
  };

  const handleChangeTitle = (keyItem: GenericAssignment, customHeaderText: string) => {
    updateAssignment(keyItem.id, { customHeaderText });
  };

  const handleChangeDueDate = async (id: string, dueDate: Date | string | null) => {
    const cachedData = getCachedAssignment(id);
    if (!cachedData) return;

    const isCustomDate = dueDate !== null && typeof dueDate === 'string';
    const isDataObject = dueDate !== null && typeof dueDate === 'object';

    const { data } = await editAssignment({
      variables: {
        assigneeId: cachedData.assignedBy?.id,
        assigneeRawName: cachedData.rawAssignedBy,
        customHeaderText: cachedData.customHeaderText,
        customText: cachedData.customText || '',
        dueDate: isDataObject ? format(dueDate, 'yyyy-MM-dd') : null,
        id: +id,
        isCompleted: cachedData.isCompleted,
        inDiscussionWith: cachedData.inDiscussionWith,
        isActive: cachedData.isActive,
        isPinned: cachedData.isPinned,
        performerId: cachedData.performer?.id,
        performerRawName: cachedData.rawPerformerName,
        rawTiming: isCustomDate ? dueDate : null,
      },
      optimisticResponse: {
        editAssignment: {
          __typename: 'EditAssignmentMutationPayload',
          success: true,
          errors: [],
          assignment: {
            ...cachedData,
            rawTiming: isCustomDate ? dueDate : null,
            dueDate: isDataObject ? format(dueDate, 'yyyy-MM-dd') : null,
          },
        },
      },
    });

    if (!data?.editAssignment?.success) {
      onResponseError(data?.editAssignment?.errors);
    } else {
      onUpdateSuccess(null);
    }
  };

  const handleManageVoiceId = () => {
    if (!onClickOnManageVoiceId) {
      throw new Error('If `isPromoteVoiceId` is `true`, handlers is required');
    }
    onClickOnManageVoiceId();
  };

  const handleDismissVoiceIdPromotionBanner = () => {
    setIsPromotingVoiceId(false);
    if (onClickOnDismissVoiceIdPromotion) {
      onClickOnDismissVoiceIdPromotion();
    }
  };

  const handleClickOnUpgradePlan = () => {
    if (!onClickOnUpgradePlan) {
      throw new Error('If `isPromoteUpgrade` is `true`, handler is required');
    }
    onClickOnUpgradePlan();
  };

  const handleCloseContextMenu = () => {
    setCurrentContextMenu(null);
  };

  const handleClickOnExport = () => {
    if (!isAuthorizedToExport) return;
    onExportContent(assignmentsData?.assignments || []);
  };
  /* #endregion */

  /* #region  Effects */
  // Automatic scrolling to the requested target activity
  useLayoutEffect(() => {
    if (!scrollTarget || !assignmentsData?.assignments) return;

    const targets = Array.from(
      document.querySelectorAll(`[data-scroll-item-id="${scrollTarget}"]`),
    );

    if (!targets.length) return;

    const firstTarget = targets[0];
    firstTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
    targets?.forEach((target) => target.classList.add('highlighted'));

    function handleRemoveHightlight() {
      targets.forEach((target) => target.classList.remove('highlighted'));
    }

    document.addEventListener('click', handleRemoveHightlight, { once: true });

    return () => {
      window.removeEventListener('click', handleRemoveHightlight);
    };
  }, [scrollTarget, assignmentsData?.assignments]);
  /* #endregion */

  /* #region  Render Helpers */
  const meeting = meetingData?.meeting;
  const agentCall = meeting?.agentCall;
  const assignments = sortBy(assignmentsData?.assignments, (o) => parseInt(o.id, 10));
  const hasAssignments = (assignments?.length || 0) > 0;
  const processingResults = meeting?.processingResults;
  const meetingMainLang = meeting?.processingResults?.mainLanguage;
  const isTranscribed = meeting?.processingResults?.processedTranscribing ?? false;
  const isProcessedAssignments = processingResults?.processedAssignments ?? false;
  const isProcessedAnalytics = processingResults?.processedAnalytics ?? false;
  const isProcessedNotes = processingResults?.processedMeetingNotes ?? false;
  const isProcessingComplete = meeting?.status !== MeetingStatuses.processing;
  const isProcessed = isProcessingComplete || isProcessedAssignments;
  const isManualUpload = agentCall?.platform === AgentCallPlatform.MANUAL_UPLOAD;
  const isExportable = !isAsianLang(meetingMainLang);
  const groupedItems = groupBy(assignments, (item) => item.itemType);
  /* #endregion */

  // Props validation
  if (isPromoteUpgrade && !onClickOnUpgradePlan) {
    throw new Error('If `isPromoteUpgrade` is `true`, handler is required');
  } else if (isPromoteVoiceId && (!onClickOnManageVoiceId || !onClickOnDismissVoiceIdPromotion)) {
    throw new Error('If `isPromoteVoiceId` is `true`, handlers is required');
  }

  // Render loading indicator if the data is not ready
  if (isMeetingLoading || isAssignmentsLoading || isRefetchingKeyItems) {
    return (
      <Box position="relative" width="100%" paddingTop={4}>
        <Box textAlign="center" mt={8}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  // Render fallback
  if (!meeting) return null;

  return (
    <>
      {hasAssignments ? (
        <Box mt={4}>
          {isPromoteVoiceId && isPromotingVoiceId && (
            <VoiceIdPromotionBanner
              onClose={handleDismissVoiceIdPromotionBanner}
              onManage={handleManageVoiceId}
            />
          )}
          <Box mb={1} display="flex" flexWrap="wrap" alignItems="center" gridGap={8}>
            <Box flex={1}>
              <Typography variant="h4">
                Tasks{' '}
                <Link
                  color="textSecondary"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Help"
                  href={'https://helpdesk.sembly.ai/hc/en-us/articles/7495413385873-Tasks'}
                  style={{ display: 'inline-block', lineHeight: '1rem', fontSize: '1rem' }}
                >
                  <QuestionMarkIcon fontSize="inherit" />
                </Link>
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" gridGap={6}>
              {isAuthorizedToExport && isExportable && (
                <Button
                  size="small"
                  variant="outlined"
                  disabled={!assignments?.length}
                  startIcon={<GetAppIcon color="action" />}
                  onClick={handleClickOnExport}
                >
                  <Box component="span" mx={1}>
                    Download
                  </Box>
                </Button>
              )}
            </Box>
          </Box>
          <ActionItemMenusContainer
            selectedMenu={currentContextMenu}
            onChangeActivity={handleChangeActivity}
            onChangeDueDate={handleChangeDueDate}
            onClickOnCopyItemContent={handleClickOnCopyContent}
            onClickOnCopyItemLink={handleClickOnCopyLink}
            onClickOnDiscoverIntegrations={onClickOnDiscoverIntegrations}
            onClickOnUpgradePlan={handleClickOnUpgradePlan}
            onCloseContextMenu={handleCloseContextMenu}
            onPostingSuccess={onPostingSuccess}
            onResponseError={onResponseError}
            onSelectParticipant={handleChangeActionParticipant}
          >
            {Object.entries(groupedItems).map(([type, items]) => (
              <MeetingAssignmentsGroup
                items={items}
                key={type}
                type={type as ActionItemType}
                enableBetaFeatures={enableBetaFeatures}
                isAuthorizedToEdit={meeting.permissions.canManage}
                isAuthorizedToExport={meeting.permissions.canExport}
                isForceHiddenIntegrations={isForceHiddenIntegrations}
                onChangeDiscussedWith={handleChangeInDiscussionWith}
                onChangeText={handleChangeText}
                onChangeTitle={handleChangeTitle}
                onToggleMenu={handleToggleContextMenu}
              />
            ))}
          </ActionItemMenusContainer>
        </Box>
      ) : (
        <>
          {isProcessed ? (
            // Meeting is processed but there are no key items
            <MeetingPlaceholderAssignments
              failureReason={agentCall?.failureReason || null}
              isManualRecording={isManualUpload}
              isProcessedAnalytics={isProcessedAnalytics}
              isProcessedNotes={isProcessedNotes}
              isRestrictedKeyItems={isRestrictedKeyItemsPromotion}
              isTranscribed={isTranscribed}
              meetingStatus={meeting.status}
              onRedirect={onRedirect}
            />
          ) : (
            // Meeting is not processed yet
            <MeetingPlaceholderProcessing
              isProcessedAnalytics={isProcessedAnalytics}
              isProcessedAssignments={isProcessedAssignments}
              isProcessedNotes={isProcessedNotes}
              isPromoteUpgrade={isPromoteUpgrade}
              isRestrictedKeyItems={isRestrictedKeyItemsPromotion}
              isTranscribed={isTranscribed}
              meetingDuration={meeting.duration}
              meetingEnd={meeting.finishedAt}
              onChangeRoute={onRedirect}
              onClickOnUpgradePlan={onClickOnUpgradePlan}
            />
          )}
        </>
      )}
    </>
  );
};

export default MeetingAssignmentsContainer;
