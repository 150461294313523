import { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery, NetworkStatus } from '@apollo/client';
import { debounce } from 'lodash';
import { useIntegrationApp } from '@integration-app/react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import AddIcon from '@material-ui/icons/AddSharp';
import AppsIcon from '@material-ui/icons/Apps';
import BackIcon from '@material-ui/icons/ChevronLeft';
import ClearIcon from '@material-ui/icons/CloseSharp';
import PageIcon from '@material-ui/icons/Description';
import StorageIcon from '@material-ui/icons/StorageSharp';
// GraphQl Queries and Types
import createNotionDatabaseMutation from '../graphql/mutations/CreateNotionDatabase.graphql';
import integrationsQuery from '../graphql/queries/UserOutboundIntegrations.graphql';
import notionDatabasesQuery from '../graphql/queries/NotionDatabases.graphql';
import notionPagesQuery from '../graphql/queries/NotionPages.graphql';
import sendMeetingNotesMutation from '../graphql/mutations/SendMeetingNotesCustomIntegration.graphql';
import sendMeetingNotesToIntegrationFabricMutation from '../graphql/mutations/SendMeetingNotesToIntegrationFabric.graphql';
import sendTranscriptionToIntegrationFabricMutation from '../graphql/mutations/SendTranscriptionToIntegrationFabric.graphql';
import sendToNotionMutation from '../graphql/mutations/SendMeetingNotesToNotion.graphql';
import sendToSlackMutation from '../graphql/mutations/SendMeetingNotesToSlack.graphql';
import sendTranscriptMutation from '../graphql/mutations/SendTranscriptionCustomIntegration.graphql';
import slackDestinationsQuery from '../graphql/queries/SlackIntegrationDestinations.graphql';
import {
  CreateNotionDatabase,
  CreateNotionDatabaseVariables,
  ExtendedFlowInstance,
  GenericNotionDatabase,
  GenericNotionPage,
  GenericOutboundIntegration,
  GenericSlackDestination,
  GraphError,
  IntegrationTypes,
  NotionDatabases,
  NotionDatabasesVariables,
  NotionIntegrationsTypes,
  NotionPages,
  NotionPagesVariables,
  SendMeetingNotesCustomIntegration,
  SendMeetingNotesCustomIntegrationVariables,
  SendMeetingNotesToIntegrationFabric,
  SendMeetingNotesToIntegrationFabricVariables,
  SendMeetingNotesToNotion,
  SendMeetingNotesToNotionVariables,
  SendMeetingNotesToSlack,
  SendMeetingNotesToSlackVariables,
  SendTranscriptionCustomIntegration,
  SendTranscriptionCustomIntegrationVariables,
  SendTranscriptionToIntegrationFabric,
  SendTranscriptionToIntegrationFabricVariables,
  SlackIntegrationDestinations,
  UserOutboundIntegrations,
} from '../types';
// Lib Shared
import PrivateChannelIcon from '../icons/PrivateChannel';
import PublicChannelIcon from '../icons/Pound';
import integrationsImage from '../assets/icon-integrations-36.svg';
import notionIntegrationImage from '../assets/icon-notion-integration-24.svg';
import slackIntegrationImage from '../assets/icon-slack-integration-24.svg';
import { MeetingIntegrationsMenuItems, Twemoji } from '../components';

export interface MeetingIntegrationsMenuProps {
  anchorEl: HTMLElement;
  meetingId: string;
  onClickOnDiscoverIntegrations: () => void;
  onClose: () => void;
  onError: (error: GraphError) => void;
  onSuccess: (msg: string, integrationType: string) => void;
}

/**
 * A component that renders a list of integrations menu items
 */
export const MeetingIntegrationsMenu: React.VFC<MeetingIntegrationsMenuProps> = ({
  anchorEl,
  meetingId,
  onClickOnDiscoverIntegrations,
  onClose,
  onError,
  onSuccess,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [destinationFilterValue, setDestinationFilterValue] = useState<string>('');
  const [flowInstances, setFlowInstances] = useState<ExtendedFlowInstance[]>([]);
  const [isChangingDestination, setIsChangingDestination] = useState(false);
  const [isChangingNotionDatabase, setIsChangingNotionDatabase] = useState(false);
  const [isLoadingFlowInstances, setIsLoadingFlowInstances] = useState(false);
  const [notionDbFilterValue, setNotionDbFilterValue] = useState<string>('');
  const [notionPageFilterValue, setNotionPageFilterValue] = useState<string>('');
  const [selectedNotionPage, setSelectedNotionPage] = useState<GenericNotionPage | null>(null);

  const integrationApp = useIntegrationApp();

  const { data, loading: isLoading } = useQuery<UserOutboundIntegrations>(integrationsQuery);

  const [
    getSlackDestination,
    { data: slackDestinationsData, loading: isLoadingSlackDestinations },
  ] = useLazyQuery<SlackIntegrationDestinations>(slackDestinationsQuery);

  const [
    getNotionPages,
    {
      data: notionPagesData,
      fetchMore: fetchMoreNotionPages,
      refetch: refetchNotionPages,
      networkStatus: notionPagesMetworkStatus,
    },
  ] = useLazyQuery<NotionPages, NotionPagesVariables>(notionPagesQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    variables: {
      pageSize: 15,
      integrationType: NotionIntegrationsTypes.NOTION_MEETING_NOTES_INTEGRATION,
    },
  });

  const [getNotionDatabases, { data: notionDatabasesData, loading: isLoadingNotionDatabases }] =
    useLazyQuery<NotionDatabases, NotionDatabasesVariables>(notionDatabasesQuery, {
      fetchPolicy: 'cache-and-network',
    });

  const [sendToSlack, { loading: isSendingToSlack }] = useMutation<
    SendMeetingNotesToSlack,
    SendMeetingNotesToSlackVariables
  >(sendToSlackMutation);

  const [sendToNotion, { loading: isSendingToNotion }] = useMutation<
    SendMeetingNotesToNotion,
    SendMeetingNotesToNotionVariables
  >(sendToNotionMutation);

  const [createNotionDatabase, { loading: isCreatingNotionDb }] = useMutation<
    CreateNotionDatabase,
    CreateNotionDatabaseVariables
  >(createNotionDatabaseMutation);

  const [sendMeetingNotes, { loading: isSendingMeetingNotes }] = useMutation<
    SendMeetingNotesCustomIntegration,
    SendMeetingNotesCustomIntegrationVariables
  >(sendMeetingNotesMutation);

  const [sendTranscription, { loading: isSendingTranscription }] = useMutation<
    SendTranscriptionCustomIntegration,
    SendTranscriptionCustomIntegrationVariables
  >(sendTranscriptMutation);

  const [
    sendMeetingNotesToIntegrationFabric,
    { loading: isSendingMeetingNotesToIntegrationFabric },
  ] = useMutation<
    SendMeetingNotesToIntegrationFabric,
    SendMeetingNotesToIntegrationFabricVariables
  >(sendMeetingNotesToIntegrationFabricMutation);

  const [
    sendTranscriptionToIntegrationFabric,
    { loading: isSendingTranscriptionToIntegrationFabric },
  ] = useMutation<
    SendTranscriptionToIntegrationFabric,
    SendTranscriptionToIntegrationFabricVariables
  >(sendTranscriptionToIntegrationFabricMutation);
  /* #endregion */

  /* #region  Handlers */
  const handleCloseDatabaseMenu = () => {
    if (selectedNotionPage) {
      setSelectedNotionPage(null);
    } else {
      setSelectedNotionPage(null);
      setIsChangingNotionDatabase(false);
    }
  };

  const handleCloseDestinationMenu = () => {
    setIsChangingDestination(false);
  };

  const handleOpenDestinationMenu = () => {
    getSlackDestination();
    setIsChangingDestination(true);
  };

  const handleOpenDatabaseMenu = () => {
    getNotionPages();
    setIsChangingNotionDatabase(true);
  };

  const handleChangeFilterDestination = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDestinationFilterValue(event.target.value);
  };

  const handleChangeNotionPageFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotionPageFilterValue(event.target.value);
    debounce(() => {
      refetchNotionPages({
        pageSize: 15,
        search: event.target.value || null,
      });
    }, 500)();
  };

  const handleClearNotionPageFilterValue = () => {
    setNotionPageFilterValue('');
    refetchNotionPages({
      pageSize: 15,
      search: null,
    });
  };

  const handleChangeNotionDbFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotionDbFilterValue(event.target.value);
  };

  const handleClearNotionDbFilterValue = () => {
    setNotionDbFilterValue('');
  };

  const handleChangeDestination = (targetDestination: GenericSlackDestination) => () => {
    handleCloseDestinationMenu();
    handleSendNotesToSlack(targetDestination);
  };

  const handleChangeNotionPage = (targetPage: GenericNotionPage) => async () => {
    setSelectedNotionPage(targetPage);
    const result = await getNotionDatabases({
      variables: {
        pageId: targetPage.id,
        integrationType: NotionIntegrationsTypes.NOTION_MEETING_NOTES_INTEGRATION,
      },
    });

    const databaseCount = result?.data?.notionDatabases?.length;
    const defaultDatabase = result?.data?.notionDatabases?.find(
      (db) => db.name === 'Sembly Meetings',
    );

    if (!!defaultDatabase) {
      // If the default database found, select it automatically
      setIsChangingNotionDatabase(false);
      handleSendNotesToNotion(defaultDatabase);
    } else if (!databaseCount) {
      // If no databases found, create one automatically
      const creationResult = await createNotionDatabase({
        variables: {
          pageId: targetPage.id,
          integrationType: NotionIntegrationsTypes.NOTION_MEETING_NOTES_INTEGRATION,
        },
      });

      if (creationResult?.data?.createNotionDatabase?.success) {
        handleSendNotesToNotion(creationResult.data.createNotionDatabase?.database);
      } else {
        onError(creationResult.data?.createNotionDatabase?.errors);
      }

      onClose();
    }
  };

  const handleChangeNotionDatabase = (targetDatabase: GenericNotionDatabase) => () => {
    setIsChangingNotionDatabase(false);
    handleSendNotesToNotion(targetDatabase);
  };

  const handleSendNotesToSlack = async (targetDestination: GenericSlackDestination | null) => {
    if (!targetDestination) {
      handleOpenDestinationMenu();
      return;
    }

    const result = await sendToSlack({
      variables: { meetingId, destinationSlackId: targetDestination.slackId },
      refetchQueries: [{ query: integrationsQuery }],
    });

    if (result.data?.sendMeetingNotesToSlack?.success) {
      onSuccess('Meeting Notes successfully sent to Slack Integration', 'Slack');
    } else {
      onError(result.data?.sendMeetingNotesToSlack?.errors);
    }

    onClose();
  };

  const handleSendNotesToNotion = async (targetDatabase: GenericNotionDatabase | null) => {
    if (!targetDatabase) {
      handleOpenDatabaseMenu();
      return;
    }

    const result = await sendToNotion({
      variables: { meetingId, destinationDatabaseId: targetDatabase.id },
      refetchQueries: [{ query: integrationsQuery }],
    });

    if (result.data?.sendMeetingNotesToNotion?.success) {
      onSuccess('Meeting Notes successfully sent to Notion Integration', 'Slack');
    } else {
      onError(result.data?.sendMeetingNotesToNotion?.errors);
    }

    onClose();
  };

  const handleSendCustomIntegration = async (
    integrationType: IntegrationTypes,
    integrationId: string,
  ) => {
    if (integrationType === IntegrationTypes.MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION) {
      const result = await sendMeetingNotes({
        variables: { meetingId, integrationId },
        refetchQueries: [{ query: integrationsQuery }],
      });

      if (result.data?.sendMeetingNotesToCustomOutboundIntegration?.success) {
        onSuccess(
          'Meeting Notes successfully sent to custom integration',
          'Custom Meeting Notes Integration',
        );
      } else {
        onError(result.data?.sendMeetingNotesToCustomOutboundIntegration?.errors);
      }
    } else if (
      integrationType === IntegrationTypes.TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION
    ) {
      const result = await sendTranscription({
        variables: { meetingId, integrationId },
        refetchQueries: [{ query: integrationsQuery }],
      });

      if (result.data?.sendTranscriptionToCustomOutboundIntegration?.success) {
        onSuccess(
          'Transcription successfully sent to custom integration',
          'Custom Transcription Integration',
        );
      } else {
        onError(result.data?.sendTranscriptionToCustomOutboundIntegration?.errors);
      }
    } else {
      throw new Error('Unknown integration type');
    }

    onClose();
  };

  const handleCreateNotionDatabaseAndSend = async () => {
    if (!selectedNotionPage) {
      throw new Error('No Notion page selected');
    }

    const result = await createNotionDatabase({
      variables: {
        pageId: selectedNotionPage.id,
        integrationType: NotionIntegrationsTypes.NOTION_MEETING_NOTES_INTEGRATION,
      },
    });

    if (result?.data?.createNotionDatabase?.success) {
      handleSendNotesToNotion(result.data.createNotionDatabase?.database);
    } else {
      onError(result.data?.createNotionDatabase?.errors);
    }

    onClose();
  };

  const handleFetchMoreNotionPages = async () => {
    fetchMoreNotionPages({
      variables: {
        pageSize: 15,
        startCursor: notionPagesData?.notionPages?.nextCursor,
        search: notionPageFilterValue || null,
      },
    });
  };

  const handleSendFabricIntegration = async (automation: ExtendedFlowInstance) => {
    let result;

    if (automation.flow?.key === 'push-meeting-notes') {
      result = (
        await sendMeetingNotesToIntegrationFabric({
          variables: {
            meetingId,
            flowInstanceId: automation.id,
          },
        })
      ).data?.sendMeetingNotesToIntegrationFabric;
    } else if (automation.flow?.key === 'push-transcriptions') {
      result = (
        await sendTranscriptionToIntegrationFabric({
          variables: {
            meetingId,
            flowInstanceId: automation.id,
          },
        })
      ).data?.sendTranscriptionToIntegrationFabric;
    } else {
      throw new Error('Unknown automation type');
    }

    if (result?.success) {
      onSuccess('Data successfully sent to integration', 'Fabric Integration');
    } else {
      onError(result?.errors);
    }

    onClose();
  };
  /* #endregion */

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingFlowInstances(true);
      const flows = await integrationApp.flows.findAll({ includeArchived: false });
      const flowInstances = await integrationApp.flowInstances.find({ enabled: true, limit: 1000 });

      let updatedFlowInstances: ExtendedFlowInstance[] = [];

      flowInstances?.items?.forEach((flowInstance) => {
        const flow = flows?.find((f) => f.id === flowInstance.universalFlowId);
        if (!!flow && (flow.key === 'push-meeting-notes' || flow.key === 'push-transcriptions')) {
          updatedFlowInstances.push({ ...flowInstance, flow });
        }
      });

      // Update the state with the fetched data
      setFlowInstances(updatedFlowInstances);
      setIsLoadingFlowInstances(false);
    };

    fetchData();
  }, [integrationApp]);

  /* #region  Utils */
  const isTargetIntegrations = ({ type }: GenericOutboundIntegration) => {
    return (
      type === IntegrationTypes.ACTIONS_CUSTOM_OUTBOUND_USER_INTEGRATION ||
      type === IntegrationTypes.MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION ||
      type === IntegrationTypes.NOTION_MEETING_NOTES ||
      type === IntegrationTypes.SLACK_USER ||
      type === IntegrationTypes.TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION
    );
  };

  const isTargetDestination = ({ name }: GenericSlackDestination) => {
    return name.toLocaleLowerCase().includes(destinationFilterValue.toLocaleLowerCase());
  };
  /* #endregion */

  /* #region  Render Helpers */
  const userIntegrations = data?.outboundIntegrations?.filter(isTargetIntegrations);
  const slackDestinations = slackDestinationsData?.slackDestinations || [];
  const hasIntegrations = !!userIntegrations?.length || !!flowInstances?.length;
  const hasMoreNotionPages = notionPagesData?.notionPages.hasNext ?? false;
  const isLoadingNotionPages = notionPagesMetworkStatus === NetworkStatus.loading;
  const isFetchingNotionPages = notionPagesMetworkStatus === NetworkStatus.fetchMore;
  const availableSlackDestinations = slackDestinations.filter(isTargetDestination);
  const notionPages = notionPagesData?.notionPages.objects || [];
  const notionDatabases = notionDatabasesData?.notionDatabases || [];
  const filteredNotionDatabases = notionDatabases.filter(({ name }) =>
    name.toLocaleLowerCase().includes(notionDbFilterValue.toLocaleLowerCase()),
  );

  const renderSlackDestinationMenu = () => (
    <>
      <div className={styles.sticky}>
        <div className={styles.destinationHeader}>
          <IconButton
            size="small"
            className={`${styles.iconButton} destination`}
            onClick={handleCloseDestinationMenu}
          >
            <BackIcon fontSize="small" />
          </IconButton>
          <div className={styles.destinationInner}>
            <img src={slackIntegrationImage} alt="Slack Integration" />
            <Typography component="div">
              <b>Post item to channel</b>
            </Typography>
          </div>
        </div>
        <Divider />
        <TextField
          fullWidth
          size="small"
          variant="filled"
          placeholder="Type here to search"
          className={styles.destinationSearch}
          value={destinationFilterValue}
          onChange={handleChangeFilterDestination}
        />
        <Divider />
      </div>
      {isLoadingSlackDestinations ? (
        <Box display="flex" alignItems="center" justifyContent="center" py={8}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          {availableSlackDestinations.map((destination) => (
            <MenuItem key={destination.slackId} onClick={handleChangeDestination(destination)}>
              {destination.type === 'PUBLIC_CHANNEL' ? (
                <PublicChannelIcon />
              ) : (
                <PrivateChannelIcon />
              )}
              <Box mr={1} />
              <Typography component="span" className={styles.textWrap}>
                {destination.name}
              </Typography>
            </MenuItem>
          ))}
          {!availableSlackDestinations.length && (
            <Box m={2}>
              <Typography variant="body2" color="textSecondary">
                No destination found
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );

  const renderNotionDestinationMenu = () => (
    <>
      <div className={styles.sticky}>
        <div className={styles.destinationHeader}>
          <IconButton
            size="small"
            className={`${styles.iconButton} destination`}
            onClick={handleCloseDatabaseMenu}
          >
            <BackIcon fontSize="small" />
          </IconButton>
          <div className={styles.destinationInner}>
            <img src={notionIntegrationImage} alt="Notion Integration" />
            <Typography component="div">
              <b>Post Meeting Notes</b>
            </Typography>
          </div>
        </div>
        <Divider />
        {selectedNotionPage ? (
          <TextField
            fullWidth
            size="small"
            variant="filled"
            placeholder="Type here to search databases"
            className={styles.destinationSearch}
            value={notionDbFilterValue}
            InputProps={{
              endAdornment: !!notionDbFilterValue && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleClearNotionDbFilterValue}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChangeNotionDbFilterValue}
          />
        ) : (
          <TextField
            fullWidth
            size="small"
            variant="filled"
            placeholder="Type here to search pages"
            className={styles.destinationSearch}
            value={notionPageFilterValue}
            InputProps={{
              endAdornment: !!notionPageFilterValue && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleClearNotionPageFilterValue}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChangeNotionPageFilterValue}
          />
        )}
        <Divider />
      </div>
      {isLoadingNotionPages || isLoadingNotionDatabases ? (
        <Box display="flex" alignItems="center" justifyContent="center" py={8}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          {!selectedNotionPage ? (
            <Box my={1}>
              {notionPages.map((page) => (
                <MenuItem key={page.id} onClick={handleChangeNotionPage(page)}>
                  <Box display="flex" minWidth={32}>
                    {!!page.imageUrl || !!page.emoji ? (
                      <>
                        {!!page.imageUrl ? (
                          <img
                            loading="lazy"
                            src={page.imageUrl}
                            alt={page.name}
                            className={styles.icon}
                          />
                        ) : (
                          <Twemoji options={{ className: styles.icon }}>{page.emoji}</Twemoji>
                        )}
                      </>
                    ) : (
                      <PageIcon className={styles.icon} color="action" />
                    )}
                  </Box>
                  <Typography component="span" className={styles.textWrap}>
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
              {hasMoreNotionPages && (
                <MenuItem onClick={handleFetchMoreNotionPages}>
                  <Box display="flex" minWidth={32}>
                    {isFetchingNotionPages && (
                      <CircularProgress size={16} style={{ marginRight: 8 }} />
                    )}
                  </Box>
                  <Typography component="span" color="primary" className={styles.textWrap}>
                    Load more pages
                  </Typography>
                </MenuItem>
              )}
              {!notionPages.length && (
                <Box m={2}>
                  <Typography variant="body2" color="textSecondary">
                    No pages found
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box my={1}>
              <MenuItem onClick={handleCreateNotionDatabaseAndSend}>
                <Box display="flex" minWidth={32}>
                  <AddIcon className={styles.icon} color="action" />
                </Box>
                <Typography component="span" className={styles.textWrap}>
                  Sembly will create a database for you
                </Typography>
              </MenuItem>
              {filteredNotionDatabases.map((db) => (
                <MenuItem key={db.id} onClick={handleChangeNotionDatabase(db)}>
                  <Box display="flex" minWidth={32}>
                    <StorageIcon className={styles.icon} color="action" />
                  </Box>
                  <Typography component="span" className={styles.textWrap}>
                    {db.name}
                  </Typography>
                </MenuItem>
              ))}
              {!filteredNotionDatabases.length && (
                <Box m={2}>
                  <Typography variant="body2" color="textSecondary">
                    No databases found
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
  /* #endregion */

  return (
    <Menu
      open
      variant="menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={{ paper: styles.paper, list: styles.menu }}
      onClose={onClose}
    >
      {isLoading ||
      isLoadingFlowInstances ||
      isSendingToSlack ||
      isSendingMeetingNotes ||
      isSendingTranscription ||
      isSendingMeetingNotesToIntegrationFabric ||
      isSendingTranscriptionToIntegrationFabric ||
      isSendingToNotion ||
      isCreatingNotionDb ? (
        <Box display="flex" alignItems="center" justifyContent="center" my={8}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <div>
          {hasIntegrations ? (
            <div>
              {isChangingDestination || isChangingNotionDatabase ? (
                <>
                  {isChangingDestination && renderSlackDestinationMenu()}
                  {isChangingNotionDatabase && renderNotionDestinationMenu()}
                </>
              ) : (
                <>
                  {hasIntegrations && (
                    <MeetingIntegrationsMenuItems
                      outboundIntegrations={userIntegrations || []}
                      fabricAutomations={flowInstances}
                      onClickOnCustomItem={handleSendCustomIntegration}
                      onClickOnEditNotion={handleOpenDatabaseMenu}
                      onClickOnEditSlack={handleOpenDestinationMenu}
                      onClickOnFabricItem={handleSendFabricIntegration}
                      onClickOnNotionItem={handleSendNotesToNotion}
                      onClickOnSlackItem={handleSendNotesToSlack}
                    />
                  )}
                  <Box m={2}>
                    <Button
                      fullWidth
                      variant="outlined"
                      startIcon={<AppsIcon />}
                      onClick={onClickOnDiscoverIntegrations}
                    >
                      <Typography variant="body2">Discover new integrations</Typography>
                    </Button>
                  </Box>
                </>
              )}
            </div>
          ) : (
            <Box p={3} textAlign="center">
              <Box mb={2}>
                <img src={integrationsImage} alt="Connected Apps" />
              </Box>
              <Box my={2}>
                <Typography gutterBottom variant="h6">
                  No Connected Apps
                </Typography>
                <Typography variant="body1">
                  Connect Sembly with your favorite apps to create your first automation.
                </Typography>
              </Box>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                onClick={onClickOnDiscoverIntegrations}
              >
                <Typography variant="body1">Browse all apps</Typography>
              </Button>
            </Box>
          )}
        </div>
      )}
    </Menu>
  );
};

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: 0,
  },
  paper: {
    minWidth: '40ch',
    maxWidth: '60ch',
    maxHeight: '60vh',
    width: `calc(100vw - ${theme.spacing(4)}px)`,
    borderRadius: theme.shape.borderRadius,
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  destinationHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  destinationInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(0.75),
  },
  destinationSearch: {
    padding: theme.spacing(1.5, 2),
  },
  icon: {
    width: 18,
    height: 18,
    display: 'flex',
  },
  iconButton: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.background.paper,
    '&.destination': {
      position: 'absolute',
      top: '50%',
      left: theme.spacing(1),
      transform: 'translateY(-50%)',
    },
  },
  textWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default MeetingIntegrationsMenu;
