import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/CloseSharp';
import FiberNewIcon from '@material-ui/icons/FiberNew';
// Module Shared
import { GPTChatContainer } from '../containers';
import chatImage from '../assets/chat.gif';

export interface GPTChatProps {
  announcementClassName?: string;
  className?: string;
  isHiddenUpgradeButtons?: boolean;
  isPeronalPlan: boolean;
  meetingId: string;
  username: string;
  onClickOnUpgradePlan: () => void;
}

export const GPTChat: React.VFC<GPTChatProps> = ({
  announcementClassName = '',
  className = '',
  isHiddenUpgradeButtons = false,
  isPeronalPlan,
  meetingId,
  username,
  onClickOnUpgradePlan,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const location = useLocation<{ askSembly?: boolean } | null>();

  const anchorRef = useRef(null);

  const [isOpenChat, setIsOpenChat] = useState(false);
  const [isAnnouncing, setIsAnnouncing] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleToggleChat = () => {
    setIsOpenChat((currentValue) => {
      const nextValue = !currentValue;
      if (nextValue) handleCloseAnnouncement();
      return nextValue;
    });
  };

  const handleCloseChat = () => {
    setIsOpenChat(false);
  };

  const handleOpenChat = () => {
    setIsOpenChat(true);
    handleCloseAnnouncement();
  };

  const handleCloseAnnouncement = (event?: React.MouseEvent) => {
    if (event) event.stopPropagation();
    localStorage.setItem('is-promoted-ai-chat', 'True');
    setIsAnnouncing(false);
  };
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    const timeout = setTimeout(() => {
      const isAlreadyPromoted = !!localStorage.getItem('is-promoted-ai-chat');
      if (!isAlreadyPromoted) setIsAnnouncing(true);
    }, 1000 * 3);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  useLayoutEffect(() => {
    setIsOpenChat(!!location.state?.askSembly);
  }, [location.state?.askSembly]);
  /* #endregion */

  return (
    <>
      <div className={`${styles.buttonContainer} ${className}`} ref={anchorRef}>
        <Button
          disableElevation
          className={styles.semblianButton}
          aria-label="AI Meeting Chat"
          onClick={handleToggleChat}
        >
          <img
            src={chatImage}
            className={styles.semblianIcon}
            width={64}
            height={64}
            alt="AI Meeting Chat"
          />
        </Button>
      </div>

      {isOpenChat && (
        <GPTChatContainer
          anchorEl={anchorRef.current}
          isHiddenUpgradeButtons={isHiddenUpgradeButtons}
          isOpen={isOpenChat}
          isPeronalPlan={isPeronalPlan}
          meetingId={meetingId}
          username={username}
          onClose={handleCloseChat}
          onClickOnUpgradePlan={onClickOnUpgradePlan}
        />
      )}

      <Grow in={!!isAnnouncing}>
        <div className={`${styles.announcement} ${announcementClassName}`} onClick={handleOpenChat}>
          <Box display="inline-flex" alignItems="center" color="highlight.main">
            <FiberNewIcon color="inherit" />
          </Box>
          <Box ml={1}>Click here to chat with your Semblian!</Box>
          <IconButton className={styles.dismiss} size="small" onClick={handleCloseAnnouncement}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Grow>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  announcement: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.surface.contrastText,
    background: theme.palette.surface.main,
    borderRadius: theme.shape.borderRadius * 2,
    position: 'absolute',
    padding: theme.spacing(2),
    right: theme.spacing(2),
    width: 'calc(100vw - 40px)',
    maxWidth: 270,
    bottom: 96,
    zIndex: 10,
    '&:hover': {
      background: theme.palette.surface.dark,
    },
    '&:before': {
      content: '""',
      borderStyle: 'solid',
      borderWidth: '6px 5px 0 5px',
      borderColor: `${theme.palette.surface.main} transparent  transparent  transparent`,
      position: 'absolute',
      right: 28,
      bottom: -6,
    },
  },
  dismiss: {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[10],
    padding: theme.spacing(0.5),
    position: 'absolute',
    top: -10,
    right: -10,
    fontSize: 14,
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  semblianIcon: {
    borderRadius: '50%',
    [theme.breakpoints.down('sm')]: {
      width: 52,
      height: 52,
    },
  },
  buttonContainer: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      bottom: 135,
    },
  },
  semblianButton: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      minWidth: 52,
    },
  },
}));

export default GPTChat;
