import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
// Material UI
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Icons
import MenuIcon from '@material-ui/icons/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// Sembly UI
import { RenameModalDialog } from '@sembly-ui';
// App Shared
import { AppBar } from '@shared/components';
import { graphErrorHorsemen } from '@shared/utils';
import { useUserInterface, useChatPage } from '@shared/hooks';
import { APP_DRAWER_WIDTH } from '@shared/configuration';
import { Routes } from '@shared/enums';
// GraphQL Queries and Types
import deleteMutation from '@shared/queries/DeleteContextChat.graphql';
import editMutation from '@shared/queries/EditContextChat.graphql';
import {
  DeleteContextChat,
  DeleteContextChatVariables,
  EditContextChat,
  EditContextChatVariables,
} from '@gql-types';

export const ChatPageLayout: React.FC = ({ children }) => {
  /* #region  Hooks */
  const history = useHistory();
  const { update } = useUserInterface();
  const { chatId, chatTitle } = useChatPage();

  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isEditingChat, setIsEditingChat] = useState<boolean>(false);

  const [deleteChat] = useMutation<DeleteContextChat, DeleteContextChatVariables>(deleteMutation);
  const [editChat] = useMutation<EditContextChat, EditContextChatVariables>(editMutation);
  /* #endregion */

  /* #region  Handlers */
  const handleDrawerOpen = () => {
    update({ isAppDrawerOpen: true });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOnRenameChat = () => {
    setAnchorEl(null);
    setIsEditingChat(true);
  };

  const handleChangeChatName = async (newName: string) => {
    const response = await editChat({
      variables: { chatId: +chatId!, name: newName },
    });

    if (response.data?.editChat?.success) {
      setIsEditingChat(false);
    } else {
      graphErrorHorsemen(response.data?.editChat?.errors);
    }
  };

  const handleDeleteChat = async () => {
    const response = await deleteChat({
      variables: { chatId: +chatId! },
      update: (cache) => {
        const id = cache.identify({ __typename: 'ChatType', id: chatId });
        cache.evict({ id });
        cache.gc();
      },
    });

    if (response.data?.deleteChat?.success) {
      toast.success('Chat deleted successfully');
      history.replace(Routes.MyChats);
    } else {
      graphErrorHorsemen(response.data?.deleteChat?.errors);
    }
  };
  /* #endregion */

  return (
    <div className={styles.root}>
      {/* Begin: AppDrawer placeholder */}
      {/* AppDrawer currenty always open on desktops */}
      {!isSmallScreen && <Box flex="0 1 auto" width={APP_DRAWER_WIDTH} />}
      {/* End: AppDrawer placeholder */}
      <div className={styles.content}>
        <AppBar className={styles.appBar} layout="extra-wide">
          {isSmallScreen && (
            <IconButton onClick={handleDrawerOpen} size="small" className={styles.menuIcon}>
              <MenuIcon />
            </IconButton>
          )}
          <div className={styles.grow}>
            {!!chatTitle && (
              <Typography
                noWrap
                component="div"
                variant={isSmallScreen ? 'body2' : 'body1'}
                className={styles.title}
              >
                <b>{chatTitle}</b>
              </Typography>
            )}
          </div>
          <div className={styles.nogrow}>
            {!!chatId && (
              <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
            )}
          </div>
        </AppBar>

        <div className={styles.appBarSpacer} />

        {children}
      </div>
      {!!anchorEl && (
        <Menu
          anchorEl={anchorEl}
          PaperProps={{ square: true, className: styles.menu }}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleClickOnRenameChat}>Rename chat</MenuItem>
          <MenuItem onClick={handleDeleteChat}>Delete chat</MenuItem>
        </Menu>
      )}
      {!!isEditingChat && (
        <RenameModalDialog
          title="Rename Chat"
          defaultValue={chatTitle || ''}
          onClose={() => setIsEditingChat(false)}
          onSubmit={handleChangeChatName}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  menuIcon: {
    marginLeft: theme.spacing(-2.5),
    marginRight: theme.spacing(1),
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    borderBottom: 'none',
    overflow: 'hidden',
  },
  appBarSpacer: {
    ...theme.mixins.toolbar,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
  },
  grow: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
  },
  nogrow: {
    flexGrow: 0,
    paddingLeft: theme.spacing(2),
  },
  menu: {
    borderRadius: theme.shape.borderRadius * 2,
  },
  title: {
    marginLeft: theme.spacing(2),
  },
}));

export default ChatPageLayout;
