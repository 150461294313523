import clsx from 'clsx';
import { useState, useEffect, memo } from 'react';
import { useRouteMatch, useHistory, Link, generatePath } from 'react-router-dom';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ChatIcon from '@material-ui/icons/Chat';
import FeedbackIcon from '@material-ui/icons/SmsFailedOutlined';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import WarningIcon from '@material-ui/icons/Warning';
// Sembly UI
import { userPlan } from '@sembly-ui';
// App Shared
import { APP_DRAWER_WIDTH } from '@shared/configuration';
import { AppDrawerPopupMenu, ListItemNavLink, PlanStatsWidget } from '@shared/components';
import { InviteUserDialog, FeedbackDialogForm, PlanStatsDialog } from '@shared/dialogs';
import { PAYMENT_PLANS, APP_DRAWER_NAV_ITEMS } from '@shared/constants';
import { ReactComponent as Logo } from '@shared/assets/logo.svg';
import { Routes } from '@shared/enums';
import { useUserContext, useUserInterface } from '@shared/hooks';
// App Modules
import { AnalyticsModuleContainer } from '@modules/Analytics';
import { BookmarksModuleContainer } from '@modules/Bookmarks';
import { MeetingModuleContainer } from '@modules/Meeting';
import { MyAssignmentsModuleContainer } from '@modules/MyAssignments';
import { MyAutomationsModuleContainer } from '@modules/MyAutomations';
import { MyWorkstreamsModuleContainer } from '@modules/MyWorkstreams';
import { SharedMeetingsModuleContainer } from '@modules/SharedWithMe';
import { WorkspaceAutomationsModuleContainer } from '@modules/WorkspaceAutomations';
// GraphQL Queries and Types
import { PlanIDEnum, UserRole } from '@gql-types/globalTypes';

const AppDrawerComponent: React.VFC = () => {
  /* #region  Hooks */
  const theme = useTheme();
  const styles = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useUserContext();
  const { update, isAppDrawerOpen } = useUserInterface();

  const history = useHistory();

  const isPromoteInvite = !useRouteMatch(Routes.SettingsWorkspaceMembers);
  const isSettingsPage = !!useRouteMatch(Routes.Settings);
  const isMeetingPage = !!useRouteMatch(Routes.Meeting);
  const isAIAssociatePage = !!useRouteMatch(Routes.MyChats);
  const isHomePage = !!useRouteMatch(Routes.Home);
  const isSharedWithMePage = !!useRouteMatch(Routes.SharedWithMe);
  const isBookmarksPage = !!useRouteMatch(Routes.Bookmarks);
  const isMyAssignmentsPage = !!useRouteMatch(Routes.MyAssignments);
  const isAnalyticsPage = !!useRouteMatch(Routes.Analytics);
  const myAutomationsPage = !!useRouteMatch(Routes.MyAutomations);
  const myWorkstreamsPage = !!useRouteMatch(Routes.MyWorkstreams);
  const workspaceAutomationsPage = !!useRouteMatch(Routes.WorkspaceAutomations);

  const [isOpenPopupMenu, setIsOpenPopupMenu] = useState(false);
  const [isOpenPlanStatsDialog, setIsOpenPlanStatsDialog] = useState(false);
  const [isOpenInvitationDialog, setIsOpenInvitationDialog] = useState(false);
  const [isFeedbackFormOpen, setIsFeedbackFormOpen] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState<string[]>([]);
  /* #endregion */

  /* #region  Handlers */
  const handleTogglePopupMenu = (value: boolean) => () => {
    setIsOpenPopupMenu(value);
  };

  const handleToggleFeedbackForm = (value: boolean) => () => {
    setIsFeedbackFormOpen(value);
  };

  const handleToggleInvintationDialog = (value: boolean) => () => {
    setIsOpenInvitationDialog(value);
  };

  const handleTogglePlanStatsDialog = (value: boolean) => () => {
    setIsOpenPlanStatsDialog(value);
    update({ isAppDrawerOpen: value });
  };

  const handleCloseDrawer = () => {
    update({ isAppDrawerOpen: false });
  };

  const handleNavLinkMouseOver = (route: string) => () => {
    switch (route) {
      case Routes.Meeting:
        MeetingModuleContainer.preload();
        break;
      case Routes.Bookmarks:
        BookmarksModuleContainer.preload();
        break;
      case Routes.MyAssignments:
        MyAssignmentsModuleContainer.preload();
        break;
      case Routes.MyWorkstreams:
        MyWorkstreamsModuleContainer.preload();
        break;
      case Routes.SharedWithMe:
        SharedMeetingsModuleContainer.preload();
        break;
      case Routes.Analytics:
        AnalyticsModuleContainer.preload();
        break;
      case Routes.MyAutomations:
        MyAutomationsModuleContainer.preload();
        break;
      case Routes.WorkspaceAutomations:
        WorkspaceAutomationsModuleContainer.preload();
        break;
    }
  };

  const handleClickOnEmailConfirmation = () => {
    handleCloseDrawer();
    history.push(Routes.SettingsProfile);
  };

  const handleToggleSection = (section: string) => () => {
    const newCollapsedSections = collapsedSections.includes(section)
      ? collapsedSections.filter((item) => item !== section)
      : [...collapsedSections, section];

    setCollapsedSections(newCollapsedSections);
    localStorage.setItem('appDrawerSections', JSON.stringify(newCollapsedSections));
  };
  /* #endregion */

  useEffect(() => {
    localStorage.getItem('appDrawerSections') &&
      setCollapsedSections(JSON.parse(localStorage.getItem('appDrawerSections') || '[]'));
  }, []);

  /* #region  Render Helpers */
  const me = user.data?.me;
  const billingAccessType = me?.billingAccess?.id;
  const paymentCustomer = me?.paymentCustomer;
  const workspace = me?.workspace;
  const automationsUserSettings = workspace?.automationsUserSettings;
  const hasAiAssociateSubscription = !!paymentCustomer?.aiAssociateSubscription;
  const features = paymentCustomer?.plan?.features;
  const recentChats = me?.recentChats;
  const paymentPlan = PAYMENT_PLANS[billingAccessType || PlanIDEnum.PERSONAL];
  const username = me?.fullName || me?.email || 'User';
  const userpic = me?.avatar || undefined;
  const numUnreadCommitments = me?.numUnreadCommitments;
  const numUnreadActiveWorkstreams = me?.numUnreadActiveWorkstreams ?? 0;
  const isRegularUser = me?.role === UserRole.REGULAR_USER;
  const isWorkspaceAdmin = me?.role === UserRole.CUSTOMER_ADMIN;
  const isTeamPaymentPlan = me?.billingAccess?.forTeams || false;
  const isForBetaTesting = workspace?.isForBetaTesting;
  const isEnabledUserAutomations = automationsUserSettings?.userAutomationsEnabled ?? false;
  const isUserLoaded = !!user.data?.me;
  const isConfirmedEmail = me?.isValidEmail ?? false;
  const isPaidPlan = userPlan('equalsOrHigher', PlanIDEnum.PROFESSIONAL, paymentCustomer?.plan?.id);
  const isAIAssociateEnabled = me?.aiAssociateEnabled && hasAiAssociateSubscription;
  const creditsLeft = Math.round(paymentCustomer?.credits ?? 0);
  const onlineSecondsLeft = paymentCustomer?.meetingsDurationSecondsLeft ?? 0;
  const onlineSecondsAmount = features?.staticLimits?.meetingsDurationSecondsLimit ?? 0;
  const onlineSecondsUsed = onlineSecondsAmount - onlineSecondsLeft;
  const onlineUsagePercentage = 100 - ((onlineSecondsUsed * 100) / onlineSecondsAmount || 0);
  const uploadSecondsAmount = features?.dynamicLimits?.uploadRecordingsSecondsLimit ?? 0;
  const uploadSecondsUsed = paymentCustomer?.monthlyFeaturesUsage?.uploadRecordingsSecondsUsed ?? 0;
  const uploadSecondsLeft = uploadSecondsAmount - uploadSecondsUsed;
  const uploadUsagePercentage = 100 - ((uploadSecondsUsed * 100) / uploadSecondsAmount || 0);
  const isCollapsedAIAssociate = collapsedSections.includes('ai-associate');
  const isCollapsedMeetings = collapsedSections.includes('meetings');
  const isCollapsedWorkspace = collapsedSections.includes('workspace');
  const isCollapsedRecentChats = collapsedSections.includes('recent-chats');
  /* #endregion */

  // Hide drawer if not on any of the following pages
  if (
    !isAIAssociatePage &&
    !isAnalyticsPage &&
    !isBookmarksPage &&
    !isHomePage &&
    !isMeetingPage &&
    !isMyAssignmentsPage &&
    !isSettingsPage &&
    !isSharedWithMePage &&
    !myAutomationsPage &&
    !myWorkstreamsPage &&
    !workspaceAutomationsPage
  ) {
    return null;
  }

  return (
    <>
      <Drawer
        className={styles.root}
        classes={{ paper: styles.paper }}
        variant={isSmallScreen ? 'temporary' : 'permanent'}
        open={isSmallScreen ? isAppDrawerOpen : true} // currenty always open on desktops
        transitionDuration={{ exit: 0 }}
        onClose={handleCloseDrawer}
      >
        <div className={styles.body}>
          {!user.loading && (
            <>
              <Link to={Routes.Home} style={{ display: 'block' }}>
                {paymentPlan.logo ? (
                  <img src={paymentPlan.logo} className={styles.logo} alt="Sembly" />
                ) : (
                  <Logo className={styles.logo} />
                )}
              </Link>

              {isAIAssociateEnabled && (
                <>
                  <List
                    subheader={
                      <ListSubheader
                        color="inherit"
                        component="div"
                        className={styles.subheader}
                        onClick={handleToggleSection('ai-associate')}
                      >
                        <ArrowRightIcon
                          color="inherit"
                          fontSize="small"
                          className={clsx(styles.icon, { collapsed: isCollapsedAIAssociate })}
                        />
                        <span>Semblian 2.0</span>
                      </ListSubheader>
                    }
                  >
                    <Collapse in={!isCollapsedAIAssociate}>
                      {APP_DRAWER_NAV_ITEMS.aiAssociate.map((item) => {
                        const isWorkstreams = item.route === Routes.MyWorkstreams;
                        const customBadge = isWorkstreams ? numUnreadActiveWorkstreams : 0;

                        return item.isBeta && !isForBetaTesting ? null : (
                          <ListItemNavLink
                            exact
                            key={item.label}
                            to={item.route}
                            icon={item.icon}
                            primary={item.label}
                            countBadge={customBadge}
                            onMouseOver={handleNavLinkMouseOver(item.route)}
                            onClick={handleCloseDrawer}
                          />
                        );
                      })}
                    </Collapse>
                  </List>
                  {!!recentChats?.length && (
                    <List
                      subheader={
                        <ListSubheader
                          color="inherit"
                          component="div"
                          className={styles.subheader}
                          onClick={handleToggleSection('recent-chats')}
                        >
                          <ArrowRightIcon
                            fontSize="small"
                            color="inherit"
                            className={clsx(styles.icon, { collapsed: isCollapsedRecentChats })}
                          />
                          <span>Recent Chats</span>
                        </ListSubheader>
                      }
                    >
                      <Collapse in={!isCollapsedRecentChats}>
                        {recentChats.map((chat) => (
                          <ListItemNavLink
                            key={chat.id}
                            title={chat.name}
                            primary={chat.name}
                            to={generatePath(Routes.MyChatsItem, { chatId: chat.id })}
                            icon={<ChatIcon />}
                            onClick={handleCloseDrawer}
                          />
                        ))}
                      </Collapse>
                    </List>
                  )}
                </>
              )}
              <List
                subheader={
                  <ListSubheader
                    color="inherit"
                    component="div"
                    className={styles.subheader}
                    onClick={handleToggleSection('meetings')}
                  >
                    <ArrowRightIcon
                      fontSize="small"
                      color="inherit"
                      className={clsx(styles.icon, { collapsed: isCollapsedMeetings })}
                    />
                    <span>Meetings</span>
                  </ListSubheader>
                }
              >
                <Collapse in={!isCollapsedMeetings}>
                  {APP_DRAWER_NAV_ITEMS.meetings.map((item) => {
                    const isAssignments = item.route === Routes.MyAssignments;
                    const isUserIntegrations = item.route === Routes.MyAutomations;
                    const customBadge = isAssignments ? numUnreadCommitments : 0;
                    const customLabel = isAssignments ? 'My Tasks' : null;

                    if (isUserIntegrations && !isEnabledUserAutomations) {
                      return null;
                    }

                    return item.isBeta && !isForBetaTesting ? null : (
                      <ListItemNavLink
                        icon={item.icon}
                        key={item.label}
                        primary={customLabel || item.label}
                        to={item.route}
                        countBadge={customBadge ?? 0}
                        onMouseOver={handleNavLinkMouseOver(item.route)}
                        onClick={handleCloseDrawer}
                      />
                    );
                  })}
                </Collapse>
              </List>
              {isWorkspaceAdmin && (
                <List
                  subheader={
                    <ListSubheader
                      color="inherit"
                      component="div"
                      className={styles.subheader}
                      onClick={handleToggleSection('workspace')}
                    >
                      <ArrowRightIcon
                        fontSize="small"
                        color="inherit"
                        className={clsx(styles.icon, { collapsed: isCollapsedWorkspace })}
                      />
                      <span>Workspace</span>
                    </ListSubheader>
                  }
                >
                  <Collapse in={!isCollapsedWorkspace}>
                    {APP_DRAWER_NAV_ITEMS.workspace.map((item) => {
                      return item.isBeta && !isForBetaTesting ? null : (
                        <ListItemNavLink
                          icon={item.icon}
                          key={item.label}
                          primary={item.label}
                          to={item.route}
                          onMouseOver={handleNavLinkMouseOver(item.route)}
                          onClick={handleCloseDrawer}
                        />
                      );
                    })}
                  </Collapse>
                </List>
              )}
            </>
          )}
        </div>

        {isUserLoaded && (
          <div className={styles.foot}>
            <Box display="flex" flexDirection="column" mx={1}>
              <PlanStatsWidget
                creditsLeft={creditsLeft}
                isPaidPlan={isPaidPlan}
                onlineSecondsLeft={onlineSecondsLeft}
                onlineUsagePercentage={onlineUsagePercentage}
                uploadSecondsLeft={uploadSecondsLeft}
                uploadUsagePercentage={uploadUsagePercentage}
                onClick={handleTogglePlanStatsDialog(true)}
              />

              {!isConfirmedEmail && (
                <Button
                  fullWidth
                  disableElevation
                  className={`${styles.button} notify square`}
                  startIcon={<WarningIcon className={styles.warningColor} color="inherit" />}
                  onClick={handleClickOnEmailConfirmation}
                >
                  <Box my={1}>
                    <Typography component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
                      Please <u>verify</u> your email
                    </Typography>
                  </Box>
                </Button>
              )}

              {isPromoteInvite && isTeamPaymentPlan && !isRegularUser ? (
                <Button
                  fullWidth
                  disableElevation
                  variant="contained"
                  color="inherit"
                  component={Link}
                  to={Routes.SettingsWorkspaceMembers}
                  className={`${styles.button} secondary`}
                  startIcon={<GroupIcon color="inherit" />}
                  onClick={handleCloseDrawer}
                >
                  <Typography component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
                    Invite Teammates
                  </Typography>
                </Button>
              ) : null}

              <Button
                fullWidth
                disableElevation
                className={`${styles.button} feedback`}
                startIcon={<FeedbackIcon />}
                aria-label="Submit feedback"
                onClick={handleToggleFeedbackForm(true)}
              >
                <Typography component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
                  Submit feedback
                </Typography>
              </Button>
            </Box>

            <div className={styles.highlight}>
              <List disablePadding>
                <ListItem button classes={{ root: styles.listItemRoot }}>
                  <div className={styles.accountActions} onClick={handleTogglePopupMenu(true)}>
                    <ListItemAvatar>
                      <Avatar alt={username} src={userpic} />
                    </ListItemAvatar>
                    <ListItemText disableTypography className={styles.settingsItem}>
                      <div className={styles.username}>{username}</div>
                      <SettingsIcon fontSize="small" className={styles.settingsIcon} />
                    </ListItemText>
                  </div>
                </ListItem>
              </List>
            </div>
          </div>
        )}

        <AppDrawerPopupMenu isOpen={isOpenPopupMenu} onClose={handleTogglePopupMenu(false)} />
      </Drawer>
      {/* Begin: Dialogs */}
      <InviteUserDialog
        open={isOpenInvitationDialog}
        onClose={handleToggleInvintationDialog(false)}
      />
      <FeedbackDialogForm open={isFeedbackFormOpen} onClose={handleToggleFeedbackForm(false)} />
      <PlanStatsDialog open={isOpenPlanStatsDialog} onClose={handleTogglePlanStatsDialog(false)} />
      {/* End: Dialogs */}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    width: APP_DRAWER_WIDTH,
    height: '100%',
    zIndex: 2,
  },
  paper: {
    position: 'inherit',
    display: 'flex',
    top: 'auto',
    width: 'inherit',
    backgroundColor: theme.palette.surface.dark,
  },
  logo: {
    height: 24,
    width: 'auto',
    maxWidth: '90%',
    margin: theme.spacing(5, 1),
    color: theme.palette.surface.contrastText,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 1),
    },
  },
  icon: {
    transform: 'rotate(90deg)',
    transition: 'transform 0.3s',
    '&.collapsed': {
      transform: 'rotate(0deg)',
    },
  },
  button: {
    padding: theme.spacing(1, 2),
    fontSize: '0.875rem',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(1),
    '&.primary': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.secondary': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.highlight.main,
      '&:hover': {
        backgroundColor: theme.palette.highlight.light,
      },
    },
    '&.feedback': {
      color: theme.palette.grey['A100'],
      '&:hover': {
        color: theme.palette.grey[100],
      },
    },
    '&.notify': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.surface.light,
      '&:hover': {
        color: theme.palette.grey[200],
        backgroundColor: theme.palette.surface.light,
      },
      '&.square': {
        borderRadius: theme.shape.borderRadius * 2,
      },
    },
    '&.alpha': {
      color: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius * 2,
      '&:hover': {
        color: theme.palette.grey[50],
      },
      '&.active': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.surface.light,
        cursor: 'default',
        '&:hover': {
          backgroundColor: theme.palette.surface.light,
        },
      },
    },
  },
  startIcon: {
    marginRight: theme.spacing(2),
  },
  body: {
    flex: 1,
    overflow: 'auto',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1),
  },
  foot: {
    display: 'flex',
    flexDirection: 'column',
  },
  subheader: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey['50'],
    lineHeight: '34px',
    cursor: 'pointer',
    backgroundColor: theme.palette.surface.dark,
  },
  highlight: {
    backgroundColor: theme.palette.surface.light,
  },
  listItemRoot: {
    position: 'relative',
    paddingLeft: 10,
  },
  accountActions: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    maxWidth: '100%',
  },
  settingsItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ...theme.typography.body1,
  },
  settingsIcon: {
    opacity: 0.5,
  },
  username: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: theme.spacing(1),
  },
  upgradeTitle: {
    position: 'relative',
    minHeight: theme.spacing(4),
    padding: theme.spacing(0, 0, 2, 3.5),
    '& svg': {
      position: 'absolute',
      left: 0,
      top: 0,
    },
  },
  warningColor: {
    color: theme.palette.highlight.main,
  },
}));

/**
 * @name AppDrawer
 * @description The main navigation drawer for the application.
 * @returns {JSX.Element}
 */
export const AppDrawer = memo(AppDrawerComponent);

export default AppDrawer;
