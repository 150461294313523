import uniqBy from 'lodash/uniqBy';
import { ApolloClient, InMemoryCache, from, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
import { parseISO, isBefore } from 'date-fns';
import { setContext } from '@apollo/client/link/context';
// GraphQL Queries and Types
import refreshTokensMutation from '@shared/queries/RefreshTokens.graphql';
import { ExecutionErrorCodes, RefreshTokens } from '@gql-types';
// App Shared
import { CONFIG } from '@shared/configuration';
import { AuthTokens } from '@shared/types';
import { Routes } from '@shared/enums';
import { isGuestAccessRoute } from '@shared/utils';
// Clients
import { history } from './History';
import { getAuthToken, login, logout } from './authClient';

let pendingAccessTokenPromise: Promise<AuthTokens | null> | null = null;

async function getRefreshedAccessToken(): Promise<AuthTokens | null> {
  const { data } = await apolloClient.mutate<RefreshTokens>({
    mutation: refreshTokensMutation,
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });
  if (data?.refreshTokens?.success) {
    const tokens = {
      accessToken: data.refreshTokens.userToken,
      workspaceToken: data.refreshTokens.workspaceToken,
    };
    login(tokens);
    return tokens;
  } else {
    return null;
  }
}

async function refreshAuthToken() {
  if (!pendingAccessTokenPromise)
    pendingAccessTokenPromise = getRefreshedAccessToken().finally(() => {
      pendingAccessTokenPromise = null;
    });

  return pendingAccessTokenPromise;
}

// get the authentication token from local storage if it exists
async function getToken() {
  let token: AuthTokens | null = (await getAuthToken()) as unknown as AuthTokens;

  if (token?.accessToken) {
    try {
      const authTokenJwtHeader = JSON.parse(atob(token.accessToken.split('.')[1]));
      const authTokenExpirationValue = new Date((authTokenJwtHeader.exp - 60) * 1000);
      const authTokenExpirationIsoString = authTokenExpirationValue.toISOString();
      const authTokenExpirationDate = parseISO(authTokenExpirationIsoString);
      const isExpiredToken = isBefore(authTokenExpirationDate, new Date());

      if (isExpiredToken) token = await refreshAuthToken();
    } catch (e) {
      token = null;
    }
  }

  return token;
}

const OPERATIONS_TO_RETRY = ['SubmitMeetingRecordingChunks'];

const retryLink = new RetryLink({
  delay: {
    initial: 30000, // 30 seconds
    max: Infinity, // retry forever
    jitter: false, // disable randomization of delays
  },
  attempts: (count, operation, error) => {
    // don't retry if there was no error
    if (!error) return false;
    // don't retry if the operation name is not in our retry list
    if (!OPERATIONS_TO_RETRY.includes(operation.operationName)) return false;
    // retry while counter is less than 4
    if (count < 3) return true;
    // otherwise, redirect to error page
    history.replace(Routes.ErrorGeneric, {
      error: {
        title: 'Check your connection',
        message: `You don't seem to have an active internet connection.
                  Please check your connection and try again.`,
      },
    });
    return false;
  },
});

const uploadLink = createUploadLink({ uri: CONFIG.APIEndpoint });

const wsLink = new GraphQLWsLink(
  createClient({
    url: CONFIG.WSSEndpoint,
    connectionParams: async () => {
      const token = await getToken();
      return { token: token?.accessToken };
    },
  }),
);

const authLink = setContext(async ({ operationName }, { headers }) => {
  const commonHeaders = { ...headers, origin: window.location.href };

  // adjusting refresh tokens headers
  if (operationName === 'RefreshTokens') {
    return { headers: commonHeaders };
  }

  // adjusting guest access headers
  if (isGuestAccessRoute()) {
    const guestAccessToken = localStorage.getItem('guestAccessToken');
    const meetingToken = localStorage.getItem('meetingToken');
    if (guestAccessToken && meetingToken) {
      return {
        headers: {
          ...commonHeaders,
          'Guest-Access-Token': guestAccessToken,
          'Meeting-Token': meetingToken,
        },
      };
    }
    return { headers: commonHeaders };
  }

  const token: AuthTokens | null = await getToken();
  return {
    headers: {
      ...commonHeaders,
      authorization: token && token.accessToken ? `Bearer ${token.accessToken}` : '',
      'Workspace-Token': token && token.workspaceToken ? token.workspaceToken : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      type ExtendedGQLError = typeof error & { message: string | undefined; messages: string[] };
      const { message, messages, extensions } = error as ExtendedGQLError;
      const errorMessage = message || messages.join('; ');

      switch (extensions?.code) {
        case ExecutionErrorCodes.TOKEN_ERROR:
        case ExecutionErrorCodes.TOKEN_EXPIRED:
        case ExecutionErrorCodes.UNAUTHENTICATED:
          logout();
          break;

        case ExecutionErrorCodes.PERMISSION_DENIED:
          history.replace(Routes.ErrorPermissionDenied);
          break;

        case ExecutionErrorCodes.INTERNAL_SERVER_ERROR:
          history.replace(Routes.ErrorInternalServer);
          logout();
          break;

        case ExecutionErrorCodes.EXTERNAL_SERVICE_ERROR:
          history.replace(Routes.ErrorGeneric, {
            error: { title: 'External Service Error', message: errorMessage },
          });
          break;

        default:
          if (extensions?.type === 'GOOGLE_CALENDAR_NOT_ALLOWED') return;
          history.replace(Routes.ErrorGeneric, { error: { message: errorMessage } });
      }
    });
  }

  if (networkError) {
    const isFirefox = !!navigator.userAgent.match(/firefox|fxios/i);
    const performance = window.performance;
    const navigationEntries = performance.getEntriesByType(
      'navigation',
    ) as PerformanceNavigationTiming[];

    const isPageReloadedLegacy = () => performance.navigation && performance.navigation.type === 1;
    const isPageReloaded = () => navigationEntries.some((nav) => nav?.type === 'reload');

    // ignores the error caused by a specific Firefox problem with refreshing the page before it fully loads
    if (isFirefox && (isPageReloaded() || isPageReloadedLegacy())) return;
    // ignore errors caused by the following operations
    // because they will be handled by the retryLink
    if (OPERATIONS_TO_RETRY.includes(operation.operationName)) return;
    // otherwise, redirect to error page
    history.replace(Routes.ErrorGeneric, {
      error: { title: 'Connection Error', message: networkError.message },
    });
  }
});

const cache = new InMemoryCache({
  typePolicies: {
    APIKeyType: {
      keyFields: ['token'],
    },
    APIDetailsType: {
      merge: true,
    },
    DetailedMeetingType: {
      fields: {
        accessItems: { merge: false },
        highlights: { merge: false },
        diarizationItems: { merge: false },
        participants: { merge: false },
      },
    },
    CommitmentsGroupedByWeek: {
      fields: {
        commitments: { merge: false },
      },
    },
    CustomOutboundIntegrationType: {
      fields: {
        fields: { merge: false },
      },
    },
    CustomerType: {
      keyFields: ['stripeId'],
      fields: {
        paymentMethod: { merge: true },
        monthlyFeaturesUsage: { merge: true },
      },
    },
    MeetingPermissionsType: {
      merge: true,
    },
    MonthlyFeaturesUsageType: {
      merge: true,
    },
    SlackDestinationType: {
      keyFields: ['slackId'],
    },
    StripePlanType: {
      fields: {
        features: { merge: true },
      },
    },
    StripePriceCurrencyOptionsType: {
      merge: true,
    },
    SlackRuleDestinationType: {
      keyFields: ['slackId'],
    },
    TeamType: {
      fields: {
        users: { merge: false },
      },
    },
    UserGuideItemType: {
      keyFields: ['type'],
    },
    ZapierIntegrationSettingsType: {
      keyFields: ['apiKey'],
    },
    Query: {
      fields: {
        me: { merge: true },
        meetingBookmarks: { merge: false },
        myCommitments: { merge: false },
        myMeetingsPaginated: {
          keyArgs: ['search', 'startTime', 'endTime'],
          merge(existing = { __typename: 'DetailedMeetingPaginatedType', objects: [] }, incoming) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        sharedMeetingsPaginated: {
          keyArgs: ['endTime', 'startTime', 'search', 'perPage', 'orderBy', 'statuses'],
          merge(existing = { __typename: 'DetailedMeetingPaginatedType', objects: [] }, incoming) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        meetingsPaginated: {
          keyArgs: ['endDate', 'startDate', 'search', 'perPage', 'orderBy', 'statuses'],
          merge(existing = { __typename: 'DetailedMeetingPaginatedType', objects: [] }, incoming) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        userChatMeetingsPaginated: {
          keyArgs: ['chatId', 'search', 'perPage', 'orderBy'],
          merge(existing = { __typename: 'DetailedMeetingPaginatedType', objects: [] }, incoming) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        chatsPaginated: {
          keyArgs: ['perPage', 'orderBy', 'search'],
          merge(existing = { __typename: 'ChatsPaginatedType', objects: [] }, incoming) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        chatHistoryItemsPaginated: {
          keyArgs: ['chatId', 'orderBy', 'perPage'],
          merge(existing = { __typename: 'ChatHistoryItemPaginatedType', objects: [] }, incoming) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        commitmentsGroupedByWeeks: {
          keyArgs: ['perPage', 'search', 'isCompleted'],
          merge(
            existing = { __typename: 'CommitmentsGroupedByWeekPaginatedType', objects: [] },
            incoming,
          ) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        outboundIntegrations: {
          keyArgs: [],
          merge(existing, incoming) {
            return incoming;
          },
        },
        sharedMeetingsGroupedByDay: {
          keyArgs: ['endDate', 'startDate', 'descending', 'perPage'],
          merge(
            existing = { __typename: 'DetailedMeetingGroupedByDayPaginatedType', objects: [] },
            incoming,
          ) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        userBookmarksPaginated: {
          keyArgs: [],
          merge(existing = { __typename: 'BookmarkPaginatedType', objects: [] }, incoming) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        userIntegrations: {
          keyArgs: [],
          merge(existing, incoming) {
            return incoming;
          },
        },
        rcNewsItemsPaginated: {
          keyArgs: [],
          merge(existing = { __typename: 'RCNewsItemsPaginatedType', objects: [] }, incoming) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        notionPages: {
          keyArgs: ['integrationType'],
          merge(existing = { __typename: 'NotionPagesPaginatedType', objects: [] }, incoming) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        workspaceIntegrations: {
          merge: false,
        },
        workspaceUsersPaginated: {
          keyArgs: ['userRole', 'enabled'],
          merge(existing = { __typename: 'UserPaginatedType', objects: [] }, incoming) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
        userInsightsPaginated: {
          keyArgs: ['perPage', 'search'],
          merge(
            existing = { __typename: 'InsightPaginatedType', objects: [] },
            incoming,
          ) {
            return {
              ...incoming,
              objects: uniqBy([...existing.objects, ...incoming.objects], '__ref'),
            };
          },
        },
      },
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink,
);

export const apolloClient = new ApolloClient({
  cache,
  link: from([retryLink, splitLink, errorLink, uploadLink]),
  queryDeduplication: true,
});

export default apolloClient;
