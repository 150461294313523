import pluralize from 'pluralize';
import { Fragment, useEffect, useLayoutEffect, useState, useRef, useMemo } from 'react';
import { useQuery, useSubscription, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MaterialLink from '@material-ui/core/Link';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Sembly UI
import { copyToClipboard } from '@powowfe/common';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
// Lib Shared
import { TransitionSlide, Typing } from '../components';
import { isSafariBrowser } from '../utils';
// Module Shared
import AskSemblyProhibitedDialog from '../dialogs/AskSemblyProhibitedDialog';
import AskSemblyMenuDesktop from '../components/AskSemblyMenuDesktop';
// GQL Types and Queries
import agentsQuery from '../graphql/queries/GPTAgent.graphql';
import askSemblyMutation from '../graphql/mutations/AskSembly.graphql';
import chatSubscription from '../graphql/subscriptions/ChatSubscription.subscription';
import historyQuery from '../graphql/queries/GPTHistory.graphql';
import limitsQuery from '../graphql/queries/GPTLimits.graphql';
import {
  AskSembly,
  AskSemblyVariables,
  ExecutionErrorCodes,
  ExecutionErrors,
  GPTAgent,
  GPTHistory,
  GPTHistoryVariables,
  GPTLimits,
  RequestCategory,
} from '../types';

/* #region  Types */
enum MessageType {
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER',
}

export interface ContainerMenuData {
  anchorEl: HTMLElement | null;
  message?: string;
}

export interface Message {
  type: MessageType;
  message: string;
}

export interface AskSemblyChatProps {
  anchorEl: HTMLElement | null;
  isHiddenUpgradeButtons: boolean;
  isOpen: boolean;
  isPeronalPlan: boolean;
  meetingId: string;
  username: string;
  onClose: () => void;
  onClickOnUpgradePlan: () => void;
}
/* #endregion */

export const GPTChatContainer: React.VFC<AskSemblyChatProps> = ({
  anchorEl,
  isHiddenUpgradeButtons,
  isOpen,
  isPeronalPlan,
  meetingId,
  username,
  onClose,
  onClickOnUpgradePlan,
}) => {
  /* #region  Hooks */
  const theme = useTheme();
  const styles = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const timeoutRef = useRef<NodeJS.Timeout>();

  const [errorMsg, setErrorMsg] = useState<string | null>('');
  const [contentSection, setContentSection] = useState<HTMLUListElement | null>(null);
  const [desktopMenuData, setDesktopMenuData] = useState<ContainerMenuData | null>(null);
  const [isFAQExpanded, setIsFAQExpanded] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [isLongWaiting, setIsLongWaiting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [shownAskSemblyProhibitedDialog, setShownAskSemblyProhibitedDialog] = useState(false);

  const { data: agents, loading: isLoadingAgents } = useQuery<GPTAgent>(agentsQuery);

  const {
    data: limits,
    loading: isLoadingLimits,
    refetch: refetchLimits,
  } = useQuery<GPTLimits>(limitsQuery, {
    fetchPolicy: 'network-only',
  });

  const { data: historyData, refetch: refetchHistoryData } = useQuery<
    GPTHistory,
    GPTHistoryVariables
  >(historyQuery, {
    variables: { meetingId },
    fetchPolicy: 'cache-and-network',
  });

  const [askQuestion] = useMutation<AskSembly, AskSemblyVariables>(askSemblyMutation);

  useSubscription(chatSubscription, {
    onSubscriptionData: () => {
      refetchLimits();
    },
  });
  /* #endregion */

  /* #region  Handlers */
  const handleChangeContent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserMessage(e.target.value.substring(0, MESSAGE_CHARS_MAX_COUNT));
  };

  const handleToggleFAQ = () => {
    setIsFAQExpanded((prev) => !prev);
  };

  const handleToggleDesktopMenu =
    (isOpen: boolean, message?: string) => (event: React.MouseEvent<HTMLElement>) => {
      setDesktopMenuData({ anchorEl: isOpen ? event.currentTarget : null, message: message });
    };

  const handleCopyContent = async (message?: string) => {
    if (!message) return;
    const success = await copyToClipboard(message);
    if (success) {
      toast.dark('Copied to clipboard.');
    }
  };

  const handleKeyDownOnForm: (
    canSendNewQuestion: boolean,
  ) => React.KeyboardEventHandler<HTMLFormElement> = (canSendNewQuestion) => (event) => {
    if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
      if (canSendNewQuestion) handleSubmit();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleKeyDownOnTextField: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' && event.ctrlKey && !isSafariBrowser()) {
      const target = event.currentTarget;
      const cursorPosition = target.selectionStart || 0;
      const textBeforeCursorPosition = target.value.substring(0, cursorPosition);
      const textAfterCursorPosition = target.value.substring(cursorPosition, target.value.length);
      const resultMessage = textBeforeCursorPosition + '\r\n' + textAfterCursorPosition;
      if (resultMessage.length < MESSAGE_CHARS_MAX_COUNT) {
        setUserMessage(resultMessage);
      }
    }
  };

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = (event) => {
    const value = event.clipboardData.getData('Text');
    if (value.length > MESSAGE_CHARS_MAX_COUNT) {
      setUserMessage(value.substring(0, MESSAGE_CHARS_MAX_COUNT));
    }
  };

  const handleAskSembly = async (category?: RequestCategory, suggestedQuestion?: string) => {
    if (userMessage || suggestedQuestion) {
      const data = historyData?.myAskSemblyHistory || [];
      const variables: AskSemblyVariables = {
        question: suggestedQuestion ? suggestedQuestion : userMessage,
        meetingId: meetingId,
        semblianAgentId: '1',
        category: category,
      };

      setIsProcessing(true);
      setIsLongWaiting(false);
      setErrorMsg(null);
      setUserMessage('');

      timeoutRef.current = setTimeout(() => {
        setIsLongWaiting(true);
      }, 40000);

      const result = await askQuestion({
        variables,
        optimisticResponse: {
          askSembly: {
            __typename: 'AskSemblyMutationPayload',
            success: true,
            errors: [],
            questionAnswer: {
              __typename: 'QuestionAnswerType',
              answer: '',
              question: variables.question,
              id: data[data.length - 1]?.id + 1,
              isProcessing: true,
            },
          },
        },
      });

      if (result.data) {
        const error = result.data.askSembly?.errors?.[0];

        if (error && error.__typename !== 'ExecutionErrorType') return;

        const executionError = error as ExecutionErrors;
        const errorMessage = executionError?.messages?.[0];
        const errorCode = executionError?.extensions?.code;

        if (executionError) {
          if (errorCode === ExecutionErrorCodes.USED_FREE_REQUESTS) {
            setShownAskSemblyProhibitedDialog(true);
            setErrorMsg(errorMessage || 'No more trial prompts remaining.');
          } else if (errorCode === ExecutionErrorCodes.PERMISSION_DENIED) {
            setErrorMsg(errorMessage || 'Please check your credits balance.');
          }
          setIsProcessing(false);
          return;
        }
      }
    }

    await refetchHistoryData();
    setIsProcessing(false);
  };

  const handleAskSuggestedQuestions =
    (category: RequestCategory, suggestedQuestion: string) => () => {
      handleAskSembly(category, suggestedQuestion);
      setIsFAQExpanded(false);
    };

  const handleSubmit = () => {
    handleAskSembly();
  };

  const handleCopyMessage = () => {
    handleCopyContent(desktopMenuData?.message);
  };

  const handleCloseMenuDesktop = () => {
    setDesktopMenuData(null);
  };

  const handleOpenMenuDesktop = () => {
    setDesktopMenuData(desktopMenuData);
  };

  const handleToggleAskSemblyProhibitedDialog = (open: boolean) => () => {
    setShownAskSemblyProhibitedDialog(open);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const semblian = agents?.semblianAgent;
  const semblianName = semblian?.name;
  const greetingMessage = semblian?.greetingMessage;
  const SemblianIcon = semblian?.avatar;
  const suggestedQuestions = semblian?.suggestedQuestions;
  const paymentCustomer = limits?.me?.paymentCustomer;
  const fisrtName = username.split(' ')[0];
  const askSemblyLimits = limits?.me?.paymentCustomer;
  const freeRequests = askSemblyLimits?.plan?.features?.staticLimits?.askSemblyFreeRequests ?? 0;
  const requestsUsed = paymentCustomer?.monthlyFeaturesUsage?.askSemblyRequestsUsed ?? 0;
  const promptsAmount = freeRequests - requestsUsed;
  const freePromptsLeft = `${promptsAmount} free ${pluralize('prompt', promptsAmount)} left`;
  const messageCharsCount = userMessage.length || 0;

  const renderSuggestedQuestions = (onlyFirst?: boolean) => {
    if (!suggestedQuestions?.length) return null;

    let currentCategory: RequestCategory | null = null;

    return suggestedQuestions.map((item) => {
      if (currentCategory !== item.category) {
        currentCategory = item.category;
      } else {
        if (onlyFirst) {
          return null;
        }
      }
      return (
        <Fragment key={item.id}>
          <ListItem
            className={styles.suggestedItem}
            onClick={handleAskSuggestedQuestions(item.category, item.question)}
          >
            <Typography variant="body1">{item.question}</Typography>
          </ListItem>
        </Fragment>
      );
    });
  };
  /* #endregion */

  /* #region  Effects */
  useLayoutEffect(() => {
    contentSection?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [historyData?.myAskSemblyHistory?.length, contentSection, errorMsg]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);
  /* #endregion */

  const content = useMemo(() => {
    let result: { type: MessageType; message: string; isProcessing: boolean }[] = [
      {
        type: MessageType.ANSWER,
        message: `Hi ${fisrtName}! 👋`,
        isProcessing: false,
      },
      {
        type: MessageType.ANSWER,
        message: greetingMessage || '',
        isProcessing: false,
      },
    ];

    historyData?.myAskSemblyHistory?.forEach((item, index) => {
      const isLast = index === (historyData?.myAskSemblyHistory || []).length - 1;
      const isProcessing = item.isProcessing;
      const waitText = isLongWaiting ? 'That was a tough one, still thinking' : 'Thinking';
      result.push(
        {
          type: MessageType.QUESTION,
          message: item.question,
          isProcessing: isProcessing && isLast,
        },
        {
          type: MessageType.ANSWER,
          isProcessing: isProcessing && isLast,
          message: isProcessing
            ? waitText
            : item.answer || `Something went wrong. ${isLast ? 'Please try again later' : ''}`,
        },
      );
    });

    if (!!errorMsg) {
      result.push({
        type: MessageType.ANSWER,
        message: errorMsg,
        isProcessing: false,
      });
    }

    return result;
  }, [historyData?.myAskSemblyHistory, isLongWaiting, errorMsg, fisrtName, greetingMessage]);

  const lastAnswer = content[content.length - 1];
  const canSendNewQuestion = !lastAnswer.isProcessing && !!userMessage && !isProcessing;
  const canSendSuggestedQuestion = !lastAnswer.isProcessing && !isProcessing;

  return (
    <Popover
      open={isOpen}
      container={isSmallScreen ? document.getElementById('root') : anchorEl}
      classes={{ paper: styles.root }}
      anchorEl={anchorEl}
      marginThreshold={0}
      TransitionComponent={isSmallScreen ? TransitionSlide : Fade}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={styles.title}>
        {isLoadingLimits || isLoadingAgents ? (
          <CircularProgress color="inherit" size={12} />
        ) : (
          <>
            {!isLoadingAgents && (
              <Typography variant="h6" className={styles.headlines}>
                {semblianName}
              </Typography>
            )}
            {isPeronalPlan && (
              <Typography component="div" variant="body2" className={styles.creditsLeft}>
                {freePromptsLeft}
              </Typography>
            )}
          </>
        )}

        <Box
          display="flex"
          textAlign="center"
          justifyContent="space-between"
          flexDirection="row-reverse"
          className={styles.iconsContainer}
        >
          <Box display="flex" textAlign="center">
            <MaterialLink
              className={styles.iconButton}
              href="https://sembly-ai.zendesk.com/hc/en-us/articles/13837459230481"
              rel="noopener noreferrer"
              target="_blank"
            >
              <HelpOutlineIcon />
            </MaterialLink>
            <IconButton aria-label="close" className={styles.iconButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Box className={styles.chatContent}>
          <List ref={setContentSection} className={styles.messageList}>
            {content.map((item, index) =>
              item.type === MessageType.QUESTION ? (
                <ListItem
                  key={index}
                  className={styles.messageListItemQuestion}
                  onMouseEnter={handleToggleDesktopMenu(true, item.message)}
                  onMouseLeave={handleToggleDesktopMenu(false)}
                >
                  <ListItemText className={styles.questionText}>
                    <Typography variant="body1">{item.message}</Typography>
                  </ListItemText>
                </ListItem>
              ) : (
                <ListItem
                  key={index}
                  onMouseEnter={handleToggleDesktopMenu(!!item.message, item.message)}
                  onMouseLeave={handleToggleDesktopMenu(false)}
                  className={styles.messageListItemAnswer}
                >
                  {lastAnswer.type !== MessageType.ANSWER && (
                    <ListItemAvatar>
                      <Avatar
                        alt={semblianName}
                        className={styles.semblianIcon}
                        src={SemblianIcon || undefined}
                      />
                    </ListItemAvatar>
                  )}
                  <ListItemText
                    className={`${styles.answersText} ${item.isProcessing ? styles.typing : ''}`}
                  >
                    <Typography component="span" variant="body1">
                      {item.message}
                    </Typography>
                    {item.isProcessing && <Typing />}
                  </ListItemText>
                </ListItem>
              ),
            )}
          </List>
          {!historyData?.myAskSemblyHistory?.length && !errorMsg && canSendSuggestedQuestion && (
            <Box display="flex" flexDirection="row-reverse">
              <List className={styles.suggestedList}>{renderSuggestedQuestions(true)}</List>
            </Box>
          )}
        </Box>
      </DialogContent>

      <div className={`${styles.faqBlock} ${isFAQExpanded ? styles.isFAQExpanded : ''}`}>
        <DialogActions className={styles.actions}>
          <form onKeyDown={handleKeyDownOnForm(canSendNewQuestion)} className={styles.actionsForm}>
            <Box flex={1} display="flex">
              <div className={styles.textareaBlock}>
                <TextField
                  fullWidth
                  multiline
                  minRows={2}
                  variant="filled"
                  value={userMessage}
                  inputProps={{ maxLength: MESSAGE_CHARS_MAX_COUNT }}
                  InputProps={{
                    classes: { root: styles.textField },
                    onKeyDown: handleKeyDownOnTextField,
                  }}
                  placeholder="Ask me anything"
                  onChange={handleChangeContent}
                  onPaste={handlePaste}
                />
                <span className={styles.messageCharsCount}>
                  {MESSAGE_CHARS_MAX_COUNT - messageCharsCount}
                </span>
              </div>
              <Tooltip
                arrow
                placement="top"
                title={
                  canSendNewQuestion ? 'Send' : !!userMessage ? 'Please wait' : 'Type a message'
                }
              >
                <div className={styles.buttonActionWrapper}>
                  <IconButton
                    color={userMessage ? 'primary' : 'default'}
                    className={styles.buttonAction}
                    disabled={!canSendNewQuestion || isLoadingAgents}
                    onClick={handleSubmit}
                  >
                    <SendIcon />
                  </IconButton>
                </div>
              </Tooltip>
              <Tooltip
                arrow
                placement="top"
                title={canSendSuggestedQuestion ? 'Suggestions' : 'Please wait'}
              >
                <div className={styles.buttonActionWrapper}>
                  <IconButton
                    className={styles.buttonAction}
                    disabled={!canSendSuggestedQuestion}
                    onClick={handleToggleFAQ}
                  >
                    {isFAQExpanded ? (
                      <CloseIcon />
                    ) : (
                      <EmojiObjectsOutlinedIcon className={styles.navIcon} />
                    )}
                  </IconButton>
                </div>
              </Tooltip>
            </Box>
            <Box flex={0}>
              <Typography variant="body2" color="textSecondary">
                AI-generated content, use judiciously
              </Typography>
            </Box>
          </form>
        </DialogActions>
        <div className={styles.faqBlockContent}>{renderSuggestedQuestions()}</div>
      </div>

      <AskSemblyMenuDesktop
        anchorEl={desktopMenuData?.anchorEl || null}
        onClickOnCopyContent={handleCopyMessage}
        onClose={handleCloseMenuDesktop}
        onMouseEnter={handleOpenMenuDesktop}
      />

      {shownAskSemblyProhibitedDialog && (
        <AskSemblyProhibitedDialog
          isHiddenUpgradeButtons={isHiddenUpgradeButtons}
          onClose={handleToggleAskSemblyProhibitedDialog(false)}
          onClickOnPromoteUpgrade={onClickOnUpgradePlan}
        >
          {errorMsg}
        </AskSemblyProhibitedDialog>
      )}
    </Popover>
  );
};

const MESSAGE_CHARS_MAX_COUNT = 1000;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(11),
    top: 'auto !important',
    left: 'auto !important',
    width: `calc(100% - ${theme.spacing(6)}px)`,
    maxWidth: 600,
    borderRadius: theme.spacing(1),
    background: theme.palette.background.paper,
    boxShadow: '0px 0px 48px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      right: 0,
      bottom: 0,
      borderRadius: 0,
      maxHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  title: {
    backgroundColor: '#2F3241',
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    padding: theme.spacing(1, 8),
    minHeight: theme.spacing(6.5),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  headlines: {
    fontSize: '1rem',
  },
  creditsLeft: {
    opacity: 0.7,
  },
  iconsContainer: {
    position: 'absolute',
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  },
  iconButton: {
    display: 'flex',
    color: theme.palette.common.white,
    padding: theme.spacing(0.5),
  },
  content: {
    padding: 0,
    color: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexGrow: 1,
    },
  },
  chatContent: {
    padding: theme.spacing(2, 2, 0),
    overflowY: 'auto',
    height: '458px',
    maxHeight: `calc(100vh - 300px)`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 1.5, 0),
      height: 'auto',
      maxHeight: `none`,
      flexGrow: 1,
    },
  },
  preview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '418px',
    maxHeight: `calc(100vh - 340px)`,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      maxHeight: `none`,
      flexGrow: 1,
    },
  },
  semblianIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
  },
  navIcon: {
    width: '32px',
    height: '32px',
    color: theme.palette.highlight.main,
  },
  name: {
    fontSize: 16,
    fontWeight: 500,
    paddingRight: theme.spacing(0.5),
  },
  messageList: {
    padding: '0 0 1px 0',
  },
  messageListItemAnswer: {
    position: 'relative',
    padding: '0 0 0 48px',
    display: 'block',
    minHeight: '45px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1.5),
    },
  },
  answersText: {
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#F2F2F3',
    padding: theme.spacing(1.5),
    color: theme.palette.common.black,
    margin: 0,
    overflowWrap: 'anywhere',
    wordBreak: 'keep-all',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
  },
  typing: {
    float: 'left',
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  messageListItemQuestion: {
    display: 'block',
    padding: '0 0 0 36%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1.5),
    },
  },
  questionText: {
    backgroundColor: '#2F3241',
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(1.5),
    color: theme.palette.common.white,
    margin: 0,
    overflowWrap: 'anywhere',
    wordBreak: 'keep-all',
    hyphens: 'auto',
  },
  suggestedList: {
    padding: 0,
    width: '64%',
  },
  suggestedItem: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(1.5),
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.grey['A100']}`,
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F2F2F3',
    },
  },
  actions: {
    padding: theme.spacing(1.5),
    height: '100px',
  },
  actionsForm: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  textField: {
    border: `1px solid ${theme.palette.grey['A100']}`,
    borderRadius: theme.shape.borderRadius * 2,
    padding: 0,
    '& textarea': {
      height: '38px !important',
      padding: theme.spacing(1.5),
      borderRadius: theme.shape.borderRadius * 2,
    },
  },
  textareaBlock: {
    position: 'relative',
    flexGrow: 1,
  },
  messageCharsCount: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    right: theme.spacing(1),
    color: theme.palette.grey[300],
  },
  buttonActionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  buttonAction: {
    width: '48px',
    border: `1px solid ${theme.palette.grey['A100']}`,
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    height: '100%',
  },
  faqBlock: {
    position: 'relative',
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
    transition: 'all 0.3s ease-in-out',
  },
  isFAQExpanded: {
    transform: 'translateY(-320px)',
    boxShadow: '0px 0px 48px rgba(0, 0, 0, 0.1)',
  },
  faqBlockContent: {
    position: 'absolute',
    top: '100px',
    width: '100%',
    height: theme.spacing(40),
    padding: theme.spacing(1.5),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
    overflowY: 'auto',
  },
}));

export default GPTChatContainer;
