import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
// Sembly UI
import { GraphError } from '@sembly-ui';
// App Shared
import generateAccessTokenMutation from '@shared/queries/GenerateIntegrationFabricWorkspaceToken.graphql';
import { GenerateIntegrationFabricWorkspaceToken, PlanIDEnum } from '@gql-types';
import { useUserContext } from '@shared/hooks';

/**
 * Generates an access token for the Integration Fabric and stores it in a cookie.
 */
export const useFabricWorkspaceLevelAuth = () => {
  const user = useUserContext();

  const [authToken, setAuthToken] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState<GraphError | null>(null);

  const [generateAccessToken] = useMutation<GenerateIntegrationFabricWorkspaceToken>(
    generateAccessTokenMutation,
  );

  const removeAccessToken = () => {
    setAuthToken('');
    setError(null);
  };

  const billingAccessId = user.data?.me?.billingAccess?.id;
  const canRequestAuthToken =
    billingAccessId &&
    [PlanIDEnum.TEAM, PlanIDEnum.TEAM_PLUS, PlanIDEnum.ENTERPRISE].includes(billingAccessId);

  useEffect(() => {
    // method to remove the access token from the cookie
    const removeAccessToken = () => {
      setAuthToken('');
      setError(null);
    };

    const requestAndSetAuthToken = async () => {
      setIsLoading(true);

      const result = await generateAccessToken();
      const accessToken = result.data?.generateIntegrationFabricWorkspaceToken?.accessToken;

      if (!accessToken) {
        setError(result.data?.generateIntegrationFabricWorkspaceToken?.errors);
        return '';
      }

      setAuthToken(accessToken || '');
      setIsLoading(false);
    };

    if (canRequestAuthToken) {
      requestAndSetAuthToken();
    } else {
      removeAccessToken();
      return;
    }

    return () => {
      removeAccessToken();
    };
  }, [generateAccessToken, canRequestAuthToken]);

  return { authToken, loading, error, removeAccessToken };
};

export default useFabricWorkspaceLevelAuth;
