import {
  Redirect,
  Route,
  Switch,
  generatePath,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { useEffect } from 'react';
import { setCookie } from 'typescript-cookie';
import { LocationDescriptor } from 'history';
// Generated GQL types
import { PlanIDEnum } from '@gql-types';
// Sembly UI
import { userPlan } from '@sembly-ui';
// App Shared
import { LocationState, ProtectedRouterModule, PublicRouterModule } from '@shared/types';
import { PAYMENT_PLANS } from '@shared/constants';
import { Routes as R } from '@shared/enums';
import {
  STORAGE_REDIRECT_LOCATION_KEY,
  STORAGE_TARGET_SERVICE_PLAN_KEY,
} from '@shared/configuration';
import { useAuth } from '@shared/clients/authClient';
import { useUserContext, useTracking } from '@shared/hooks';

export interface ModuleRouterProps {
  modules: (PublicRouterModule | ProtectedRouterModule)[];
  rootRoute: string;
  homeRoute: string;
  loginRoute: string;
}

export const ModuleRouter: React.FC<ModuleRouterProps> = ({
  children,
  homeRoute,
  loginRoute,
  modules,
  rootRoute,
}) => {
  /* #region Hooks */
  const [logged] = useAuth();
  const user = useUserContext();

  const history = useHistory();
  const location = useLocation<LocationState>();

  const isHomeRoute = !!useRouteMatch(homeRoute)?.isExact;
  const isCheckoutRoute = !!useRouteMatch(R.Checkout);
  const isSignUpRoute = !!useRouteMatch(R.SignUp);
  const isErrorPage = !!useRouteMatch(R.Error);

  useTracking();
  /* #endregion */

  /* #region  Render Helpers */
  const targetPlanId = sessionStorage.getItem(STORAGE_TARGET_SERVICE_PLAN_KEY);
  const isRequiredPaywall = !isCheckoutRoute && user.data?.me?.paymentCustomer?.paywallRequired;
  const isValidServicePlan = !!targetPlanId && Object.keys(PAYMENT_PLANS).includes(targetPlanId);
  const planId = isValidServicePlan ? targetPlanId : PlanIDEnum.PROFESSIONAL;
  const checkoutRoute = generatePath(R.CheckoutStart, { planId: planId! });

  const searchParams = new URLSearchParams(location.search);
  const servicePlan = searchParams.get('servicePlan') || '';

  const loginRedirectLocation: LocationDescriptor<LocationState> = {
    pathname: loginRoute,
    state: { from: { pathname: location.pathname, search: location.search } },
  };

  const paymentCustomer = user.data?.me?.paymentCustomer;
  const isRequiredEmailValidation = user.data?.me?.isValidEmail === false;
  const isSmartMeetingUser = user.data?.me?.philipsDevices?.smartmeeting ?? false;
  const isLoadedUserData = user.data?.me !== undefined;
  const isPersonalPlan = userPlan('equals', PlanIDEnum.PERSONAL, paymentCustomer?.plan?.id);
  const hasSmartMeetingSerialNumber = !!user.data?.me?.philipsDevices?.smartmeetingSerialNumber;
  const isRequiredSmartMeetingRegistration = isSmartMeetingUser && !hasSmartMeetingSerialNumber;
  const isRequiredNewAccountOnboarding = user.data?.me?.showLanguageOnboarding ?? false;
  const isRequiredInvitationOnboarding = user.data?.me?.showInvitationOnboarding ?? false;
  const isEmailValidationRequired = logged && isLoadedUserData && isPersonalPlan && isRequiredEmailValidation;
  /* #endregion */

  if (!!servicePlan && isSignUpRoute) {
    setCookie('targetServicePlan', servicePlan); // POST request to the server requires the cookie
    sessionStorage.setItem(STORAGE_TARGET_SERVICE_PLAN_KEY, servicePlan);
  }

  useEffect(() => {
    if (isErrorPage || !user.data?.me) return;

    if (isEmailValidationRequired) return history.replace(R.OnboardingEmailConfirmation);
    if (isRequiredInvitationOnboarding) return history.replace(R.OnboardingWorkspace);
    if (isRequiredSmartMeetingRegistration) return history.replace(R.OnboardingPhilipsDevice);
    if (isRequiredNewAccountOnboarding) return history.replace(R.OnboardingNewAccount);
    if (isRequiredPaywall) return history.replace(checkoutRoute);

    const nextLocation = sessionStorage.getItem(STORAGE_REDIRECT_LOCATION_KEY);
    let nextLocationParsed: null | { pathname: string; search: string } = null;

    try {
      nextLocationParsed = !!nextLocation
        ? (JSON.parse(nextLocation) as { pathname: string; search: string })
        : null;
    } catch (error) {
      sessionStorage.removeItem(STORAGE_REDIRECT_LOCATION_KEY);
    }

    const isRequiredRedirectToFromPath = logged && !!nextLocationParsed;

    if (isRequiredRedirectToFromPath && isHomeRoute) {
      sessionStorage.removeItem(STORAGE_REDIRECT_LOCATION_KEY);
      const target: LocationDescriptor<LocationState> = {
        pathname: nextLocationParsed!.pathname,
        search: nextLocationParsed!.search,
      };

      setTimeout(() => {
        history.replace(target);
      });
    }
  }, [
    checkoutRoute,
    history,
    isEmailValidationRequired,
    isErrorPage,
    isHomeRoute,
    isRequiredInvitationOnboarding,
    isRequiredNewAccountOnboarding,
    isRequiredPaywall,
    isRequiredSmartMeetingRegistration,
    location.pathname,
    logged,
    user.data?.me,
  ]);

  return (
    <>
      {children}
      <Switch>
        <Redirect exact from={rootRoute} to={homeRoute} />

        {modules.map(({ isProtected, routeProps }, index) => {
          const key = `${routeProps.path}-${index}`;
          return isProtected && !logged ? (
            <Redirect key={key} to={loginRedirectLocation} />
          ) : (
            <Route key={key} {...routeProps} />
          );
        })}

        <Redirect to={R.PageNotFound} />
      </Switch>
    </>
  );
};

export default ModuleRouter;
