import { Integration, FlowInstance } from '@integration-app/sdk';
import { useState, useEffect, useMemo } from 'react';
import { startCase } from 'lodash';
// Material UI
import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import InputBase from '@material-ui/core/InputBase';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import { useAutomation, useConfirmationDialog } from '../hooks';
import { AutomationRuleCard, GenericDialog, GenericConfirmation } from '../components';
import {
  AUTOMATION_FILTER_TYPES,
  IRREGULAR_INTEGRATION_KEYS,
  WORKSPACE_AUTOMATION_FILTER_TYPES,
} from '../constants';
import ManageThirdPartyAutomationDataTypeDialog from './ManageThirdPartyAutomationDataTypeDialog';
import ManageThirdPartyWorkspaceAutomationFilterDialog from './ManageThirdPartyWorkspaceAutomationFilterDialog';
import ManageThirdPartyAutomationDestinationDialog from './ManageThirdPartyAutomationDestinationDialog';
// Lib Assets & Icons
import semblyLogo from '../assets/sembly-logo.svg';
import DataTypeIcon from '../icons/DataType';
import DestinationIcon from '../icons/Destination';
import FilterIcon from '../icons/Filter';

export interface ManageThirdPartyAutomationDialogProps {
  automation: Integration;
  containerType: 'workspace' | 'personal';
  flowInstanceKey?: string;
  flowInstanceId: string;
  open: boolean;
  onClose: (isDiscarded: boolean, flowInstance?: FlowInstance) => void;
  onComplete?: () => void;
  onIntegrationAppError?: (error: unknown) => void;
}

export const ManageThirdPartyAutomationDialog: React.VFC<ManageThirdPartyAutomationDialogProps> = ({
  automation,
  containerType,
  flowInstanceKey,
  flowInstanceId,
  open,
  onClose,
  onComplete = () => null,
  onIntegrationAppError = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [isCompleting, setIsCompleting] = useState(false);
  const [isUpdatingFlowType, setIsUpdatingFlowType] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);

  const {
    dataTypeOptions,
    dataTypeValue,
    destinationDataSchema,
    destinationValue,
    flowInstance,
    hasActiveConnection,
    hasDestinationParametersSchema,
    integrationKey,
    loading,
    name,
    parameters,
    parametersSchema,
    updateDestinationValue,
    updateFlowInstance,
    updating,
  } = useAutomation(automation, flowInstanceKey, flowInstanceId);

  const [confirmDiscarding, DiscardingConfirmationDialog] = useConfirmationDialog((resolve) => (
    <GenericConfirmation
      open
      titleProps={{ color: 'inherit' }}
      title="Discard Changes"
      text="Are you sure you want to discard all changes and delete the automation?"
      confirmButtonProps={{ color: 'secondary' }}
      confirmButtonLabel="Discard Changes"
      onCancel={() => resolve(false)}
      onConfirm={() => resolve(true)}
    />
  ));

  const [showDataTypeDialog, setShowDataTypeDialog] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showDestinationDialog, setShowDestinationDialog] = useState(false);
  const [integrationName, setIntegrationName] = useState(`${automation.name} Automation`);
  /* #endregion */

  const validateForm = () => {
    let errors: string[] = [];
    setFormErrors([]);

    if (!integrationName) {
      errors.push('Please enter an automation name.');
    }

    if (!dataTypeValue) {
      errors.push('Please select a data type.');
    }

    if (!parameters || !parameters.rule) {
      errors.push('Please select a filter value.');
    } else {
      if (parameters.rule === 'FILTERED_BY_TEAM' && !parameters?.teamId) {
        errors.push('Filter: Please select a team in the filter.');
      } else if (parameters.rule === 'FILTERED_BY_OWNER' && !parameters?.ownerId) {
        errors.push('Filter: Please select a user in the filter.');
      } else if (parameters.rule === 'FILTERED_BY_MEETING_TYPE' && !parameters.meetingType) {
        errors.push('Filter: Please select a meeting type in the filter.');
      } else if (parameters.rule === 'FILTERED_BY_KEYWORDS' && !parameters.keywords?.length) {
        errors.push('Filter: Please select at least one keyword in the filter.');
      }
    }

    if (!hasValidDestination) {
      errors.push('Please select a destination value.');
    }

    if (errors.length) {
      setFormErrors(errors);
      return false;
    }

    return true;
  };

  /* #region  Handlers */
  const handleChangeIntegrationName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIntegrationName(event.target.value);
  };

  const handleToggleDataTypeDialog = (open: boolean) => () => {
    setShowDataTypeDialog(open);
  };

  const handleToggleFilterDialog = (open: boolean) => () => {
    setShowFilterDialog(open);
  };

  const handleToggleDestinationDialog = (open: boolean) => () => {
    setShowDestinationDialog(open);
  };

  const handleChangeDataType = async (flowKey: string) => {
    // Create the flow instance
    setIsUpdatingFlowType(true);
    await updateFlowInstance({ name: integrationName, flowKey });
    setIsUpdatingFlowType(false);
  };

  const handleChangeFilter = async (parameters: {
    rule: string;
    keywords?: string[];
    meetingType?: string;
    ownerId?: number;
    teamId?: number;
  }) => {
    updateFlowInstance({ name: integrationName, parameters });
  };

  const handleChangeDestination = async (values: Record<string, string>) => {
    updateDestinationValue(values);
  };

  const handleComplete = async () => {
    if (!validateForm()) return;

    setIsCompleting(true);
    await updateFlowInstance({ parameters, name: integrationName, enabled: true });
    setIsCompleting(false);
    onComplete();
    onClose(false);
  };

  const handleDiscard = async () => {
    if (!!flowInstance && !validateForm()) {
      const isDiscarded = await confirmDiscarding();
      if (isDiscarded) onClose(true, flowInstance);
    } else {
      onClose(false);
    }
  };
  /* #endregion */

  useEffect(() => {
    if (!name) return;
    setIntegrationName(name);
  }, [name]);

  /* #region  */
  const hasValidDestination = useMemo(() => {
    const requiredFields = destinationDataSchema?.required || [];
    return requiredFields.every((field) => destinationValue[field]);
  }, [destinationValue, destinationDataSchema?.required]);

  const selectedFilterValue = useMemo(() => {
    let value = null;
    const filterTypes =
      containerType === 'workspace' ? WORKSPACE_AUTOMATION_FILTER_TYPES : AUTOMATION_FILTER_TYPES;

    if (!parameters) return null;

    if (parameters.rule === 'FILTERED_BY_TEAM') {
      value = parameters.teamId ? `${filterTypes[parameters.rule]}` : null;
    } else if (parameters.rule === 'FILTERED_BY_OWNER') {
      value = parameters.ownerId ? `${filterTypes[parameters.rule]}` : null;
    } else if (parameters.rule === 'FILTERED_BY_MEETING_TYPE') {
      value = parameters.meetingType ? `${filterTypes[parameters.rule]}` : null;
    } else if (parameters.rule === 'FILTERED_BY_KEYWORDS') {
      value = !!parameters.keywords?.length ? `${filterTypes[parameters.rule]}` : null;
    } else {
      value = filterTypes[parameters.rule];
    }

    return value;
  }, [parameters, containerType]);

  const selectedDestinationValue = useMemo(() => {
    if (!destinationDataSchema?.properties) return null;

    let result: string[] = [];

    Object.keys(destinationDataSchema.properties).forEach((fieldKey) => {
      const isIrrgularIntegration =
        !!integrationKey && IRREGULAR_INTEGRATION_KEYS.includes(integrationKey);

      const requiredFields = destinationDataSchema.required || [];
      const property = destinationDataSchema.properties?.[fieldKey];
      const collectionKey = property?.referenceCollection?.key;

      if (!isIrrgularIntegration && !requiredFields.includes(fieldKey)) {
        result.push('Selected');
        return;
      }

      if (!collectionKey) return;

      result.push(startCase(collectionKey.split('-').join(' ')));
    });

    // remove duplicates
    result = result.filter((value, index, self) => self.indexOf(value) === index);

    return result.length ? result.join(' / ') : null;
  }, [destinationDataSchema, integrationKey]);

  const selectedDataType = dataTypeOptions.find((o) => o.value === dataTypeValue)?.label || null;

  const hasDestinationOptions = useMemo(() => {
    let options = [];

    Object.keys(destinationDataSchema?.properties || []).forEach((fieldKey) => {
      const requiredFields = destinationDataSchema?.required || [];

      // If the field is not required, don't add it to the available options
      if (requiredFields.includes(fieldKey)) options.push(fieldKey);
    });

    return !!options.length;
  }, [destinationDataSchema]);
  /* #endregion */

  return (
    <>
      <GenericDialog
        hideTitle
        hideCloseIconButton
        disableTransition
        title="Apps"
        dialogProps={{ open, fullScreen: true, style: { zIndex: 1000 } }}
        onClose={handleDiscard}
      >
        <DialogContent className={styles.dialogContent}>
          <div className={styles.header}>
            <img src={semblyLogo} alt="Sembly" className={styles.logo} />
          </div>
          <div className={styles.content}>
            <div className={styles.appLogo}>
              {automation.logoUri ? (
                <img
                  className={styles.appLogoImage}
                  src={automation.logoUri}
                  alt={automation.name}
                  width={64}
                  height={64}
                />
              ) : (
                <Avatar>{automation.name.charAt(0).toUpperCase()}</Avatar>
              )}
            </div>
            <div>
              <Box position="relative">
                {loading && (
                  <Skeleton variant="rect" animation="wave" className={styles.skeleton} />
                )}
                <Tooltip arrow title="Enter Automation Name">
                  <InputBase
                    autoFocus={!loading}
                    disabled={!hasActiveConnection || loading}
                    placeholder={!loading ? 'Enter Automation Name' : ''}
                    classes={{
                      root: styles.fieldRoot,
                      input: styles.fieldInput,
                    }}
                    inputProps={{ 'aria-label': 'Enter Automation Name' }}
                    value={loading ? '' : integrationName}
                    onChange={handleChangeIntegrationName}
                  />
                </Tooltip>
              </Box>
              <div className={styles.steps}>
                <AutomationRuleCard
                  title="1. Data Type"
                  description="What would you like to send: notes, tasks, or transcriptions?"
                  loading={loading}
                  disabled={!hasActiveConnection}
                  icon={<DataTypeIcon color="action" />}
                  value={selectedDataType}
                  onClick={handleToggleDataTypeDialog(true)}
                />
                <div className={styles.delimiter} role="presentation">
                  —
                </div>
                <AutomationRuleCard
                  title="2. Filter"
                  description="Which meetings group should this be applied to?"
                  loading={loading}
                  icon={<FilterIcon color="action" />}
                  disabled={!dataTypeValue || !hasActiveConnection}
                  value={selectedFilterValue}
                  onClick={handleToggleFilterDialog(true)}
                />

                <div className={styles.delimiter} role="presentation">
                  —
                </div>
                <AutomationRuleCard
                  title="3. Destination"
                  description="Which destination would you like to use for sending this information?"
                  loading={loading}
                  icon={<DestinationIcon color="action" />}
                  disabled={
                    (!!dataTypeValue && !hasDestinationParametersSchema) ||
                    (!!dataTypeValue && !hasDestinationOptions) ||
                    !dataTypeValue ||
                    !hasActiveConnection
                  }
                  value={
                    !!dataTypeValue && !hasDestinationParametersSchema
                      ? 'Selected'
                      : dataTypeValue && hasValidDestination
                      ? selectedDestinationValue
                      : null
                  }
                  onClick={handleToggleDestinationDialog(true)}
                />
              </div>
              {!hasActiveConnection && (
                <Box my={2} display="flex" flexDirection="column" gridGap={6}>
                  <Alert severity="warning">
                    <Typography variant="body1">
                      The Integration for this Automation is disconnected. Please reconnect the
                      Integration or delete this Automation.
                    </Typography>
                  </Alert>
                </Box>
              )}
              {!!formErrors.length && (
                <Box my={2} display="flex" flexDirection="column" gridGap={6}>
                  {formErrors.map((error, index) => (
                    <Alert key={index} color="warning">
                      <Typography variant="body1">{error}</Typography>
                    </Alert>
                  ))}
                </Box>
              )}

              <div className={styles.actions}>
                <Box flexGrow={1}>
                  <Button variant="outlined" disabled={isCompleting} onClick={handleDiscard}>
                    Back
                  </Button>
                </Box>
                <Box display="flex" flexGrow={0} gridGap={8}>
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    disabled={loading || updating || isCompleting}
                    onClick={handleComplete}
                    startIcon={isCompleting && <CircularProgress size={18} color="inherit" />}
                  >
                    <span>Complete</span>
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </DialogContent>
      </GenericDialog>
      {/* Begin: Dialogs */}
      {showDataTypeDialog && (
        <ManageThirdPartyAutomationDataTypeDialog
          open
          loading={isUpdatingFlowType}
          items={dataTypeOptions}
          value={dataTypeValue}
          onChange={handleChangeDataType}
          onClose={handleToggleDataTypeDialog(false)}
        />
      )}
      {showFilterDialog && (
        <ManageThirdPartyWorkspaceAutomationFilterDialog
          open
          containerType={containerType}
          parametersSchema={parametersSchema}
          values={parameters}
          onChange={handleChangeFilter}
          onClose={handleToggleFilterDialog(false)}
        />
      )}
      {showDestinationDialog && (
        <ManageThirdPartyAutomationDestinationDialog
          open
          appKey={automation.key}
          values={destinationValue}
          dataSchema={destinationDataSchema}
          onChange={handleChangeDestination}
          onClose={handleToggleDestinationDialog(false)}
          onError={onIntegrationAppError}
        />
      )}
      {DiscardingConfirmationDialog}
      {/* End: Dialogs */}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1, 4, 4),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingRight: theme.spacing(2),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    flex: 1,
  },
  appLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 128,
    width: 128,
  },
  appLogoImage: {
    objectFit: 'contain',
  },
  skeleton: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
  logo: {
    flexGrow: 0,
    width: 160,
    height: 48,
    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 20,
      justifyContent: 'center',
      marginBottom: theme.spacing(3),
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  steps: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    flexDirection: 'row',
    gap: theme.spacing(2),
    margin: theme.spacing(4, 0),
  },
  delimiter: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  fieldRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px dashed ${theme.palette.divider}`,
    width: '100%',
    transition: theme.transitions.create(['border-color', 'box-shadow'], {
      duration: theme.transitions.duration.short,
    }),
    ...theme.typography.h1,
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
  fieldInput: {
    textAlign: 'center',
  },
}));

export default ManageThirdPartyAutomationDialog;
