import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import MaterialLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// App Shared
import { Alert } from '@shared/components';
import { RemindCancelSubscriptionDialog } from '@shared/dialogs';
import { Routes } from '@shared/enums';
import { convertSecondsToMinutes } from '@shared/utils';
import { useAuth } from '@shared/clients/authClient';
import { useUserContext } from '@shared/hooks';
// GraphQL Queries & Types
import dismissCalendarMessageMutation from '@shared/queries/DismissCalendarIntegrationLostMessage.graphql';
import { DismissCalendarIntegrationLostMessage, PlanIDEnum, UserRole } from '@gql-types';

export const GlobalNotifications: React.VFC = () => {
  /* #region  Hooks */
  const history = useHistory();

  const { data, loading } = useUserContext();
  const [isLoggedIn] = useAuth();

  const [remindSubscriptionIsOpen, setRemindSubscriptionIsOpen] = useState<boolean>(false);
  const alertRef = useRef<React.ReactText | null>(null);
  const showRunningOutLimitRef = useRef<boolean>(false);
  const showExceededLimitRef = useRef<boolean>(false);

  const isCheckoutRoute = !!useRouteMatch(Routes.Checkout);
  const isLoginRoute = !!useRouteMatch(Routes.Login);
  const isSignUpRoute = !!useRouteMatch(Routes.SignUp);

  const [dismissCalendarMessage] = useMutation<DismissCalendarIntegrationLostMessage>(
    dismissCalendarMessageMutation,
  );
  /* #endregion */

  /* #region  Handlers */
  const handleToggleSubscriptionDialog = (open: boolean) => () => {
    setRemindSubscriptionIsOpen(open);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const isRegularUser = data?.me?.role === UserRole.REGULAR_USER;
  const isSmartmeetingUser = data?.me?.philipsDevices?.smartmeeting ?? false;
  const activeAnnouncements = data?.me?.activeAnnouncements || [];
  const paymentCustomer = data?.me?.paymentCustomer;
  const paymentCustomerPlanId = paymentCustomer?.plan?.id;
  const meetingsSecLeft = paymentCustomer?.meetingsDurationSecondsLeft ?? 0;
  const meetingsMinLeft = convertSecondsToMinutes(meetingsSecLeft);
  const features = paymentCustomer?.plan?.features;
  const meetingsSecLimit = features?.staticLimits?.meetingsDurationSecondsLimit ?? 0;
  const meetingsSecUsed = meetingsSecLimit - meetingsSecLeft;
  const usagePercentage = (meetingsSecUsed * 100) / meetingsSecLimit;
  const isLimitRunningOut = usagePercentage > 70;
  const isAllowedPage = !(isCheckoutRoute || isLoginRoute || isSignUpRoute);
  const isPersonalPlan = data?.me?.billingAccess?.id === PlanIDEnum.PERSONAL;
  const isSubscriptionActivated = paymentCustomer?.isSubscriptionActivated ?? false;
  const isTrialExpired = paymentCustomer?.isTrialExpired ?? false;
  const isRecordingAllowed = paymentCustomer?.isRecordingAllowed ?? false;
  const isPersonal = paymentCustomer?.plan?.id === PlanIDEnum.PERSONAL;
  const isExpiredPlanTrial = isTrialExpired && !isSubscriptionActivated && !isPersonal;
  const showExpiredTrialAlert = isAllowedPage && isExpiredPlanTrial && !loading;
  const showRunningOutLimit = isPersonalPlan && isLimitRunningOut && isRecordingAllowed;
  const showExceededLimit = isPersonalPlan && !isRecordingAllowed;
  const isTeamPlan = paymentCustomer?.plan?.id === PlanIDEnum.TEAM;
  const isTeamPlusPlan = paymentCustomer?.plan?.id === PlanIDEnum.TEAM_PLUS;
  const isHaveMoreMembers = !!data?.me?.workspace?.numUsers && data?.me?.workspace?.numUsers > 1; // prettier-ignore
  const isTeam = (isTeamPlan || isTeamPlusPlan) && isHaveMoreMembers;
  const isCalendarIntegrationLost = data?.me?.calendarIntegrationLost ?? false;
  const isShowSwitchUrl = !isRegularUser;
  /* #endregion */

  /* #region  Effects */
  // If The Cancel Subscription Dialogs Was Opened
  useEffect(() => {
    if (isLoggedIn && showExpiredTrialAlert) {
      if (!!localStorage.getItem('remindExpiredSubscription')) {
        setRemindSubscriptionIsOpen(false);
      } else {
        localStorage.setItem('remindExpiredSubscription', 'true');
        setRemindSubscriptionIsOpen(true);
      }
    } else {
      setRemindSubscriptionIsOpen(false);
    }
  }, [isLoggedIn, showExpiredTrialAlert]);

  // Paid subscription expiration notification (highest priority)
  useEffect(() => {
    if (showExpiredTrialAlert) {
      toast(
        <Alert severity="error">
          {isSmartmeetingUser ? (
            <Typography variant="body1">
              <span>Your trial has expired. </span>
              {isShowSwitchUrl && (
                <MaterialLink
                  color="inherit"
                  underline="always"
                  onClick={handleToggleSubscriptionDialog(true)}
                >
                  Activate subscription or switch to Personal
                </MaterialLink>
              )}
            </Typography>
          ) : (
            <Typography variant="body1">
              <span>Your subscription was canceled. </span>
              {isShowSwitchUrl && (
                <MaterialLink
                  color="inherit"
                  underline="always"
                  onClick={handleToggleSubscriptionDialog(true)}
                >
                  Renew your subscription or switch to Personal
                </MaterialLink>
              )}
            </Typography>
          )}
        </Alert>,
        { containerId: 'GlobalAlert', toastId: 'subscriptionExpirationNotification' },
      );
    } else {
      toast.dismiss('subscriptionExpirationNotification');
    }
  }, [history, paymentCustomerPlanId, showExpiredTrialAlert, isSmartmeetingUser, isShowSwitchUrl]);

  // Messages from Sembly administrators (medium priority)
  useEffect(() => {
    let announcements: React.ReactText[] = [];

    activeAnnouncements.forEach((announcement) => {
      announcements.push(
        toast(
          <Alert key={announcement.id} severity="info">
            <Typography variant="body1">
              {announcement.text}
              {!!announcement.link && (
                <Box component="span" pl={1}>
                  <MaterialLink
                    color="inherit"
                    underline="always"
                    target="_blank"
                    rel="noreferrer noopener"
                    href={announcement.link}
                  >
                    Learn more
                  </MaterialLink>
                </Box>
              )}
            </Typography>
          </Alert>,
          { containerId: 'GlobalAlert' },
        ),
      );
    });

    return () => {
      if (announcements.length) {
        announcements.forEach((ref) => toast.dismiss(ref));
        announcements = [];
      }
    };
    // there is no need to track activeAnnouncements in this case
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAnnouncements?.length]);

  // Other notification (low priority)
  useEffect(() => {
    showRunningOutLimitRef.current = showRunningOutLimit;
    showExceededLimitRef.current = showExceededLimit;

    const alerts = {
      limitRunningOut: (
        <Alert severity="info">
          <Typography variant="body1">
            You have {meetingsMinLeft} minutes remaining on the current plan.{' '}
            <MaterialLink
              color="inherit"
              underline="always"
              component={Link}
              to={Routes.SettingsWorkspacePlans}
            >
              Upgrade now
            </MaterialLink>{' '}
            for unlimited use!
          </Typography>
        </Alert>
      ),
      exceededLimit: (
        <Alert severity="warning">
          <Typography variant="body1">
            You have used up your{' '}
            <MaterialLink
              color="inherit"
              underline="always"
              href="https://sembly-ai.zendesk.com/hc/en-us/articles/8897884939281"
              target="_blank"
              rel="noopener noreferrer"
            >
              recording limit
            </MaterialLink>{' '}
            for this month.{' '}
            <MaterialLink
              color="inherit"
              underline="always"
              component={Link}
              to={Routes.SettingsWorkspacePlans}
            >
              Upgrade now
            </MaterialLink>{' '}
            for unlimited use!
          </Typography>
        </Alert>
      ),
      calendarLost: (
        <Alert
          severity="warning"
          onClose={() => {
            if (data?.me?.calendarIntegrationLost === undefined) return; // type guard
            dismissCalendarMessage({
              optimisticResponse: {
                dismissCalendarIntegrationLostMessage: {
                  __typename: 'DismissCalendarIntegrationLostMessageMutationPayload',
                  success: true,
                  errors: [],
                  user: { ...data.me, calendarIntegrationLost: false },
                },
              },
            });
          }}
        >
          <Typography variant="body1">
            Sembly has lost track of your future meetings, please{' '}
            <MaterialLink
              color="inherit"
              underline="always"
              component={Link}
              to={Routes.SettingsCalendars}
            >
              reconnect your calendar
            </MaterialLink>{' '}
            in settings.
          </Typography>
        </Alert>
      ),
    };

    if (showRunningOutLimit) {
      // Recording limit used more than 70%
      alertRef.current && toast.dismiss(alertRef.current);
      alertRef.current = toast(alerts.limitRunningOut, {
        containerId: 'GlobalAlert',
        onOpen: (props: unknown) => {
          // if outdated, close automatically
          if (!showRunningOutLimitRef.current) {
            (props as { deleteToast: () => void }).deleteToast();
          }
        },
      });
    } else if (showExceededLimit) {
      // Recording limit used up
      alertRef.current && toast.dismiss(alertRef.current);
      alertRef.current = toast(alerts.exceededLimit, {
        containerId: 'GlobalAlert',
        onOpen: (props: unknown) => {
          // if outdated, close automatically
          if (!showExceededLimitRef.current) {
            (props as { deleteToast: () => void }).deleteToast();
          }
        },
      });
    } else if (isCalendarIntegrationLost) {
      // Calendar integration lost
      alertRef.current && toast.dismiss(alertRef.current);
      alertRef.current = toast(alerts.calendarLost, {
        containerId: 'GlobalAlert',
        closeButton: false,
      });
    } else {
      // Otherwise hide the alert
      if (alertRef.current && toast.isActive(alertRef.current)) {
        toast.dismiss(alertRef.current);
        alertRef.current = null;
      }
    }
  }, [
    data?.me,
    showExceededLimit,
    showRunningOutLimit,
    meetingsMinLeft,
    isCalendarIntegrationLost,
    dismissCalendarMessage,
  ]);

  // Dissmiss all toasts
  useEffect(() => {
    if (!isLoggedIn) toast.dismiss();
  }, [isLoggedIn]);
  /* #endregion */

  if (remindSubscriptionIsOpen && isShowSwitchUrl) {
    return (
      <RemindCancelSubscriptionDialog
        open={remindSubscriptionIsOpen && isShowSwitchUrl}
        isTeam={isTeam}
        onClose={handleToggleSubscriptionDialog(false)}
      />
    );
  }

  return null;
};

export default GlobalNotifications;
