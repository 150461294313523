import { useHistory, generatePath } from 'react-router-dom';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// GQL Types
import { GenericStripePromotionCode } from '@gql-types/GenericStripePromotionCode';
import { GenericPaymentPlan } from '@gql-types/GenericPaymentPlan';
import { PlanIDEnum, StripePricePaymentIntervalEnum } from '@gql-types/globalTypes';
// App Shared
import { Routes } from '@shared/enums';
import { PAYMENT_PLANS } from '@shared/constants';
import { formatCurrencyAmount, isPastISO } from '@shared/utils';
import { BillingCycle } from '@shared/types';

export interface PaymentPlanHeaderProps {
  billingCycle: BillingCycle;
  ctaActiveButtonLabel?: string;
  ctaButtonLabel?: string;
  currencyCode: string;
  enableActiveCtaButton?: boolean;
  paymentCustomerPriceType: StripePricePaymentIntervalEnum | null;
  paymentPlans: GenericPaymentPlan[];
  planId: PlanIDEnum;
  promotion: GenericStripePromotionCode | null;
  trialExpirationDate: string | null;
  userBillingAccessId: string | null;
  onClickOnCtaButton: (plan: GenericPaymentPlan) => void;
}

export const PaymentPlanHeader: React.FC<PaymentPlanHeaderProps> = ({
  billingCycle,
  ctaActiveButtonLabel = 'Select',
  ctaButtonLabel = 'Try It Free',
  currencyCode,
  enableActiveCtaButton = false,
  paymentCustomerPriceType,
  paymentPlans,
  planId,
  promotion,
  trialExpirationDate,
  userBillingAccessId,
  onClickOnCtaButton,
}) => {
  const styles = useStyles();
  const history = useHistory();

  /* #region  Handlers */
  const handleChangeCurrentBillingPlan = (plan: GenericPaymentPlan) => () => {
    onClickOnCtaButton(plan);
  };

  const handleCheckoutSubscription = (plan: GenericPaymentPlan | undefined) => () => {
    if (!plan) throw new Error('Cannot read property "paymentPlan"');
    const searchParams = new URLSearchParams({ billingCycle });
    const pathname = generatePath(Routes.CheckoutStart, { planId: plan.id });
    history.push(`${pathname}?${searchParams}`);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const paymentPlan = PAYMENT_PLANS[planId];
  const stripePlan = paymentPlans.find((item) => item?.id === planId);
  const stripeProductId = stripePlan?.productId || '';

  const isPersonal = planId === PlanIDEnum.PERSONAL;
  const isEnterprise = planId === PlanIDEnum.ENTERPRISE;
  const isSelected =
    isPersonal || isEnterprise
      ? planId === userBillingAccessId
      : planId === userBillingAccessId && paymentCustomerPriceType === billingCycle.toUpperCase();
  const isExpiredTial = isPastISO(trialExpirationDate);
  const isDiscounted = checkIsApplicablePromotion(promotion?.appliesTo, stripeProductId);

  const selectedPrice = stripePlan?.priceOptions[billingCycle];
  const currencyOptions = selectedPrice?.currencyOptions;
  const currencyOption = currencyOptions?.find((option) => option.currency.toLowerCase() === currencyCode.toLowerCase()); // prettier-ignore
  const selectedPriceAmount = currencyOption?.amount || 0;

  const billingCycleUpper = billingCycle[0].toUpperCase() + billingCycle.slice(1);
  const amount = selectedPriceAmount ? selectedPriceAmount / 100 : 0;
  const price = formatCurrencyAmount(amount, currencyCode);
  const label = stripePlan?.name || paymentPlan.name;

  const discountPercent = promotion?.percentOff || 0;
  const discountAmount = promotion?.amountOff || 0;
  const discountValue = !!discountPercent ? amount * (discountPercent / 100) : discountAmount / 100;
  const amountValue = amount - discountValue <= 0 ? 0 : amount - discountValue;
  const discount = formatCurrencyAmount(isDiscounted ? amountValue : amount, currencyCode);

  const forTeams = stripePlan?.forTeams || false;
  const paymentType = forTeams ? `Member / ${billingCycleUpper}` : billingCycleUpper;
  const isTriedOut = isExpiredTial || isPersonal;
  const ctaLabel = isTriedOut ? ctaActiveButtonLabel : ctaButtonLabel;
  /* #endregion */

  return (
    <>
      <Box
        key={planId}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        my={!isEnterprise || isSelected ? 2 : 0}
        mt={isEnterprise && !isSelected ? 2 : 0}
      >
        <Typography variant="subtitle2">{label}</Typography>
        {isEnterprise ? (
          <Box display="flex" alignItems="center" minHeight="53px" mt={1}>
            {!isSelected && (
              <Typography
                noWrap
                variant="inherit"
                color="textSecondary"
                className={`${styles.secondaryText} ${styles.placeholder}`}
              >
                Contact the Sembly Team to learn more
              </Typography>
            )}
          </Box>
        ) : (
          <>
            <Box display="flex" alignItems="center" mt={1}>
              {isDiscounted && amount > 0 ? (
                <>
                  <Typography variant="h4" color="primary">
                    {discount}
                  </Typography>
                  <Box mr={1} />
                  <Tooltip arrow title={`${promotion?.code} – ${promotion?.name}`}>
                    <Typography variant="subtitle2" color="textSecondary">
                      <s>{price}</s>
                    </Typography>
                  </Tooltip>
                </>
              ) : (
                <Typography variant="h4" color="primary">
                  {price}
                </Typography>
              )}
            </Box>
            <Typography
              noWrap
              variant="inherit"
              color="textSecondary"
              className={styles.secondaryText}
            >
              {paymentType}
            </Typography>
          </>
        )}

        <Box mt={1}>
          {!stripePlan || isEnterprise ? (
            <Button
              disableElevation
              href="https://www.sembly.ai/form/"
              target="_blank"
              rel="noopener noreferrer"
              variant={isSelected ? 'outlined' : 'contained'}
              color="primary"
              disabled={isSelected}
              aria-label="Contact Sales"
            >
              <Typography noWrap variant="body2">
                {isSelected ? 'Current' : isEnterprise ? 'Contact Sales' : 'Contact us'}
              </Typography>
            </Button>
          ) : (
            <Button
              disableElevation
              color="primary"
              variant={isSelected ? 'outlined' : 'contained'}
              disabled={!enableActiveCtaButton && isSelected}
              aria-label="Select Plan"
              onClick={handleChangeCurrentBillingPlan(stripePlan)}
            >
              <Typography noWrap variant="body2">
                {isSelected && !enableActiveCtaButton ? 'Current' : ctaLabel}
              </Typography>
            </Button>
          )}
        </Box>
        {isEnterprise && !isSelected && (
          <Button
            color="primary"
            className={styles.link}
            aria-label="Apply Promo Code"
            onClick={handleCheckoutSubscription(stripePlan)}
          >
            <Typography noWrap variant="body2">
              Have a Promo Code
            </Typography>
          </Button>
        )}
      </Box>
    </>
  );
};

function checkIsApplicablePromotion(
  applicableItems: string[] | null = null,
  target: string = '',
): boolean {
  if (applicableItems === null) return false;
  const isRestrictedPromotion = !!applicableItems.length;
  return !isRestrictedPromotion || (isRestrictedPromotion && applicableItems.includes(target));
}

const useStyles = makeStyles((theme) => ({
  link: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 'normal',
  },
  secondaryText: {
    fontWeight: 'normal',
    fontSize: '0.75em',
  },
  placeholder: {
    maxWidth: 110,
    textAlign: 'center',
    whiteSpace: 'inherit',
    lineHeight: 1.5,
  },
}));

export default PaymentPlanHeader;
