import { DataSchema } from '@integration-app/sdk';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import { AutomationDestinationSelect, GenericDialog } from '../components';

export interface ManageThirdPartyAutomationDestinationDialogProps {
  appKey: string;
  dataSchema: DataSchema | null;
  open: boolean;
  values: Record<string, string> | null;
  onChange: (values: Record<string, string>) => void;
  onClose: () => void;
  onError: (error: unknown) => void;
}

/**
 * Dialog that displays all available destinations that can be used to create an automation.
 */
export const ManageThirdPartyAutomationDestinationDialog: React.VFC<
  ManageThirdPartyAutomationDestinationDialogProps
> = ({ open, appKey, dataSchema, values, onChange, onClose, onError }) => {
  const styles = useStyles();

  const handleChangeValue = (newValue: Record<string, string>) => {
    const fieldsList = Object.keys(dataSchema?.properties || {});

    // Delete all set values following the selected field
    // for example, if the spreadsheetId is changed, all other values should be removed
    // fieldsList=['spreadsheetId', 'tabId'] & values={spreadsheetId: '123', tabId: '456'} & record={ tabId: '456' } => { spreadsheetId: '123', tabId: '456' }
    // fieldsList=['spreadsheetId', 'tabId'] & values={spreadsheetId: '123', tabId: '456'} & record={spreadsheetId: '123'} => { spreadsheetId: '123' }
    const newValueIndex = fieldsList.indexOf(Object.keys(newValue)[0]);
    const updatedValues =
      !values || newValueIndex === -1
        ? newValue
        : Object.fromEntries(
            fieldsList
              .slice(0, newValueIndex + 1)
              .map((fieldKey) => [fieldKey, newValue[fieldKey] || values[fieldKey]]),
          );
    onChange(updatedValues);
  };

  return (
    <GenericDialog
      title="Select a destination"
      dialogProps={{ open, fullWidth: true, maxWidth: 'sm' }}
      onClose={onClose}
    >
      <DialogContent>
        {!dataSchema?.properties ? (
          <Typography>No destinations available</Typography>
        ) : (
          <>
            {Object.keys(dataSchema.properties).map((fieldKey) => {
              const requiredFields = dataSchema.required || [];
              const property = dataSchema.properties?.[fieldKey];
              const collectionKey = property?.referenceCollection?.key;
              const collectionParameters = property?.referenceCollection?.parameters;
              const collectionValue = values?.[fieldKey] || '';

              const fieldExceptions = ['projectId', 'boardId'];

              // If the field is not required, don't show it
              if (!fieldExceptions.includes(fieldKey) && !requiredFields.includes(fieldKey)) {
                return null;
              }

              const parameters: Record<string, string> | undefined = !collectionParameters
                ? undefined
                : Object.values(collectionParameters || {}).reduce((acc, param) => {
                    let k = param?.['$var'];
                    k = k?.startsWith('$.') ? k.slice(2) : k; // remove the "$." prefix from the key if it exists
                    const v = values?.[k];
                    if (k) acc[k] = v || '';
                    return acc;
                  }, {});

              return (
                !!collectionKey && (
                  <Box key={fieldKey} mb={2}>
                    <AutomationDestinationSelect
                      appKey={appKey}
                      fieldKey={fieldKey}
                      collectionKey={collectionKey}
                      collectionParameters={parameters}
                      value={collectionValue}
                      onChange={handleChangeValue}
                      onError={onError}
                    />
                  </Box>
                )
              );
            })}
          </>
        )}
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button
          disableElevation
          color="primary"
          variant="contained"
          disabled={!values}
          onClick={onClose}
        >
          Done
        </Button>
      </DialogActions>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(2, 3, 3),
  },
}));

export default ManageThirdPartyAutomationDestinationDialog;
