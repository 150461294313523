import groupBy from 'lodash/groupBy';
import mapKeys from 'lodash/mapKeys';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import fromPairs from 'lodash/fromPairs';
import { DateTime } from 'luxon';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
// Material UI
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, alpha } from '@material-ui/core/styles';
// Sembly UI
import { useIntersectionObserver } from '@sembly-ui';
// App Shared
import { APP_DRAWER_WIDTH, STORAGE_MEETING_LIST_SCROLL_TARGET_KEY } from '@shared/configuration';
import { MeetingCards } from '@shared/containers';
import { GenericMeetingOverview } from '@gql-types';

export interface MeetingListProps {
  isFetchingNextPages: boolean;
  isFetchingPrevPages: boolean;
  isRecentDataHasNext: boolean;
  isUpcomingDataHasNext: boolean;
  recentData: GenericMeetingOverview[];
  upcomingData: GenericMeetingOverview[];
  onRequestNextPage: () => void;
  onRequestPrevPage: () => void;
  onVerifyAgent: (meetingId: string) => void;
}

export const MeetingList: React.VFC<MeetingListProps> = ({
  isFetchingNextPages,
  isFetchingPrevPages,
  isRecentDataHasNext,
  isUpcomingDataHasNext,
  recentData,
  upcomingData,
  onRequestNextPage,
  onRequestPrevPage,
  onVerifyAgent,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const isCooldownRef = useRef(false);
  const isFocusedOnTodayRef = useRef(false);
  const previousLatestRecentItemId = useRef<string | null>(null);

  const [currentLatestRecentItemId, setCurrentLatestRecentItemId] = useState<string | null>(null);
  const [nextPageButton, setNextPageButton] = useState<HTMLButtonElement | null>(null);
  const [prevPageButton, setPrevPageButton] = useState<HTMLButtonElement | null>(null);
  const [todaySection, setTodaySection] = useState<HTMLDivElement | null>(null);

  const prevPageButtonObserver = useIntersectionObserver(prevPageButton, { threshold: 1 });
  const nextPageButtonObserver = useIntersectionObserver(nextPageButton);
  const todaySectionObserver = useIntersectionObserver(todaySection);

  const isTodayAtTop = (todaySectionObserver.boundingClientRect?.top ?? 0) < 100;
  const isTodayDownward = !todaySectionObserver.isIntersecting && !isTodayAtTop;
  const isTodayUpward = !todaySectionObserver.isIntersecting && isTodayAtTop;
  /* #endregion */

  const handleScrollToToday = () => {
    todaySection?.scrollIntoView();
    sessionStorage.removeItem(STORAGE_MEETING_LIST_SCROLL_TARGET_KEY);
  };

  /* #region  Render Helpers */
  const sortedRecentMeetings = sortBy(recentData, 'startedAt');
  const consolidatedMeetings = uniqBy([...sortedRecentMeetings, ...upcomingData], 'id');
  const timeZone = window.SemblyUserTimeZone || 'Etc/GMT';
  const today = DateTime.now().setZone(timeZone).endOf('day').toISO();

  let groupedMeetings = {
    [today]: [], // always show the "Today" group for upcoming meetings
    ...groupBy(consolidatedMeetings, parseMeetingDate),
  };

  groupedMeetings = fromPairs(Object.entries(groupedMeetings).sort());

  const groupedMeetingsKeys = Object.keys(groupedMeetings);
  const groupedMeetingsFirstDayKey = groupedMeetingsKeys[0];
  const oldestDayMeeting = sortedRecentMeetings[0];

  // Set a blank date for the group to use this as a flag indicating that the data for
  // this period may still not be completely loaded.
  if (groupedMeetingsKeys.length > 1 && isRecentDataHasNext) {
    groupedMeetings = mapKeys(groupedMeetings, (val, key) =>
      key === groupedMeetingsFirstDayKey ? '' : key,
    );
  }
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    if (
      prevPageButtonObserver.isIntersecting &&
      isRecentDataHasNext &&
      !isFetchingPrevPages &&
      !isCooldownRef.current
    ) {
      onRequestPrevPage();
      isCooldownRef.current = true;
    }

    if (nextPageButtonObserver.isIntersecting && isUpcomingDataHasNext && !isFetchingNextPages) {
      onRequestNextPage();
    }
  }, [
    isFetchingNextPages,
    isFetchingPrevPages,
    isRecentDataHasNext,
    isUpcomingDataHasNext,
    nextPageButtonObserver.isIntersecting,
    prevPageButtonObserver.isIntersecting,
    onRequestNextPage,
    onRequestPrevPage,
  ]);

  useEffect(() => {
    setCurrentLatestRecentItemId((currentItemId) => {
      previousLatestRecentItemId.current = currentItemId;
      return oldestDayMeeting?.id || null;
    });
  }, [oldestDayMeeting, recentData.length]);

  useLayoutEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!isFetchingPrevPages && isFocusedOnTodayRef.current) {
      const targetId = previousLatestRecentItemId.current || currentLatestRecentItemId;
      const target = document.querySelector(`[data-meeting-id="${targetId}"]`);
      target?.scrollIntoView({ block: 'start' });
      timeout = setTimeout(() => (isCooldownRef.current = false), 100);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isFetchingPrevPages, currentLatestRecentItemId]);

  // Scrolling the page to the beginning of the "Today" section or restore position after return
  useLayoutEffect(() => {
    function scrollToTodaySection(scrollTarget: HTMLDivElement) {
      scrollTarget.scrollIntoView();
      isFocusedOnTodayRef.current = true;
      setTimeout(() => (isCooldownRef.current = false), 100);
    }

    const scrollTargetMeetingId = sessionStorage.getItem(STORAGE_MEETING_LIST_SCROLL_TARGET_KEY);

    if (scrollTargetMeetingId) {
      const scrollTargetId = `[data-meeting-id="${scrollTargetMeetingId}"]`;
      const scrollTarget = document.querySelector(scrollTargetId);
      if (scrollTarget) {
        scrollTarget.scrollIntoView({ block: 'center' });
        isFocusedOnTodayRef.current = true;
      } else if (!isFocusedOnTodayRef.current && todaySection) {
        scrollToTodaySection(todaySection);
      }
    } else if (!isFocusedOnTodayRef.current && todaySection) {
      scrollToTodaySection(todaySection);
    }
  }, [todaySection]);

  // Remove the scroll target when unmounting
  useEffect(() => {
    return () => {
      sessionStorage.removeItem(STORAGE_MEETING_LIST_SCROLL_TARGET_KEY);
    };
  }, []);
  /* #endregion */

  return (
    <div className={`${styles.root} ${isRecentDataHasNext ? styles.faded : ''}`}>
      <div className={styles.list} id="MeetingsList">
        {!!todaySection && isTodayUpward && (
          <div className={styles.pinTop}>
            <Button
              variant="contained"
              className={styles.button}
              endIcon={<ArrowUpwardIcon fontSize="small" />}
              aria-label="Scroll to today"
              onClick={handleScrollToToday}
            >
              Today
            </Button>
          </div>
        )}

        {isFetchingPrevPages && (
          <div className={styles.pinTop}>
            <CircularProgress size={24} />
          </div>
        )}

        <div className={styles.elevated}>
          {isRecentDataHasNext && (
            <Button
              variant="outlined"
              ref={setPrevPageButton}
              className={styles.button}
              disabled={isFetchingPrevPages}
              hidden={isFetchingPrevPages}
              aria-label="Load history"
              onClick={onRequestPrevPage}
            >
              Load history
            </Button>
          )}
        </div>

        <MeetingCards ref={setTodaySection} data={groupedMeetings} onVerifyAgent={onVerifyAgent} />

        {isTodayDownward && (
          <div className={styles.pinBottom}>
            <Button
              variant="contained"
              className={styles.button}
              endIcon={<ArrowDownwardIcon fontSize="small" />}
              aria-label="Scroll to today"
              onClick={handleScrollToToday}
            >
              Today
            </Button>
          </div>
        )}

        {isUpcomingDataHasNext ? (
          <Box width="100%" textAlign="center" mt={4} mb={2}>
            <Button
              variant="outlined"
              className={styles.button}
              ref={setNextPageButton}
              disabled={isFetchingNextPages}
              aria-label="Load more"
              onClick={onRequestNextPage}
            >
              {isFetchingNextPages ? <CircularProgress size={24} /> : <span>Load more</span>}
            </Button>
          </Box>
        ) : (
          <Box height="50vh" />
        )}
      </div>
    </div>
  );
};

function parseMeetingDate(item: GenericMeetingOverview) {
  const fallbackDateISO = new Date(-8640000000000000).toISOString(); // farthest date
  return DateTime.fromISO(item.startedAt || fallbackDateISO)
    .setZone(window.SemblyUserTimeZone || 'America/New_York')
    .endOf('day')
    .toISO();
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    zIndex: 0,
    ...theme.mixins.layout.default.padding,
  },
  faded: {
    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      left: 0,
      height: 80,
      zIndex: 10,
      backgroundImage: `
          linear-gradient(
            ${alpha(theme.palette.background.default, 1)},
            ${alpha(theme.palette.background.default, 0)}
          )`,
    },
  },
  button: {
    '&[hidden]': {
      visibility: 'hidden',
    },
  },
  pinTop: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.mixins.toolbar.minHeight,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 10,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${APP_DRAWER_WIDTH}px)`,
      left: APP_DRAWER_WIDTH,
    },
  },
  pinBottom: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.mixins.toolbar.minHeight,
    bottom: theme.spacing(4),
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 10,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${APP_DRAWER_WIDTH}px)`,
      left: APP_DRAWER_WIDTH,
    },
  },
  elevated: {
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: '100%',
    textAlign: 'center',
    zIndex: 10,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    paddingTop: theme.spacing(8),
  },
  list: {
    position: 'relative',
    width: '100%',
  },
}));

export default MeetingList;
