import ReactMarkdown from 'markdown-to-jsx';
import { parseISO } from 'date-fns';
// Material UI
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, alpha, useTheme } from '@material-ui/core/styles';
// Material Icons
import { ChatAnswerCard, ChatArtifact } from '../components';
import { GenericChatHistoryItem, GenericChatArtifact } from '../types';
import { formatInTimeZone } from '../utils';
import { MEETING_TYPES } from '../constants';

export interface ChatItemProps {
  data: GenericChatHistoryItem;
  onClickOnArtefact?: (data: GenericChatArtifact) => void;
}

export const ChatItem: React.VFC<ChatItemProps> = ({ data, onClickOnArtefact = () => null }) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const hasQustionAnswer = !!data.questionAnswer;
  const hasInsight = !!data.insight;
  const hasWorkstream = !!data.workstream;
  const hasMeeting = !!data.meeting;

  if (hasQustionAnswer) {
    const item = data.questionAnswer;
    const isProcessing = data?.isInProcessing || false;

    return (
      <>
        <div className={styles.question}>
          <Typography component="div" variant={isSmallScreen ? 'body1' : 'subtitle1'}>
            <ReactMarkdown children={item?.question || ''} />
          </Typography>
        </div>
        <ChatAnswerCard processing={isProcessing}>
          <Typography component="div" variant={isSmallScreen ? 'body1' : 'subtitle1'}>
            <ReactMarkdown children={item?.answer || ''} />
          </Typography>
          {item?.answerFiles.map((artefact) => (
            <ChatArtifact key={artefact.id} data={artefact} onClick={onClickOnArtefact} />
          ))}
        </ChatAnswerCard>
      </>
    );
  }

  if (hasInsight) {
    const startedAt = data.insight?.meeting?.startedAt || new Date().toISOString();
    const finishDate = data.insight?.meeting?.finishedAt;
    const finishedAt = finishDate || startedAt;
    const startedDate = formatInTimeZone(parseISO(startedAt), 'PP');
    const startedTime = formatInTimeZone(parseISO(startedAt), 'p');
    const finishedTime = formatInTimeZone(parseISO(finishedAt || startedAt), 'p');
    const hasTimeRange = startedTime !== finishedTime;
    const time = hasTimeRange ? `${startedTime} – ${finishedTime}` : startedTime;
    const nextSteps = data.insight?.nextSteps || '';
    const deliveriable = data.insight?.deliverable || '';

    return (
      <>
        <Divider className={styles.divider} />
        <div className={styles.insight}>
          <div className={styles.topic}>{data.insight?.topic}</div>
          <Typography variant="inherit" component="div">
            {data.insight?.name}
          </Typography>
          <div className={styles.insightMeta}>
            <span>{data.insight?.meeting?.title}</span>
            <span>{startedDate}</span>
            <span>{time}</span>
            <span>{data.insight?.meeting.owner.fullName}</span>
          </div>
        </div>
        {(!!nextSteps || !!deliveriable) && (
          <ChatAnswerCard className={styles.insightContent}>
            {!!nextSteps && (
              <>
                <Typography variant="body1" color="textSecondary">
                  <b>Next Steps:</b>
                </Typography>
                <Typography variant="body1">{nextSteps}</Typography>
              </>
            )}
            {!!deliveriable && (
              <>
                <Typography variant="body1" color="textSecondary">
                  <b>Deliverable:</b>
                </Typography>
                <Typography variant="body1">{deliveriable}</Typography>
              </>
            )}
          </ChatAnswerCard>
        )}
      </>
    );
  }

  if (hasWorkstream) {
    return (
      <ChatAnswerCard processing={!data.workstream?.description}>
        <Typography variant={isSmallScreen ? 'body1' : 'subtitle1'}>
          <b>Description</b>
        </Typography>
        <Typography variant={isSmallScreen ? 'body1' : 'subtitle1'}>
          {data.workstream?.description}
        </Typography>
      </ChatAnswerCard>
    );
  }

  if (hasMeeting) {
    const startedAt = data.meeting?.startedAt || new Date().toISOString();
    const finishDate = data.meeting?.finishedAt;
    const finishedAt = finishDate || startedAt;
    const startedDate = formatInTimeZone(parseISO(startedAt), 'PP');
    const startedTime = formatInTimeZone(parseISO(startedAt), 'p');
    const finishedTime = formatInTimeZone(parseISO(finishedAt || startedAt), 'p');
    const hasTimeRange = startedTime !== finishedTime;
    const time = hasTimeRange ? `${startedTime} – ${finishedTime}` : startedTime;

    let meetingType: string | null = null;

    for (const category of MEETING_TYPES) {
      const element = category.elements.find((el) => el.value === data.meeting?.meetingType);
      if (element) {
        meetingType = element.label;
        break;
      }
    }

    return (
      <>
        <Divider className={styles.divider} />
        <div className={styles.insight}>
          <div className={styles.topic}>{meetingType || 'General'}</div>
          <Typography variant="inherit" component="div">
            {data.meeting?.title}
          </Typography>
          <div className={styles.insightMeta}>
            <span>{startedDate}</span>
            <span>{time}</span>
            <span>{data.meeting?.owner.fullName}</span>
          </div>
          <div className={styles.insightMeta}>
            Note: if you only want answers based on inserted meetings, you must specify this in your
            prompt. Eg: "...answer based only on given meetings."
          </div>
        </div>
      </>
    );
  }

  return null;
};

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(1, 2),
  },
  question: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
    gridGap: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius * 2,
    maxWidth: '70%',
    '& p': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
  },
  insight: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    color: theme.palette.accents.purple,
    padding: theme.spacing(1, 2),
    gridGap: theme.spacing(1),
    ...theme.typography.h4,
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.h5,
    },
  },
  insightContent: {
    marginBottom: theme.spacing(1),
  },
  insightMeta: {
    color: theme.palette.text.secondary,
    ...theme.typography.body2,
    '& > span:not(:last-child):after': {
      content: "'•'",
      display: 'inline-flex',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  topic: {
    color: theme.palette.accents.purple,
    backgroundColor: alpha(theme.palette.accents.purple, 0.1),
    padding: theme.spacing(0.25, 2),
    borderRadius: '2em',
    ...theme.typography.body1,
  },
}));

export default ChatItem;
