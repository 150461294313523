import pluralize from 'pluralize';
import { useState, useEffect } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import PromotionIcon from '@material-ui/icons/LocalOffer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// App Shared
import { GenericPaymentPlan } from '@gql-types/GenericPaymentPlan';
import { GenericStripePromotionCode } from '@gql-types/GenericStripePromotionCode';
import { GenericWorkspace } from '@gql-types/GenericWorkspace';
import { BillingCycle } from '@shared/types';
import { formatCurrencyAmount } from '@shared/utils';

export interface CheckoutDetailsProps {
  billingCycle: BillingCycle;
  currencyCode: string;
  paymentPlan: GenericPaymentPlan;
  promotion?: GenericStripePromotionCode | null;
  trialDurationDays?: number;
  verifyingPromotion?: boolean;
  workspace: GenericWorkspace;
  onApplyPromotion?: (value: string) => void;
  onChangePromotion?: (value: string) => void;
  onRemovePromotion?: () => void;
}

export const CheckoutDetails: React.VFC<CheckoutDetailsProps> = ({
  billingCycle,
  currencyCode,
  paymentPlan,
  promotion,
  trialDurationDays = 0,
  verifyingPromotion = false,
  workspace,
  onApplyPromotion,
  onRemovePromotion = () => null,
  onChangePromotion = () => null,
}) => {
  /* #region  Hooks */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [promotionCode, setPromotionCode] = useState('');
  const [showPromotionForm, setShowPromotionForm] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleTogglePromotion = (visible: boolean) => () => {
    setShowPromotionForm(visible);
    if (!visible) handleRemovePromotion();
  };

  const handleApplyPromotion = () => {
    if (!onApplyPromotion) return;
    onApplyPromotion(promotionCode);
  };

  const handleRemovePromotion = () => {
    onRemovePromotion();
    setPromotionCode('');
    setShowPromotionForm(false);
  };

  const handleUpdatePromotionCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromotionCode(e.target.value);
    onChangePromotion && onChangePromotion(e.target.value);
  };

  const handleSubmitPromotionCode = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    handleApplyPromotion();
  };
  /* #endregion */

  /* #region  Render Helpers */
  const billingName = billingCycle === 'monthly' ? billingCycle : `${billingCycle}ly`;
  const numUsers = workspace.numUsers || 1;
  const currencyOptions = paymentPlan.priceOptions[billingCycle].currencyOptions;
  const currencyOption = currencyOptions.find((option) => option.currency.toLowerCase() === currencyCode.toLowerCase()); // prettier-ignore
  const priceAmount = currencyOption?.amount || 0;
  const discountName = promotion?.name || '';
  const amount = formatCurrencyAmount(priceAmount / 100, currencyCode);
  const subtotalValue = numUsers * (priceAmount / 100);

  const discountPercent = promotion?.percentOff || 0;
  const discountAmount = promotion?.amountOff || 0;
  const hasDiscount = !!discountPercent || !!discountAmount;
  const subtotal = formatCurrencyAmount(subtotalValue, currencyCode);
  const discountValue = !!discountPercent ? subtotalValue * (discountPercent / 100) : !!discountAmount ? discountAmount / 100 : 0; // prettier-ignore
  const discount = hasDiscount ? `-${formatCurrencyAmount(discountValue, currencyCode)}` : '-';
  const totalValue = subtotalValue - discountValue;
  const total = formatCurrencyAmount(totalValue <= 0 ? 0 : totalValue, currencyCode);

  const team = numUsers > 1 ? `${numUsers} ${pluralize('member', numUsers)}` : '1 member';
  const trialDurationText = `${trialDurationDays} ${pluralize('day', trialDurationDays)}`;
  const subtotalDescription = `${trialDurationDays}-day trial`;

  const description = paymentPlan.forTeams
    ? `${team}, ${amount} per member, billed ${billingName}`
    : `${amount}, billed ${billingName}`;

  const descriptionForTrial = paymentPlan.forTeams
    ? `${team}, ${amount} per member, billed ${billingName} after ${trialDurationText} trial`
    : `${amount}, billed ${billingName} after ${trialDurationText} trial`;
  /* #endregion */

  useEffect(() => {
    if (hasDiscount && showPromotionForm) {
      setShowPromotionForm(false);
    }
  }, [hasDiscount, showPromotionForm]);

  return (
    <>
      <Paper square>
        <List>
          <ListItem>
            <ListItemText
              primary={paymentPlan.name}
              secondary={
                <Box component="span" display="block" maxWidth={isSmallScreen ? 250 : 'auto'}>
                  {!!trialDurationDays ? descriptionForTrial : description}
                </Box>
              }
            />
            <ListItemSecondaryAction>
              {!!trialDurationDays ? (
                <Box textAlign="right">
                  <Typography variant="subtitle1">{subtotal}</Typography>
                  <Typography variant="body2">{subtotalDescription}</Typography>
                </Box>
              ) : (
                <Typography variant="subtitle1">{subtotal}</Typography>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="middle" component="li" />
          <ListItem>
            <ListItemText
              primary="Promotion code"
              secondary={
                hasDiscount ? (
                  <Box component="span" display="flex" alignItems="center" maxWidth="70%">
                    <PromotionIcon color="primary" fontSize="small" />
                    <Box component="span" mr={1} />
                    <Typography component="span" variant="body2" color="textSecondary">
                      {promotion?.code} - {discountName}
                    </Typography>
                    <Box component="span" mr={1} />
                    <Link onClick={handleRemovePromotion}>
                      <Typography component="span" variant="body2">
                        Delete
                      </Typography>
                    </Link>
                  </Box>
                ) : null
              }
            />
            {!showPromotionForm && (
              <ListItemSecondaryAction>
                {hasDiscount ? (
                  <Typography variant="subtitle1">{discount}</Typography>
                ) : (
                  <Link onClick={handleTogglePromotion(true)}>
                    <Typography variant="button">Add</Typography>
                  </Link>
                )}
              </ListItemSecondaryAction>
            )}
          </ListItem>
          {!hasDiscount && showPromotionForm && (
            <ListItem>
              <TextField
                fullWidth
                autoFocus
                size="small"
                variant="filled"
                disabled={verifyingPromotion}
                InputProps={{
                  endAdornment: !!promotionCode && !verifyingPromotion && (
                    <InputAdornment position="end">
                      <Link onClick={handleApplyPromotion}>
                        <Typography variant="button">Apply</Typography>
                      </Link>
                    </InputAdornment>
                  ),
                }}
                value={promotionCode}
                onChange={handleUpdatePromotionCode}
                onKeyDown={handleSubmitPromotionCode}
              />
              <Box mr={1} />
              <Button
                variant="outlined"
                disabled={verifyingPromotion}
                style={{ minWidth: 80 }}
                aria-label="Cancel"
                onClick={handleTogglePromotion(false)}
              >
                {verifyingPromotion ? <CircularProgress size={24} /> : <span>Cancel</span>}
              </Button>
            </ListItem>
          )}
        </List>
      </Paper>

      <Box mt={4}>
        <Paper square>
          <List>
            <ListItem>
              <ListItemText primary="Total" />
              <ListItemSecondaryAction>
                <Typography variant="subtitle1">
                  <b>{total}</b>
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
      </Box>
    </>
  );
};

export default CheckoutDetails;
