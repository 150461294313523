// Material UI
import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// Sembly UI
import { GenericDialog } from '@sembly-ui';
// App Shared
import { ACHIEVEMENT_TYPES } from '@shared/constants';
import { ReactComponent as BeginnerIcon } from '@shared/assets/icon-level-beginner-64.svg';
import { ReactComponent as CupIcon } from '@shared/assets/icon-cup-20.svg';
import { ReactComponent as ExpertIcon } from '@shared/assets/icon-level-expert-64.svg';
import { ReactComponent as ProIcon } from '@shared/assets/icon-level-pro-64.svg';
import { useAuth } from '@shared/clients/authClient';
import { useUserInterface, useUserContext } from '@shared/hooks';
// GraphQL Queries and Types
import userGuideItemsQuery from '@shared/queries/UserGuideItems.graphql';
import { UserGuideTypes, UserGuideItems, UserRole } from '@gql-types';

export interface AchievementsProps {
  onClose: () => void;
}

export const Achievements: React.VFC<AchievementsProps> = ({ onClose }) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useUserContext();
  const [isLoggedIn] = useAuth();
  const { update } = useUserInterface();

  const { data, loading } = useQuery<UserGuideItems>(userGuideItemsQuery, { skip: !isLoggedIn });
  /* #endregion */

  /* #region  Handlers */
  const handleClose = () => {
    onClose();
  };

  const handleConfirmAchievementPromotion = (type: UserGuideTypes) => () => {
    update({ isOpenResourceCenter: false, currentAchievementType: type });
  };
  /* #endregion */

  /* #region  Render Helpers */
  const isAdmin = user.data?.me?.role === UserRole.CUSTOMER_ADMIN;
  const guideItems = data?.userGuideItems || [];
  const restrictedTypes = isAdmin ? [] : [UserGuideTypes.INVITE_TEAMMEMBER];
  const availablePromotions = guideItems.filter((item) => !restrictedTypes.includes(item.type));
  const completedGuideItems = availablePromotions.filter((item) => item.isDone);
  const value = completedGuideItems.length;
  const totalValue = availablePromotions.length;
  const progressPercentage = (100 * value) / totalValue;

  let progressLabel = 'Sembly Beginner';
  if (progressPercentage > 40) progressLabel = 'Sembly Professional';
  if (progressPercentage > 70) progressLabel = 'Sembly Expert';

  let progressIcon = <BeginnerIcon />;
  if (progressPercentage > 40) progressIcon = <ProIcon />;
  if (progressPercentage > 70) progressIcon = <ExpertIcon />;
  /* #endregion */

  return (
    <GenericDialog
      title="Level Up"
      dialogProps={{ fullWidth: true, maxWidth: 'sm', fullScreen: isSmallScreen }}
      onClose={handleClose}
    >
      {loading ? (
        <Box mt={6} mb={12} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box component={DialogContent} mb={4}>
          <div className={styles.head}>
            {progressIcon}
            <Box mb={1} />
            <Typography variant="h6">{progressLabel}</Typography>
            <Box mt={1} width="100%">
              <LinearProgress variant="determinate" value={progressPercentage} />
            </Box>
            <Typography variant="body2">
              {value} of {totalValue} completed
            </Typography>
          </div>

          <Box mt={2} mb={4}>
            {availablePromotions.map((item) => {
              const achievement = ACHIEVEMENT_TYPES[item.type];
              return (
                <Box
                  key={item.type}
                  className={styles.item}
                  flexDirection={isSmallScreen ? 'column' : 'row'}
                >
                  <img className={styles.icon} src={achievement.icon} alt={achievement.title} />
                  <Box flex={1} textAlign={isSmallScreen ? 'center' : 'left'}>
                    <Typography gutterBottom variant="body1">
                      <b>{achievement.title}</b>
                    </Typography>
                    <Typography variant="body2">{achievement.description}</Typography>
                  </Box>

                  <Box display="flex" alignItems="center" gridGap={12}>
                    {item.isDone ? (
                      <Button
                        variant="outlined"
                        style={{ minWidth: 120 }}
                        aria-label="Show achievement"
                        onClick={handleConfirmAchievementPromotion(item.type)}
                      >
                        <Box display="flex" alignItems="center">
                          <CupIcon />
                          <Box component="span" mr={0.75} />
                          <Typography noWrap variant="body2" component="span">
                            Completed
                          </Typography>
                        </Box>
                      </Button>
                    ) : (
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        style={{ minWidth: 120 }}
                        aria-label="Show how to achieve"
                        onClick={handleConfirmAchievementPromotion(item.type)}
                      >
                        <Typography noWrap component="span" variant="body2">
                          Show me how
                        </Typography>
                      </Button>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box textAlign="right">
            <Button
              disableElevation
              color="primary"
              variant="contained"
              aria-label="Done"
              onClick={handleClose}
            >
              <Typography variant="body1" component="span">
                Done
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  head: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.surface.main,
    color: theme.palette.surface.contrastText,
    padding: theme.spacing(3),
    gap: theme.spacing(1),
  },
  icon: {
    width: 36,
    height: 36,
    display: 'block',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    gap: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    border: '1px solid',
    borderColor: theme.palette.grey[200],
  },
  button: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    padding: theme.spacing(1),
    fontSize: 14,
    '&:hover': {
      borderColor: theme.palette.grey.A200,
    },
  },
}));

export default Achievements;
